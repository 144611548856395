import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { db } from '../../firebase';
import { QrReader } from 'react-qr-reader';
import { Typography } from '@mui/material';
import AttendanceTable from './AttendanceTable';
import styles from '../../styles/admin/AdminPage.module.css';
import { collection, getDocs, query, where, addDoc } from 'firebase/firestore';

const AdminPage = () => {
  const [recognizedUsers, setRecognizedUsers] = useState([]);
  const { user } = useContext(AuthContext);
  const [showCompleteMessage, setShowCompleteMessage] = useState(false);

  useEffect(() => {
    if (user?.email === 'admin@naver.com') {
      const fetchAttendanceRecords = async () => {
        const q = query(collection(db, '0902out'));
        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data(), // 직접적인 데이터 접근 대신 객체 전체를 할당
            timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : null,
          }));
          setRecognizedUsers(users);
        } catch (error) {
          console.error('Failed to fetch attendance records:', error);
        }
      };
      fetchAttendanceRecords();
    }
  }, [user]);

  if (!user || user.email !== 'admin@naver.com') {
    return <Typography variant="h6">관리자만 접근 가능합니다.</Typography>;
  }

  const handleScan = async (resultText) => {
    if (!resultText) return; // Early return if no data
    try {
      const qrData = JSON.parse(resultText);
      const now = new Date();
      if (recognizedUsers.some(user => user.userId === qrData.userId && (now - new Date(user.timestamp)) < 5000)) {
        // Prevent re-processing a recently processed QR code
        return;
      }
      const existingUser = await getUserFromFirestore(qrData.userId);
      if (!existingUser) {
        await addDoc(collection(db, '0902out'), { ...qrData, timestamp: now });
        setShowCompleteMessage(true);
        setTimeout(() => setShowCompleteMessage(false), 2000);
      }
    } catch (error) {
      console.error('Error handling QR code:', error);
    }
  };

  const getUserFromFirestore = async (userId) => {
    const q = query(collection(db, '0902out'), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty ? querySnapshot.docs[0].data() : null;
  };

  return (
    <div className={styles.admin}>
      <Typography variant="h4" gutterBottom>2월 17일 로타리 창립 119주년 출석 관리</Typography>
      <div className={styles['qr-reader']} style={{ width: '100%', height: '100%' }}>
        {!showCompleteMessage ? (
          <QrReader
            delay={300}
            onError={(error) => console.error(error)}
            onResult={(result) => result?.text && handleScan(result.text)}
            style={{ width: '100%' }}
          />
        ) : (
          <Typography variant="h5" className={styles['complete-text']}>출석이 완료되었습니다.</Typography>
        )}
      </div>
      <Typography variant="h6" gutterBottom>Attendance Records:</Typography>
      <AttendanceTable recognizedUsers={recognizedUsers} />
    </div>
  );
};

export default AdminPage;
