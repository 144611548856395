import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const SourcePage = () => {
  const [audioUrls, setAudioUrls] = useState([]);

  useEffect(() => {
    const fetchAudioUrls = async () => {
      const storage = getStorage();
      const fileNames = [
        '1.국기에대한경례.wav',
        '2.애국가.mp3',
        '3.순국선열및작고로타리안에대한묵념.mp3',
        '4.로타리송.mp3'
      ];

      const urls = await Promise.all(
        fileNames.map(async (fileName) => {
          const fileRef = ref(storage, `gs://rotary-webapp-230324.appspot.com/${fileName}`);
          const url = await getDownloadURL(fileRef);
          return { name: fileName, url };
        })
      );

      setAudioUrls(urls);
    };

    fetchAudioUrls();
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>로타리 의전음원</h1>
      <div style={styles.listContainer}>
        {audioUrls.map(({ name, url }, index) => (
          <div key={index} style={styles.audioItem}>
            <p style={styles.audioTitle}>{name}</p>
            <audio controls src={url} style={styles.audioPlayer}>
              .
            </audio>
          </div>
        ))}
      </div>
    </div>
  );
};

// 스타일 객체를 추가합니다.
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh',
  },
  title: {
    color: '#333',
    fontSize: '2rem',
    marginBottom: '2rem'
  },
  listContainer: {
    width: '80%',
    maxWidth: '640px'
  },
  audioItem: {
    marginBottom: '1rem',
    padding: '1rem',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '4px',
    backgroundColor: '#fff'
  },
  audioTitle: {
    marginBottom: '0.5rem',
    fontWeight: 'bold'
  },
  audioPlayer: {
    width: '100%' // 오디오 플레이어의 너비를 부모 요소에 맞춥니다.
  }
};

export default SourcePage;
