import React, { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { TextField, Button } from '@mui/material';
import { getFirestore, doc, getDoc, setDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { app } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import SubBtn from '../quiz/SubBtn';

const db = getFirestore(app);

const QuizPage = () => {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [answer1, setAnswer1] = useState('');
  const navigate = useNavigate();

  const correctAnswers = {
    answer1: "후견인",
  };

  const fetchUserData = async (userId) => {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data() : null;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const handleSubmit = async () => {
    if (!isLoggedIn || !user) {
      alert('로그인이 필요합니다.');
      return;
    }

    const submissionRef = doc(db, 'quizSubmissions', user.uid);
    const submissionSnap = await getDoc(submissionRef);
    if (submissionSnap.exists()) {
      alert('이미 정답을 제출했습니다.');
      return;
    }

    const userData = await fetchUserData(user.uid);
    if (!userData) {
      alert('사용자 정보를 가져오는 데 실패했습니다.');
      return;
    }

    const normalizedUserAnswer = answer1.trim().toLowerCase();
    const isCorrect = normalizedUserAnswer === correctAnswers.answer1.toLowerCase();
    const answersCollectionRef = collection(db, isCorrect ? 'correctAnswers' : 'incorrectAnswers');

    try {
      await addDoc(answersCollectionRef, {
        ...userData,
        userId: user.uid,
        answer: answer1,
        timestamp: serverTimestamp(),
      });

      await setDoc(submissionRef, { submitted: true });

      alert('정답이 제출 되었습니다.');
      navigate('/');
    } catch (error) {
      console.error('Error saving quiz answer:', error);
      alert('정답 제출에 실패했습니다. 관리자에게 문의하세요. 010-2765-1787');
    }
  };

  const imageStyle = {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '20px'
  };

  const textFieldStyle = {
    width: '80%',
    marginBottom: '10px'
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb6ZWqU%2FbtsFM2jP3Ko%2FUoABXlkHRecvt3cxBiw8UK%2Fimg.png" alt="Img" style={imageStyle} />

      <div>
          <TextField 
            label="정답 입력" 
            value={answer1} 
            onChange={(e) => setAnswer1(e.target.value)} 
            style={textFieldStyle} 
            disabled={!isLoggedIn} // 여기에 disabled 속성 추가
          />
      </div>
      {!isLoggedIn && <p style={{ color: 'red' }}>로그인 이후 제출 가능합니다.</p>}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!isLoggedIn}
        style={{
          width: '80%',
          backgroundColor: isLoggedIn ? '#194C86' : '#CCCCCC', // 조건부 스타일 설정
          color: 'white',
          marginBottom: '20px',
        }}
      >
        제출
      </Button>
      <p style={{ color: 'black', marginTop: '-10px' }}>[응모기간] 2024년 3월 15일(금) ~ 31일(일)</p>


      <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcIaAmm%2FbtsFMLW53si%2F4fnOe3D8AL3GKFfueAcMj1%2Fimg.png" alt="Img" style={imageStyle}/>
      <SubBtn />
    </div>
  );
};

export default QuizPage;
