import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const GiguNews = () => {
  const newsData1 = [
    {
      id: 1,
      title: '서산지역대표 인터뷰',
      description: '해냄 장광순',
      date: '',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FEI6Z9%2FbtsH1iykuaD%2FPVGkfpk9exTWERui3uGcQ1%2Fimg.png',
    },
    // {
    //   id: 2,
    //   title: '서산지역 로타랙트',
    //   description: '',
    //   date: '',
    //   imageUrl: '',
    // },
    // {
    //   id: 3,
    //   title: '서산지역 지구임원',
    //   description: '',
    //   date: '',
    //   imageUrl: '',
    // },
    // {
    //   id: 4,
    //   title: '',
    //   description: '',
    //   date: '',
    //   imageUrl: '',
    // },
    {
      id: 5,
      title: '',
      description: '',
      date: '',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb6I6hH%2FbtsHR05K4eQ%2FAD5TWJsdNZ6DekdrcN4D10%2Fimg.png',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontSize: '2rem', color: '#194C86', textAlign: 'center', borderBottom: '4px solid #194C86', padding: '1rem', cursor: 'pointer' }}>
          <strong>지역소식</strong>
        </Typography>
      </Grid>
      {newsData1.map((news1) => (
        <Grid item xs={12} sm={6} md={4} key={news1.id}>
          <Link to={`/newsArea1/${news1.id}`} style={{ textDecoration: 'none', color: 'black' }}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={news1.imageUrl}
                alt={news1.title}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {news1.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news1.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news1.date}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default GiguNews;
