import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import useStyles from '../../../styles/contents/MessageRiStyle';

const MessageRi = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcwVP3p%2Fbtsn7qWiaWo%2FNOGa9IMwOA5O3Pjm53l530%2Fimg.png"
            alt="logo"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            <strong>RI 회장 <br/>6월 메시지</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.pictureFrame}>
            <img
              src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FoysOp%2FbtsnGYLRPWD%2Fz7kQyTB20akSlyy0UsrEX1%2Fimg.jpg"
              alt="riProfiles"
              className={classes.profile}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.profileContainer}>
            <Typography variant="h6" className={classes.profileName}>
              <strong>R. 고든 R. 매키넬리</strong>
            </Typography>
            <Typography variant="body1" className={classes.profilePosition}>
              2023-24년도 RI 회장 <br />
              사우스 퀸즈페리 로타리클럽 <br />
              스코틀랜드 웨스트로디언
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.messageText}>
            {'\n'}
            &nbsp;로타리 회장은 임기 마지막 달에 본 지면에서 지난 1년을 회고하는 관례가 있습니다. 저는 우리가 함께 이룬 모든 성과가 자랑스럽습니다. 하지만 저는 우리의 미래에 초점을 맞추고자 하며 그 미래는 소아마비로 시작됩니다.
            {'\n'}{'\n'}
            &nbsp;우리는 30억 명의 어린이에게 소아마비 백신을 접종했고 약 2천만 건의 소아마비를 막을 수 있었습니다. 하지만 이것만으로는 충분하지 않습니다. 우리는 소아마비 발병률 제로를 달성하고 전 세계 어린이들과의 약속을 지키기 위해 엔드폴리오나우 캠페인을 계속 지원해야 합니다.
            {'\n'}{'\n'}
            &nbsp;소아마비 퇴치만이 우리가 전 세계적으로 펼치는 유일한 노력은 아닙니다. 사실 로타리는 그보다 훨씬 오래전부터 세계평화를 위해 노력해왔습니다. 전 세계의 봉사 프로젝트와 로타리 평화 센터를 통해 적극적 평화를 확산시키는 것이 그 어느 때보다 중요합니다.
            {'\n'}{'\n'}
            &nbsp;우리는 또한 내부로부터 평화를 지속적으로 구축해야 하며, 이는 동료 로타리 회원들과 우리가 봉사하는 지역사회의 정신건강과 웰빙을 지원하는 일에서부터 시작됩니다. 로타리는 아직 존재하지 않는 글로벌 정신건강 시스템을 구축할 수 있는 기회를 맞이했습니다. 저는 회원들이 정신건강 이니셔티브에 관한 로타리 액션 그룹에 가입해 올해 시작된 추진력을 이어갈 것을 촉구합니다.
            {'\n'}{'\n'}
            &nbsp;여러분의 회장으로 봉사하게 된 것은 일생일대의 영광이었습니다. 여러분은 헤더와 저에게 영원히 소중하게 간직할 추억을 선사해주었습니다. 우리의 지속적인 우정과 '세상에 희망을' 심기 위한 공동의 노력을 기대하겠습니다.
            {'\n'}{'\n'}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default MessageRi;
