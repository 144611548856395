import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const MainNews = () => {
  const newsData = [
    {
      id: 1,
      title: '서산 사랑의집 나눔회 집짓기',
      description: '',
      date: '5월 25일(토)',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbAnpIR%2FbtsH3xA2Ut9%2F25OrHKllhZgiIbRVZ4ihIK%2Fimg.png',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontSize: '2rem', color: '#194C86', textAlign: 'center', borderBottom: '4px solid #194C86', padding: '1rem', cursor: 'pointer' }}>
          <strong>서산지역</strong>
        </Typography>
      </Grid>
      {newsData.map((news) => (
        <Grid item xs={12} sm={6} md={4} key={news.id}>
          <Link to={`/mainNews/${news.id}`} style={{ textDecoration: 'none', color: 'black', textAlign: 'center' }}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image={news.imageUrl}
                alt={news.title}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {news.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news.date}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default MainNews;
