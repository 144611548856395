// "short_name": "RI3620 총재월신",
// "name": "국제로타리 3620지구 총재월신",

// {
//   "hosting": {
//     "public": "build",
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ],
//     "rewrites": [{
//     "source": "**",
//     "destination": "/index.html"
//     }]
//   }
// }

import React, { useEffect } from 'react';
// import React, { useState, useEffect } from 'react';
import { CssBaseline, Container, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import Purpose from './components/contents/Purpose';
import MainScreen from './components/main/MainScreen';
import MessageRi from './components/contents/message/MessageRi';
import Message3620 from './components/contents/message/Message3620';
// import Inauguration from './components/contents/Inauguration';
import GiguNews from './components/main/GiguNews';
import AreaNews from './components/main/AreaNews';
import ClubNews from './components/main/ClubNews';
import Logo from './components/header/Logo';
import ScrollToTop from './components/main/ScrollToTop';
import GiA from './components/contents/GiA';
import GiB from './components/contents/GiB';
import GiC from './components/contents/GiC';
import GiD from './components/contents/GiD';
import GiE from './components/contents/GiE';
import GiF from './components/contents/GiF';
import GiH from './components/contents/GiH';
import GiG from './components/contents/GiG';
import AreaA from './components/contentsArea/AreaA';
// import AreaB from './components/contentsArea/AreaB';
// import AreaC from './components/contentsArea/AreaC';
// import AreaD from './components/contentsArea/AreaD';
import AreaE from './components/contentsArea/AreaE';
import MainContents from './components/contents/MainContents';
import DonationScreen from './components/donation/DonationScreen';
import DonationScreenJ from './components/donationJ/DonationScreenJ';
import ClubScreen from './components/clubActivity/ClubScreen';
import ClubTotalScreen from './components/clubTotal/ClubTotalScreen';
import ClubTotalScreenJ from './components/clubTotalJ/ClubTotalScreenJ';
import MemberScreen from './components/member/MemberScreen';
import CalendarPage from './components/contents/Calendar';
import PlanList from './components/contents/PlanList';
// import PopupModal from './components/main/PopupModal';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import LoginPage from './components/auth/LoginPage';
import SignUpPage from './components/auth/SignUpPage';
import PasswordReset from './components/auth/PasswordReset';
import FindUserEmail from './components/auth/FindUserEmail';
import QRCodePage from './components/qrcode/QRCodePage';
import AdminPage from './components/admin/AdminPage';
import AuthProvider from './components/auth/AuthContext';
import TrainingPage from './components/contents/training/TrainingPage';
import QuizPage from './components/quiz/QuizPage';
import AnswersPage from './components/quiz/AnswersPage';
import SourcePage from './components/source/SourcePage';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-8LY3FPN6YD');
    // 위치가 바뀔 때마다 페이지 뷰 추적
    const currentPath = location.pathname + location.search;
    ReactGA.set({ page: currentPath });
    ReactGA.pageview(currentPath);
  }, [location]);

  return null; // 이 컴포넌트는 시각적으로 아무 것도 렌더링하지 않음
};

const App = () => {
  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   setShowModal(true);
  // }, []);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000',
      },
      secondary: {
        main: '#ff5722',
      },
    },
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <GoogleAnalytics />
          <ScrollToTop />
            <Header />
            <Logo />
            {/* {showModal && <PopupModal onClose={handleCloseModal} />} */}
            <Container style={{ marginTop: '65%', position: 'relative'}}>
                <Routes>
                  <Route exact path="/" element={<MainScreen />} />
                  <Route path="/training" element={<TrainingPage />} />
                  <Route path="/source" element={<SourcePage />} />
                  <Route path="/purpose" element={<Purpose />} />
                  <Route path="/calendar" element={<CalendarPage />} />
                  <Route path="/planlist" element={<PlanList />} />
                  <Route path="/ri" element={<MessageRi />} />
                  <Route path="/3620" element={<Message3620 />} />
                  {/* <Route path="/notice1" element={<Inauguration />} /> */}
                  <Route path="/notice2" element={<MemberScreen />} />
                  <Route path="/notice3" element={<DonationScreen />} />
                  <Route path="/notice4" element={<ClubTotalScreen />} />
                  {/* <Route path="/notice5" element={<Inauguration />} /> */}
                  <Route path="/notice6" element={<MemberScreen />} />
                  <Route path="/notice7" element={<DonationScreenJ />} />
                  <Route path="/notice8" element={<ClubTotalScreenJ />} />
                  <Route path="/page1" element={<GiguNews />} />
                  <Route path="/page2" element={<AreaNews />} />
                  <Route path="/page3" element={<ClubNews />} />
                  <Route path="/mainNews/1" element={<MainContents />} />
                  <Route path="/news/1" element={<GiA />} />
                  <Route path="/news/2" element={<GiC />} />
                  <Route path="/news/3" element={<GiD />} />
                  <Route path="/news/4" element={<GiE />} />
                  <Route path="/news/5" element={<GiF />} />
                  <Route path="/news/6" element={<GiB />} />
                  <Route path="/news/7" element={<GiH />} />
                  <Route path="/news/8" element={<GiG />} />
                  <Route path="/newsArea1/1" element={<AreaA />} />
                  {/* <Route path="/newsArea1/2" element={<AreaB />} />
                  <Route path="/newsArea1/3" element={<AreaC />} />
                  <Route path="/newsArea1/4" element={<AreaD />} /> */}
                  <Route path="/newsArea1/5" element={<AreaE />} />
                  <Route path="/clubNews/1" element={<ClubScreen />} />
                  {/* Auth */}
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/reset-password" element={<PasswordReset />} />
                  <Route path="/find-id" element={<FindUserEmail />} />
                  {/* QR */}
                  <Route path="/qr-code-page" element={<QRCodePage />} />
                  {/* Admin */}
                  <Route path="/admin" element={<AdminPage />} />
                  {/* Quiz */}
                  <Route path="/quiz" element={<QuizPage />} />
                  <Route path="/answers_admin" element={<AnswersPage />} />
                </Routes>
            </Container>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;