import React from 'react';
import YoutubeCard from './YoutubeCard';
// import QuizBtn from '../../quiz/QuizBtn';

const videoData = [
  {
    title: "로타리와 환경",
    description: "조은석 연수위원",
    videoId: "nigvcBwPEqk?si=cnq9lX1fo8ZXL8PY"
  },
  {
    title: "클럽정관과 세칙",
    description: "이종근 연수위원",
    videoId: "dsLFaYq8YX4?si=PvYQ2hgQPEnHRu2C"
  },
  {
    title: "멤버십의 중요성",
    description: "양병성 연수위원",
    videoId: "VoVNgjiNZEo?si=2OMNeobKFFPlOVeP"
  },
  {
    title: "보조금의 종류",
    description: "이종근 연수위원",
    videoId: "KO6ntqMhMPo?si=E0aiLi_Pq-87Rax6"
  },
  {
    title: "소아마비 퇴치 활동",
    description: "고영찬 연수위원",
    videoId: "UP2BWKaiVQQ?si=QFUw_enHhMLS-uUA"
  },
  {
    title: "로타리의 가치와 홍보전략",
    description: "손봉환 연수위원",
    videoId: "hv3N9VfzOJw?si=r8LnKnJ9p1Bi__6S"
  },
  {
    title: "멤버십의 필요성",
    description: "김선욱 연수위원",
    videoId: "LQKi_y9W4sM?si=nfhx91a4Z5pWgO17"
  },
  {
    title: "신입회원 영입",
    description: "강미정 연수위원",
    videoId: "26yKUXcIq2E?si=zR6a9RHmYtIpttJV"
  },
  {
    title: "로타리재단의 필요성",
    description: "이덕주 연수위원",
    videoId: "tv6JPV_3OXQ?si=IM_eGR00Qhn0Zaci"
  },
  {
    title: "청소년의 중요성",
    description: "홍미영 연수위원",
    videoId: "gaIzfNZzjHo?si=-LUsWyCM84IJfjja"
  },
  {
    title: "공공이미지의 중요성",
    description: "황혜경 연수위원",
    videoId: "8T0vdZkwdTg?si=RrAF7-QO_YmoSksz"
  },
  // {
  //   title: "",
  //   description: "",
  //   videoId: ""
  // },
];

const TrainingPage = () => {
  return (
    <div>
      {videoData.map(({ title, description, videoId }, index) => (
        <>
          <YoutubeCard
            key={index}
            title={title}
            description={description}
            videoUrl={`https://www.youtube.com/watch?v=${videoId.split('?')[0]}`}
          />
          {/* {title === "멤버십의 필요성" && <QuizBtn />} */}
        </>
      ))}
    </div>
  );
};

export default TrainingPage;
