import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '-30px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-150px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-300px',
    },
  },
}));

export default useStyles;
