import { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { functions } from '../../firebase'; // Firebase 초기화 코드에 functions import 추가
import { httpsCallable } from 'firebase/functions'; // Firebase Functions 사용을 위해 추가
import { Link } from 'react-router-dom';

const FindUserEmail = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    setError('');
    setEmail(''); // 이전 검색 결과 초기화

    const findUserEmailByName = httpsCallable(functions, 'findUserEmailByName'); // 수정됨

    try {
      const result = await findUserEmailByName({ name });
      setEmail(result.data.email);
    } catch (error) {
      console.error('Error searching user:', error);
      setError('사용자 검색 중 오류가 발생했습니다.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <h2>이메일 찾기</h2>
        <form onSubmit={handleSearch} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <TextField
            label="사용자 이름"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" color="primary">
            검색
          </Button>
        </form>
        {email && <p>이메일: {email}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}

        <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link to="/signup" style={{ textDecoration: 'none', color: 'gray' }}>회원가입</Link>
          <span style={{ margin: '0 10px' }}>|</span>
          <Link to="/login" style={{ textDecoration: 'none', color: '#194C86' }}>로그인</Link>
          <span style={{ margin: '0 10px' }}>|</span>
          <Link to="/reset-password" style={{ textDecoration: 'none', color: 'gray' }}>비밀번호 찾기</Link>
        </div>
      </div>

    </div>
  );
};

export default FindUserEmail;
