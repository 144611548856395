import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  {
    clubName: '천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안삼거리 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안파로스 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안드림 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안충남 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data2 = [
  {
    clubName: '성환 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '서천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '남천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안하늘 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안천룡 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안백두 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안쌍봉 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안새충무 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data3 = [
  {
    clubName: '새천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안능수 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안도솔 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안동남 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안상록 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안천지 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안희망 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안리더스 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안동호 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안버들 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data4 = [
  {
    clubName: '천안중앙 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안목주 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안일봉 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안남산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안서북 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안아우내 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안무궁화 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안다온 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '천안다온 로타랙트',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data5 = [
  {
    clubName: '온양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '온양목련 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산둔포 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산배방 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산온궁 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산성웅 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '온양온천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '',
  },
];

const data6 = [
  {
    clubName: '새온양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '온아 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '온양중앙 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산온정 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산현충 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산단미 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '아산온수 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data7 = [
  {
    clubName: '예산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '신례원 RC',
    a: '',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '고덕 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '예산어울림 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data8 = [
  {
    clubName: '당진 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '석문 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '합덕 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진당나루 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진송악 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진신평 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진고대 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진해나루 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진아미 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '당진골드 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data9 = [
  {
    clubName: '광천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '홍성 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '청양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '홍성홍서 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '홍성홍주 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '청양칠갑산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '홍성조양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '내포 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '광천오서산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '홍성용봉 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '홍성운주 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data10 = [
  {
    clubName: '대천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '대천중앙 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '대천서부 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '뉴대천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '대천더함 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data11 = [
  {
    clubName: '서산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '서령 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '새서산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '충서 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '서산한서 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '서산보라매 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '서산국화 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '서산가온 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const data12 = [
  {
    clubName: '태안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '안면도 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '태안소성 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '태안백화산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '태안해당화 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '안면도송림 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
  {
    clubName: '태안한결 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
    f: '●',
    g: '●',
    h: '●',
    i: '●',
  },
];

const ClubTotalD = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안1지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data1.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안2지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data2.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안3지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data3.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안4지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data4.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산1지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data5.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산2지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data6.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 예산지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data7.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 당진지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data8.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 홍성∙청양지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data9.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 보령지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data10.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 서산지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data11.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 태안지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={classes.cell} colSpan={10} style={{ backgroundColor: '#f0f0f0' }}>지구회비 및 분담금</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구회비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년 및<br/>청소년교환<br/>지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>재난기금 및<br/>지구사무실<br/>준비금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제프로그램<br/>추진비</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>공공이미지<br/>분담금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>다문화, <br/>북한이탈<br/>주민정착지원금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제봉사단<br/>지원금<br/></TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>무궁화<br/>나무심기사업</TableCell>
                </TableRow>
                {data12.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.f}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.g}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.h}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.i}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <br/><br/>
    </div>
  );
};

export default ClubTotalD;