import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import useStyles from '../../../styles/contents/MessageRiStyle';

const Message3620 = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcwVP3p%2Fbtsn7qWiaWo%2FNOGa9IMwOA5O3Pjm53l530%2Fimg.png"
            alt="logo"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.profileTitle}>
            <strong>2023-24년도 총재<br/> 6월 메시지</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.pictureFrame}>
            <img
              src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FqCYjr%2FbtspFldAUtT%2FxY1k04aOVKmLroAJMb7rk0%2Fimg.png"
              alt="riProfiles"
              className={classes.profile}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.profileContainer}>
            <Typography variant="h6" className={classes.profileName}>
              <strong>해암 박홍전 총재</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.messageText}>
            {'\n'}
            &nbsp;사랑하고 존경하는 국제로타리 3620지구 로타리안 여러분.
            {'\n'}{'\n'}
            &nbsp;저는 지난 회기동안 저와 함께 한 모든 임원들의 정성어린 도움으로 우리 지구 회원여러분께 투명한 결산으로 임기를 마칠 수 있게 됨을 진심으로 감사하고 있습니다.
            {'\n'}{'\n'}
            &nbsp;2023-24년에는 로타리안 여러분과 국제봉사에 대한 애정이 깊었습니다. 3년 프로젝트 인 캄보디아 르비아 로타리3620지구초등학교 지어주기를 마무리하고 필리핀 빈냔시 “우정의 마을“ 100채 집 지어주기를 시작한 이번 회기는 국제로타리 3620지구의 변화의 시작이자 도약의 원년이라 정의합니다.
            {'\n'}{'\n'}
            &nbsp;저는 임기 시작과 함께 한 총재 공식 방문을 통하여 클럽의 실정을 인식하고 클럽의 정리되지 못한 것들을 회원들과 함께 고민하고 살펴 반듯하게 세운 것은 큰 보람으로 기억 될 것입니다. 3700여명의 회원 모두가 주인공입니다.
            {'\n'}{'\n'}
            &nbsp;특히 존 11,12에서 처음으로 총재월신을 지면에서 웹으로 전환하였습니다. 웹진으로 총재월신 첫 회를 발행했을 때의 두려움,설레임이 이제는 확신으로 자리하게 되었습니다.
            {'\n'}{'\n'}
            &nbsp;존경하는 성지 문상식 총재님께서 편집위원장을 맡으시고 황혜경 편집부위원장을 비롯한 편집위원들과 지구기자, 지역기자들의 정성과 열정으로 이제 언제 어디서나 함께 하는 손 안의 총재월신이 되었습니다. 그동안의 노고에 감사드립니다.
            {'\n'}{'\n'}
            &nbsp;2023-24년도 저와 함께한 우리 지구 로타리안 여러분 고맙고 감사합니다.
            {'\n'}{'\n'}
            &nbsp;저는 여러분이 너무나 자랑스럽습니다.
            {'\n'}{'\n'}
            &nbsp;<span style={{ fontWeight: 'bold' }}>세상에 희망을! 행복한 로타리!</span>
            {'\n'}{'\n'}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Message3620;