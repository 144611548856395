import React from 'react';
import { format } from 'date-fns';
import styles from '../../styles/admin/AdminPage.module.css';

const AttendanceTable = ({ recognizedUsers }) => {
  return (
    <div className={styles['attendance-table']}>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Club</th>
            <th>Phone Number</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {recognizedUsers.map((record, index) => {
            // Firestore 타임스탬프를 Date 객체로 변환
            const timestampDate = record.data.timestamp.toDate();
            // Date 객체를 원하는 포맷의 문자열로 변환
            const formattedDate = format(timestampDate, 'yyyy-MM-dd HH:mm:ss');
            return (
              <tr key={`${record.id}-${index}`}>
                <td>{record.data.name}</td>
                <td>{record.data.club}</td>
                <td>{record.data.phoneNumber}</td>
                <td>{formattedDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceTable;

