import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 당진지역 ]",
    title2: "탄소중립 업무 협약식",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F1ZlUu%2FbtsHTm7ba3w%2FsesEPsuZAdh20izaaYkrcK%2Fimg.jpg',
    description: '&nbsp;5월 9일, 탄소중립 업무 협약식에 참여했다. 지역사회 내 탄소중립 실현 및 탄소중립 생활 실천의 확립을 위한 상호 협력관계를 목적으로 지역내 단체들과 MOU를 체결했다. 2024-25년도 차기에서 중점 사업할 예정이다.',
  },
  {
    title1: "[ 당진 로타리클럽 ]",
    title2: "5월 정기 배식봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FovQ5e%2FbtsHRJQA8kH%2FOHI6imu0JzWOKKT7ryR7V1%2Fimg.jpg',
    description: '&nbsp;5월 9일 11시, 당진시 노인복지관에서 5월 정기 배식봉사를 실시했다.',
  },
  {
    title1: "[ 당진당나루 로타리클럽 ]",
    title2: "화재 취약지역 해소를 위한<br/>소화기 및 감지기 전달",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F4pOV5%2FbtsHSUcc3ar%2Fxj7KOpHzixfgFIfonIkJn1%2Fimg.jpg',
    description: '&nbsp;5월 3일 당진소방서에 500만원 상당의 소화기 및 화재감지기를 당진소방서 최장일 서장에게 전달하며, 화재취역 가구에 잘 전달 될수 있도록 부탁하였다. 전달은 당진시복지재단을 경유하여 당진소방서에 전달한 것이다. 최장일 서장은 지역주민의 안전을 위해 물품을 기증해 주신 관계자분들께 감사드린다고 하며 화재예방이 필요한 곳에 잘사용하겠다고 말했다. 2016년도에도 500만원의 주택기초 소방시설을 기증한바 있어, 소방서 임직원들이 지속적인 봉사에 감사드린다고 하였다.',
  },
  {
    title1: "[ 당진송악 로타리클럽 ]",
    title2: "학교 밖 생활지도",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbWJHIp%2FbtsHRvx7s71%2FINGn4kqdKlR4s1olkf7Zqk%2Fimg.jpg',
    description: '&nbsp;5월 2일 오후 8시, 2023-24 학교 밖 생활지도를 실시했다. 이번 엄마순찰대와 함께하는 학교 밖 생활지도는 공공이미지위원회(소향 김용례 위원장) 주관으로 진행되었다.',
  },
  {
    title1: "[ 당진송악 로타리클럽 ]",
    title2: "제25대 임원 및 이사회",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FCUiZB%2FbtsHSr9rC6f%2FfKc4kGJDGxJBqd0FsrK9N0%2Fimg.jpg',
    description: '&nbsp;5월 7일 오후 6시 30분, 2023-24년 제25대 임원 및 이사회 열두번째 모임이 커피만나에서 있었다. 이번 임원 이사회는 가족야유회, 지구대회, 신입회원 지식교육 관련 논의가 있었다.',
  },
  {
    title1: "[ 당진해나루 로타리클럽 ]",
    title2: "송악복지관 정기봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbc6fLJ%2FbtsHSASC9kn%2Fof2Zkcy1UQKpmqNOH8lmVk%2Fimg.jpg',
    description: '&nbsp;5월 9일, 송악복지관 정기봉사를 실시했다.',
  },
  {
    title1: "[ 당진아미 로타리클럽 ]",
    title2: "당진시장 표창패 수상",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcs4Wm0%2FbtsHSvYd8hY%2FRexdHMEZHXOK7PqMsJJtu0%2Fimg.png',
    description: '&nbsp;5월 3일(금), 당진시 남부노인복지관 (관장 최태선)에서 12회 카네이션 대축제 "봄 놀다" 행사가 있었다. 이날 제52회 어버이날을 맞아, 평소 지역 어르신들에게 봉사를 실천한 공로로 당진시장(오성환)으로부터 표창패를 받았다. 온동 최지웅 회장은 "어르신들 뿐만 아니라 어려운 이웃들이 희망과 사랑을 잃지 않도록 지속적인 관심과 따뜻한 손길을 내밀겠다"고 소감을 밝혔다.',
  },
  {
    title1: "[ 당진아미 로타리클럽 ]",
    title2: "당진시 남부 노인복지관<br/>배식봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbYNtBG%2FbtsHS0pVklX%2FTZqsmc3Y6K6O8AwYPnKwWK%2Fimg.jpg',
    description: '&nbsp;5월 22일(수),  당진시 남부노인복지관에서 배식봉사를 실시했다.',
  },
]

const ClubDj = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubDj;
