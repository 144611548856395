import React, { useState } from 'react';
import { LoginContainer, Form, FormControl as CustomFormControl, CustomButton } from '../../styles/auth/SignupScreenStyles';
import { TextField } from '@mui/material';
import { auth } from '../../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/quiz');
    } catch (error) {
      setError('이메일 또는 비밀번호를 다시 확인해주세요.');
      console.error('Login failed:', error.message);
    }
  };

  return (
    <LoginContainer>
      <h2>로그인</h2>
      <Form onSubmit={handleLogin}>
        <CustomFormControl>
          <TextField 
            label="이메일" 
            value={email.trim()} 
            onChange={(e) => setEmail(e.target.value)} 
            fullWidth 
            required 
          />
        </CustomFormControl>
        <CustomFormControl>
          <TextField 
            label="비밀번호" 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            fullWidth 
            required 
          />
        </CustomFormControl>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        <p style={{ textAlign: 'center', marginBottom: '-5px', color: 'grey' }}>
          QR출석 시 가입한 아이디를 사용하세요.
        </p>

        <CustomButton type="submit">Login</CustomButton>

        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link to="/signup" style={{ textDecoration: 'none', color: 'gray' }}>회원가입</Link>
          <span style={{ margin: '0 10px' }}>|</span>
          <Link to="/find-id" style={{ textDecoration: 'none', color: 'gray' }}>아이디 찾기</Link>
          <span style={{ margin: '0 10px' }}>|</span>
          <Link to="/reset-password" style={{ textDecoration: 'none', color: 'gray' }}>비밀번호 찾기</Link>
        </div>
      </Form>
    </LoginContainer>
  );
};

export default LoginPage;
