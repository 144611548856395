// https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png

import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/areaContents/AreaStyle.js';
import ComplexBtn from '../main/ComplexBtn.js';

const AreaE = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          국제로타리 3620지구{'\n'}
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>서산지역 협의회{'\n'}{'\n'}</span>
        </Typography>
        
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb5dmyE%2FbtsIahTJEY6%2F6dDwhwfQHfOG4MTiK2poW0%2Fimg.jpg'
            title="main4"
          />
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;해냄 장광순{'\n'}&nbsp;서산지역 대표{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>

        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FFwGlC%2FbtsIcjJmeR0%2FEJvkfoVmWLd1aLNU4ffUH1%2Fimg.jpg'
            title="main4"
          />
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서남 박상목{'\n'}&nbsp;사무차장{'\n'}
        </Typography>

        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fycnui%2FbtsIcR6DEs7%2F6ibMDrlneFs962nMyGwWT0%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;이수섭{'\n'}&nbsp;서산지역기자{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서산 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcwm9TT%2FbtsIaF0Yjh9%2F0PGQTkNxgIIvAid4JeVu50%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산 로타리클럽{'\n'}&nbsp;이수민 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbQrSaE%2FbtsIbTD9Mkc%2FqAPQJDAkvJzKeEVkGjpZk0%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산 로타리클럽{'\n'}&nbsp;최창용 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서령 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FkD5yp%2FbtsIbiYWhx1%2FkKyGOyGV4KeVacJkjVmPI1%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서령 로타리클럽{'\n'}&nbsp;허만 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fdw0Wwv%2FbtsIb5j9JDV%2FOK6TtPooC6X4ykY3y6fE1k%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서령 로타리클럽{'\n'}&nbsp;장금철 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 새서산 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F3opAx%2FbtsIaTSB15R%2FK8LrsKk6YH92jZxgwkjk30%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;새서산 로타리클럽{'\n'}&nbsp;김성환 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FnlIFB%2FbtsIcslTzpI%2Fu2ikvGboEyqoNSCvFexhk1%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;새서산 로타리클럽{'\n'}&nbsp;유경모 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 충서 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpPhXp%2FbtsIbqh87He%2FRkUKHQk9kHjKYO5rjT4FP1%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;충서 로타리클럽{'\n'}&nbsp;태하 정일권 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbaf9SU%2FbtsIcBQoPe4%2F9AJIOOPmAZtXpG1pKOKuCK%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;충서 로타리클럽{'\n'}&nbsp;김도우 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서산팔봉 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image=''
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산팔봉 로타리클럽{'\n'}&nbsp;박동철 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image=''
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산팔봉 로타리클럽{'\n'}&nbsp;이근형 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서산한서 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FEdxIK%2FbtsIb3GC5IF%2Ft2Kplmw1eqhPMsTk0MMcck%2Fimg.png'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산한서 로타리클럽{'\n'}&nbsp;김주태 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbNFD1t%2FbtsIbmUdErl%2Fzbkf2Kq44f3sm8kfRV6bGk%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산한서 로타리클럽{'\n'}&nbsp;한도현 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서산보라매 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FZL668%2FbtsH93A5SZQ%2F4N8UanCfPfWSTfNynTUZKk%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산보라매 로타리클럽{'\n'}&nbsp;이기봉 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbUOTGZ%2FbtsIajRx2C1%2FbB5PyWPMIldeMAwpDKMiaK%2Fimg.png'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산보라매 로타리클럽{'\n'}&nbsp;정점식 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서산국화 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbyU5Mu%2FbtsH9LHrLIo%2FWyxMkfYE3YkEAuq6QqAkt0%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산국화 로타리클럽{'\n'}&nbsp;김은진 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcuNUcS%2FbtsIbwvvIyp%2FwJPoTjexEdVGYenZfijg91%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산국화 로타리클럽{'\n'}&nbsp;이은심 총무{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}{'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>[ 서산가온 로타리클럽 ]{'\n'}{'\n'}</span>
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbdxa5h%2FbtsH9K2N9Cu%2FdSFQpIh9gA8KXqjMcBLIDk%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산가온 로타리클럽{'\n'}&nbsp;김은자 회장{'\n'}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}
        </Typography>
        <Typography className={`${classes.preserveNewline} ${classes.mediaCenter}`}>
          <CardMedia
            className={classes.media4}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F2KmuN%2FbtsIcP8OjpP%2FlW41tHHvZQdEfck13ef2U1%2Fimg.jpg'
            title="main4"
          />{'\n'}{'\n'}
        </Typography>
        <Typography variant="h6" className={`${classes.preserveNewline} ${classes.mediaCenter} ${classes.smallText}`}>
          <br/><br/><br/>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;서산가온 로타리클럽{'\n'}&nbsp;이명임 총무{'\n'}
        </Typography>

      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default AreaE;
