import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiF = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        {/* <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          2023-24년도 세상에 희망을 준 행복한 로타리를 돌아보며{'\n'}{'\n'}
        </Typography> */}

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FIuzl4%2FbtsH882xYps%2F0eWepzcaB4kGChPtdftJrK%2Fimg.png'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FxogJ2%2FbtsH00dzzJk%2FDwvcu9yXYOGfZtnP37pbC0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>3620지구 총재</span>{'\n'}
          해암 박홍전
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FqCYjr%2FbtspFldAUtT%2FxY1k04aOVKmLroAJMb7rk0%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"국제봉사는 우리가 해야만 하는 일"</span>이다.
          {'\n'}{'\n'}
          &nbsp;4년전 캄보디아 르비아로타리3620초등학교 지어주기에 회원들과 함께 참석하여 국자로 땅을 파고 기둥을 세우면서 총재의 꿈을 함께 세우던 그때가 지금의 행복한 로타리를 만들었다. 회원과 함께  땀흘리며 봉사하는 것이 진정한 행복한 로타리다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>천안1지역대표</span>{'\n'}
          보륜 홍춘기
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcLbGNh%2FbtsDjWTfTw9%2Fr3Gfl8VdMtp53mmSCqdUX0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"나의 삶에 변화"</span>이다.
          {'\n'}{'\n'}
          &nbsp;대표로 추대해주신 해암 총재님께 감사하다. 많이 부족했지만 열심히 최선을 다했다고 생각하며, 로타리는 나의 삶에 변화를 준다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>천안2지역대표</span>{'\n'}
          자륜 정관호
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FS1kcs%2FbtsByzFQK3g%2FafA44SWWxr68VHpMrOA3E0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"사랑"</span>이다.
          {'\n'}{'\n'}
          &nbsp;힘들지 않은 일이 없다. 서로 안맞아도 맞추려고 노력하는 것이 중요하다. 어려운 클럽(아픈 손가락)을 잘 이끌어 보려고 말 한마디라도 더 해주려 했지만 점점 멀어지더라. 서운하고 속상한 마음도 있었지만 조금이라도 발전되는 모습을 보면 보람이 매우 컸다. 
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>천안3지역대표</span>{'\n'}
          단경 김주성
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbajcpp%2FbtsxsRxdyC5%2F0IfIntS5JpoAgnG0koNuyk%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"행복"</span>이다.
          {'\n'}{'\n'}
          &nbsp;지역의 클럽들을 대표하면서 회원과 재단 증가로 큰 보람 있었다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>천안4지역대표</span>{'\n'}
          일호 박건순
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FmzsXW%2FbtsEOVd1Dxy%2FOQdNnGNNjSS5T4mEWCAEx1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"마라톤"</span>이다.
          {'\n'}{'\n'}
          &nbsp;로타리는 길게 보면서 꾸준히 가야한다. 너무 열정을 가지고 뛰면 지치게 된다. 회원들은 가까이 하기엔 먼 당신이더라. 열정을 갖고 다가가면 멀어지려 한다. 관심을 안가져도 멀어진다. 적당한 거리에서 관심을 가지고 바라볼 수 밖에 없다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>아산1지역대표</span>{'\n'}
          호설 최완수
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F6SPzo%2FbtsGu3gO2oi%2FOskkTgj2qaVfqWceYdDF60%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"믿음소망사랑"</span>이다.
          {'\n'}{'\n'}
          &nbsp;로타리안들과 함께한 시간은 아름다음 그 자체다. 서로 믿음으로 하나가 되고 실천해 나가면서 보다 나은 로타리안이 되기 위한 소망으로 마무리를 한 것 같아 뿌듯하다. 앞으로 변치않는 사랑으로 하나가 되길 바라며, 감사하고 사랑합니다!
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>아산2지역대표</span>{'\n'}
          부운 송치운
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbQHIJP%2Fbtsz88pHSDZ%2Fq866y6dYltO1yAbaorTrg0%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"내 삶의 긍정에너지"</span>이다.
          {'\n'}{'\n'}
          &nbsp;로타리 가입 후 회장하고 지역대표하면서 생활에 조금씩 변화가 생겼다. 사람을 대할 때 마음가짐이 달라져서 배려가 커지고 긍정적으로 변했다. 로타리 가입하길 잘했다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>예산지역대표</span>{'\n'}
          동현 손동철
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FKSG6C%2FbtssYU59OKJ%2FAaIar5o82gqjQZkTYcWR5k%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"운명"</span>이다.
          {'\n'}{'\n'}
          &nbsp;갑자기 얼떨결에 대표가 되었지만 로타리에 대해 더 많은 공부와 봉사를 해야겠다고 느끼면서 이게 운명이 아닌가 생각한다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>당진지역대표</span>{'\n'}
          주도 김영노
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FzQ8R2%2FbtsFCyvebjd%2F7dTr0oJ49XYnnHv8OGaasK%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"봉사"</span>이다.
          {'\n'}{'\n'}
          &nbsp;일년간 지역대표 생활하면서 힘들었지만 나에게 기쁨을 많이 주었다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>홍성∙청양지역대표</span>{'\n'}
          절제 이천우
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdiXCcn%2FbtsyMqZWkon%2F4x6sZdhYdLD47U8lsFK7Dk%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"진심"</span>이다.
          {'\n'}{'\n'}
          &nbsp;입회부터 지구와 클럽의 임원을 거쳐 지역대표가 되는 동안 점점 더 큰 의미로 와닿은 로타리.
          {'\n'}
          &nbsp;30대에 처음 로타리를 시작할 때 로타리는 내 인생의 일부에 지나지 않았으나, 지금 내겐 로타리가 내 인생의 대부분을 차지하고 있을 만큼 로타리에 대한 나의 마음은 정말 <span style={{ fontWeight: 'bold' }}>진심</span> 그 자체이다. 나의 이러한 마음이 통했는지 지난 1년간 11개 클럽이 적극적인 참여와 아낌없는 지원으로 신입회원 증강에 동참해 주었다.
          {'\n'}
          &nbsp;처음 11개 클럽 중 30명 이하 클럽이 7개, 그중 20명 이하가 2개 클럽으로 열악한 상황이어서 정말 막막했다. 회원 수가 적을수록 예산 편성도 쉽지 않고, 재단 기부와 국제봉사까지 감당해야 할 회장님들이 걱정이 되었다. 그래서 [회원 30명 이상 달성] 목표를 세워 신입회원 등록 시 RFSM과 폴리오기금을 전달하여 힘을 실었다. 처음에는 어렵겠다고 생각했지만, 모두가 함께 노력하니 어느덧 좋은 결실을 맺었다. 
          {'\n'}
          &nbsp;물론 모든 클럽이 30명 이상을 달성하지 못한 것은 큰 아쉬움으로 남았지만 23-24년도 회장, 총무님들의 노력으로 24-25년도 회기를 힘차게 출발하는데 좋은 밑받침이 되리라 믿는다.
          {'\n'}
          &nbsp;1년 동안 슬픈 일에 함께 슬퍼하고, 기쁜 일에 더욱 기뻐해 주었던 우리 홍성.청양지역 식구들 너무도 감사하다. 술도 못 마시는 재미없는 지역대표를 항상 챙겨주고, 묵묵히 믿고 따라준 덕분에 무사히 지역대표 역할에 최선을 다할 수 있었던 것 같다. 빼곡한 일정에 힘든 날들도 있었지만 그들이 있었기에 행복했다.
          {'\n'}
          &nbsp;불꽃 같았던 열정은 잠시 내려놓고, 이제는 숯불처럼 성숙된 모습으로 최고의 로타리안이었던 홍성∙청양지역 식구들과 함께 행복한 봉사를 오래도록 지속하고 싶다.
          {'\n'}
          &nbsp;안되면 되게하라! 홍성∙청양지역 미래를 위해~
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>보령지역대표</span>{'\n'}
          대야 강한구
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbAkHRY%2FbtspMJ0K0lP%2FXuaYPbEt9cqIc8clWuBWN0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"자신감"</span>이다.
          {'\n'}{'\n'}
          &nbsp;대표 지명 받았을때 자신감 없어서 고사했는데 지인이 다시 한번 해보라 권유해서 수락했다. 맡으니 어디선가 자신감이 나오더라. 이후 모든 사회생활에 자신감이 생겼다. 앞으로도 후배들을 위해 열심히 노력하겠다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>서산지역대표</span>{'\n'}
          해냄 장광순
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb5dmyE%2FbtsIahTJEY6%2F6dDwhwfQHfOG4MTiK2poW0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"행복의 변환점"</span>이다.
          {'\n'}{'\n'}
          &nbsp;예전에는 방황하면서 행복을 다른 곳에서 찾았다. 로타리를 통해 너무 좋은 분들 만나서 행복을 찾았다. 특히 해외봉사하면서 많은 것을 깨달았다. 올 한해 너무 행복했다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>태안지역대표</span>{'\n'}
          태산 이상일
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FkUwLW%2FbtsHlGfoVB0%2F8fCBRWsdprWSSJSo0n28HK%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"행복의 변환점"</span>이다.
          {'\n'}{'\n'}
          &nbsp;영광스럽게 지역대표을 하며 많은걸 배웠다. 회장할 때와는 또 다른 로타리를 알게 되었고 잠시 흔들렸던 봉사의 마음을 다 잡는 계기가 되었다. 늘 그렇듯 로타리와 한평생 가보겠다고 다짐한다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>사무총장</span>{'\n'}
          미곡 김면수
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FDmjej%2FbtsIaUxdB8N%2FWACBA54ZIdfMWkkmqmlKik%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"행복"</span>이다.
          {'\n'}{'\n'}
          &nbsp;"나는 행복한 시간이었는가" 하고 지난 시간을 되돌아본다. 행사에 대한 중압감이 있을때마다 기꺼이 어깨를 내어준 의제 이준범 수석사무부총장님, 은효 강미 의전실장님, 다올 류해권 사찰위원장님, 청사 조욱현 기획실장님을 비롯한 지구임원 모든 분들께 감사의 마음을 전하며, 멋진 회기를 함께 만들어주신 각 클럽 회장,총무님을 비롯한 3,700여명의 로타리안 모든 분들께 고개숙여 인사를 드린다. "행복한 시간이었습니다!"
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>기획실장</span>{'\n'}
          청사 조욱현
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FrPJWB%2FbtsIbqWL88N%2Fk11kcrv6xP0NWrHPasZxM1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"터닝 포인트"</span>이다.
          {'\n'}{'\n'}
          &nbsp;올해 17년차 로타리 활동을 하면서 클럽과 지구임원을 정말 열심히 해 온 한해였다. 로타리의 참의미를 알게되고, 앞으로 어떻게 생활할 것인지 알게된 2023-24년도는 정말 행복한 로타리였다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>멤버십위원장</span>{'\n'}
          동림 김선욱
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fckk4iX%2FbtsIalhyJ5j%2FVjp7NQnCrziDQkTaKB4u4K%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"쉼터이자 놀이터"</span>이다.
          {'\n'}{'\n'}
          &nbsp;사회생활에 지친 나에게 로타리 봉사는 마음의 쉼터와 같고, 로타리를 통해 많은 리더들과 함께 자리이타를 나눌 수있는 놀이터로 더 많은 사람들과 함께하고 싶다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>모금소위원장</span>{'\n'}
          만야 이종근
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc6Hj9H%2FbtsIcFkWolR%2F2U7gzrwa7CK7KwrZe7Gx7k%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"다른 사람의 삶을 긍정적으로 변화시키는 힘"</span>이다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>연수실장</span>{'\n'}
          심산 이덕주
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbryvZe%2FbtsIaTd04PQ%2FicKbxxPO4kLfDbmyZT0Wfk%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"세상에 희망을 전하는 확실한 방법"</span>이다.
          {'\n'}{'\n'}
          &nbsp;생을 살아가면서 나 자신을 변화시키고자 끊임없이 노력한다. 경제적으로 풍요해짐을 쫒거나, 지식의 깊고 넓음을 추구하거나, 건강의 궁극을 찾기 위해 운동에 매진하는 등이다. 이러한 노력에도 한 구석이 비어있는 것 같은 느낌을 지울 수 없을 때 이를 채워주는 NGO활동이 로타리이고, 로타리가 추구하는 정신은 세계사회에 희망을 전할 수 있는 확실한 방법이라고 생각한다. 특히, 로타리의 클럽리더십플랜은 회원으로 시작해서 리더로 성장하는 표준화된 프로그램이며, 이는 나를 성장시키는 디딤돌 역할을 해주었고, 지금도 감사의 마음으로 봉사에 매진하고 있다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>의전실장</span>{'\n'}
          은효 강미
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbv2fN6%2FbtsIahTI9wk%2Fp81xcvy9K4rinLqsjres0k%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"또 하나의 가족"</span>이다.
          {'\n'}{'\n'}
          &nbsp;각기 다른 성격의 사람들이 만나 자연스럽게 하나가 되었고, 그들과의 만남은 언제나 기다려지고 설레인다. 그렇게 우리는 가족이 되었고, 함께한 시간들은 잊지못할 행복한 추억이 되었다. 새로운 가족과 함께 앞으로의 로타리 활동도 기대해 본다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>사찰위원장</span>{'\n'}
          다올 류해권
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fckg68c%2FbtsIaSe6H0p%2FWkdY3Sylf2kFmKMd2Lcmc0%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"같이 사는 인생"</span>이다.
          {'\n'}{'\n'}
          &nbsp;나만을 위해 살던 인생에서 같이 사는 인생의 맛을 알게 되었고, 나의 아픔보다 다른 이의 아픔을 돌아보는 삶이 시작되었다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>SNS 위원</span>{'\n'}
          한백 설창균
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F1ItDH%2FbtsIcynqwyZ%2FQDVCExAwUXZO5TMHUwEi20%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          나에게 로타리란 <span style={{ fontWeight: 'bold' }}>"선한영향력"</span>이다.
          {'\n'}{'\n'}
          &nbsp;자칫 이기적임이 일상으로 넘쳐나는 세상, 로타리를 통하여 좋은 사람들을 만나 봉사와 배려를 통해 나또한 좋은 영향을 받았다. 이제는 내가 누군가에게 선한 영향력이 되고 싶다.
          {'\n'}{'\n'}{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 총재월신편집위원회 부위원장{'\n'}&nbsp;한결 황혜경{'\n'}&nbsp;(온양목련 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiF;