// 봉사의인

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const DonationD = () => {
  const classes = useStyles();
  const rawData = `
당진RC 이수동 300,000 600,000
천안쌍봉RC 이순민 300,000 300,000
대천중앙RC 정준모 300,000 900,000
대천중앙RC 권순선 300,000 300,000
대천중앙RC 이보현 300,000 300,000
대천중앙RC 박정욱 300,000 300,000
대천중앙RC 유병효 300,000 300,000
대천중앙RC 송관섭 300,000 300,000
`;

const data = rawData
    .trim()
    .split('\n')
    .map((line) => {
      const parts = line.trim().split(' ');
      const finalCumulativeAmount = parts.pop();
      const donationAmount = parts.pop();
      const name = parts.slice(1).join(' ');
      const category = parts[0];
      return { category, name, donationAmount, finalCumulativeAmount };
    });

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>회원명</TableCell>
              <TableCell className={classes.cell}>기부액(원)</TableCell>
              <TableCell className={classes.cell}>누계액(원)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DonationD;
