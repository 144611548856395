import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#f0f0f0',
    color: 'black',
    fontSize: '10px',
    padding: '10px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  paragraph: {
    marginBottom: '-10px', // Adjust the margin between paragraphs as needed
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <p className={classes.paragraph}>
        <strong className={classes.boldText}>발행인 : </strong> 총재 해암 박홍전
      </p>
      <p className={classes.paragraph}>
        <strong className={classes.boldText}>발행처 : </strong> 국제로타리 3620지구 총재실
      </p>
      <p className={classes.paragraph}><strong className={classes.boldText}>편집위원장 : </strong> 성지 문상식</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>편집부위원장 :</strong> 한결 황혜경</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>편집위원 : </strong> 선우석, 전용우, 박현옥, 손봉환, 김태권, 조용진</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>로타리코리아 기자 :</strong> 전용우</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>3620지구 기자 : </strong> 문수협</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>지역기자 : </strong> 박순임, 조선호, 이승배, 최동석, 소근혜, 서한규, 장화균, 김영광, 김경진, 김태익, 이수섭</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>SNS위원장 : </strong> 문수협</p>
      <p className={classes.paragraph}><strong className={classes.boldText}>SNS위원 : </strong> 설창균, 손봉환</p>
      <br />
      <p className={classes.paragraph}><strong className={classes.boldText}>지구사무국</strong></p>
      <p className={classes.paragraph}>충청남도 천안시 서북구 오성로 121(두정동 유강빌딩 4층)</p>
      <p className={classes.paragraph}>전화 041)558-3620 / 팩스 041)585-3620</p>
      <p className={classes.paragraph}>이메일 ri3620korea@hanmail.net</p>
      <p className={classes.paragraph}>홈페이지 ri3620.com</p>
      <p className={classes.paragraph}></p>
    </footer>
  );
}

export default Footer;

