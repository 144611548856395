import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  {
    clubName: '천안 RC',
    memberList: '허준혁, 이일연, 전영권, 정수영, 김성환, 김향숙, 이진우, 남궁 윤, 박은수, 송영철, 유영찬, 방연정, 방명숙, 정호동',
    total: '14',
  },
  {
    clubName: '천안삼거리 RC',
    memberList: '채용준, 한도희, 김현민, 이승후, 이근형, 이태운, 임도운, 박일용, 정성권, 정만진, 김선호, 김준호',
    total: '12',
  },
  {
    clubName: '천안파로스 RC',
    memberList: '길재화, 장현서, 정덕순, 김금숙, 이은정, 이영자, 고명신, 이주희, 오순석',
    total: '9',
  },
  {
    clubName: '천안드림 RC',
    memberList: '최영민, 모성욱',
    total: '2',
  },
  {
    clubName: '천안충남 RC',
    memberList: '신태환',
    total: '1',
  },
];

const data2 = [
  {
    clubName: '성환 RC',
    memberList: '정길영, 유미란',
    total: '2',
  },
  {
    clubName: '서천안 RC',
    memberList: '김광겸, 안동준, 김수한, 김동우, 김성기, 김보철, 송하준, 유준오, 현동호, 김우현, 윤주완',
    total: '11',
  },
  {
    clubName: '남천안 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '천안하늘 RC',
    memberList: '조상진, 김은성, 김은경, 김동일, 배지영, 권민경, 김명화',
    total: '7',
  },
  {
    clubName: '천안천룡 RC',
    memberList: '권기동, 이용해, 오현',
    total: '3',
  },
  {
    clubName: '천안백두 RC',
    memberList: '서봉진, 안덕남',
    total: '2',
  },
  {
    clubName: '천안쌍봉 RC',
    memberList: '최승호, 함노태, 이광철, 박주형, 윤동진',
    total: '5',
  },
  {
    clubName: '천안새충무 RC',
    memberList: '백종우, 방승철, 도민수, 조광옥, 강진호, 이수원, 박정식, 이명자',
    total: '8',
  },
];

const data3 = [
  {
    clubName: '새천안 RC',
    memberList: '김형섭, 이근주',
    total: '2',
  },
  {
    clubName: '천안능수 RC',
    memberList: '안미영, 정세희, 김은진, 이수진, 유순이, 추예령, 최지호, 최혜숙, 최은미, 김현미, 김현숙',
    total: '11',
  },
  {
    clubName: '천안도솔 RC',
    memberList: '최병권, 길호진, 함석문, 한지영, 홍종철, 강명석, 이시석, 이영집, 임도형, 박영순',
    total: '10',
  },
  {
    clubName: '천안동남 RC',
    memberList: '안정현, 정경문, 곽영호, 김민구, 이중희, 이용연, 이수진, 오준경, 오세영',
    total: '9',
  },
  {
    clubName: '천안상록 RC',
    memberList: '조윤희, 전진선, 강민서, 김영아, 김영옥, 김미형, 이진옥, 이정희, 이지영, 박찬임, 우연주, 윤헤경',
    total: '12',
  },
  {
    clubName: '천안천지 RC',
    memberList: '김영삼',
    total: '1',
  },
  {
    clubName: '천안희망 RC',
    memberList: '김학진, 곽수호, 곽동신, 이영찬, 이응렬, 이호열, 이정길, 이상준, 양세직',
    total: '9',
  },
  {
    clubName: '천안리더스 RC',
    memberList: '이화수, 이경순, 맹혜란, 원유경, 최승미, 황순덕, 신예서',
    total: '7',
  },
  {
    clubName: '천안동호 RC',
    memberList: '김익주, 전배훈, 김대헌, 이현노, 신경진',
    total: '5',
  },
  {
    clubName: '천안버들 RC',
    memberList: '정재철',
    total: '1',
  },
];

const data4 = [
  {
    clubName: '천안중앙 RC',
    memberList: '강창곤, 권혁진, 이영선, 강창곤, 권혁진, 이영선, 백주은, 김영근, 정현서',
    total: '9',
  },
  {
    clubName: '천안목주 RC',
    memberList: '김동윤, 박홍규, 김한성, 김학수, 이근정, 이우정, 박영복, 윤  호, 윤두형, 유주현, 박재영, 서재성, 신동욱, 배진형, 이형준, 황호근, 박우람, 김홍균, 원종욱, 신도현, 김평기, 변경빈, 김용수, 양진호, 문현수, 김용근',
    total: '26',
  },
  {
    clubName: '천안일봉 RC',
    memberList: '김상용, 이원택',
    total: '2',
  },
  {
    clubName: '천안남산 RC',
    memberList: '김영진, 이현식, 심상현, 김인섭, 정지해',
    total: '5',
  },
  {
    clubName: '천안서북 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '천안아우내 RC',
    memberList: '홍점숙',
    total: '1',
  },
  {
    clubName: '천안무궁화 RC',
    memberList: '전서이, 김미정, 송미정',
    total: '3',
  },
  {
    clubName: '천안다온 RC',
    memberList: '김진주, 윤지희',
    total: '2',
  }
];

const data5 = [
  {
    clubName: '온양 RC',
    memberList: '안승훈, 배장한, 최명철, 장덕진, 지희승, 정연욱, 정해룡, 이태호, 이민우, 오세윤, 박순우, 이정욱, 윤찬희, 전만권',
    total: '14',
  },
  {
    clubName: '온양목련 RC',
    memberList: '윤하연, 안소연, 최이정, 한승진, 정정영, 정미나, 김은주, 이의숙, 이은열, 임수빈, 신연주, 뢰해연',
    total: '12',
  },
  {
    clubName: '아산둔포 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '아산배방 RC',
    memberList: '안용진, 이재영, 엄병수',
    total: '3',
  },
  {
    clubName: '아산온궁 RC',
    memberList: '김운호, 오기석, 조명희, 오현석, 최경용, 한상식, 김세영, 이강로, 차주성, 김영렬, 나영주, 김재수',
    total: '12',
  },
  {
    clubName: '아산성웅 RC',
    memberList: '최중한, 최동욱, 이호기, 이현석, 김형석, 김지성',
    total: '6',
  },
  {
    clubName: '온양온천 RC',
    memberList: '윤정호, 박용기, 엄성용, 황재하, 윤지영, 최순권, 황재호, 장정호, 정진철, 김환용',
    total: '10',
  },
];

const data6 = [
  {
    clubName: '새온양 RC',
    memberList: '김필수, 공지행, 하건우, 고명석',
    total: '4',
  },
  {
    clubName: '온아 RC',
    memberList: '이승준, 이종수, 서보민, 원재연, 윤창희, 이호석, 권혁규, 양성화, 김성락',
    total: '9',
  },
  {
    clubName: '아산 RC',
    memberList: '홍순복, 정선교, 김철규, 유승열, 심한주',
    total: '5',
  },
  {
    clubName: '온양중앙 RC',
    memberList: '박윤성',
    total: '1',
  },
  {
    clubName: '아산온정 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '아산현충 RC',
    memberList: '김선예, 김경욱',
    total: '2',
  },
  {
    clubName: '아산은하수 RC',
    memberList: '안윤정, 박숙희, 김미애',
    total: '3',
  },
  {
    clubName: '아산단미 RC',
    memberList: '황희화, 김선미, 정해영, 정민지, 김춘아, 오혜란, 박미숙, 박일룡, 권지선, 김종미, 이수연, 박은미, 최영애',
    total: '13',
  },
  {
    clubName: '아산온수 RC',
    memberList: '최세중, 정연우',
    total: '2',
  },
];

const data7 = [
  {
    clubName: '예산 RC',
    memberList: '지홍배, 김철호, 고진석, 박관영',
    total: '4',
  },
  {
    clubName: '신례원 RC',
    memberList: '이백규, 조찬석, 김희경, 이건배, 이재구, 이인숙, 임창완, 홍경숙',
    total: '8',
  },
  {
    clubName: '고덕 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '예산어울림 RC',
    memberList: '이영미, 김미라, 명지민, 장명희, 노수진, 김순애, 김정아, 이미형, 김나영, 백진화, 유기옥, 주현실, 이연우, 김미숙, 권회경, 이문자, 김경애, 김미배, 김해숙, 김정희, 장대순, 안정란, 차난호, 김영자, 장병희, 박차은, 명계남, 최기영, 동미숙, 김명자, 김현정, 김영신, 엄영미',
    total: '33',
  },
];

const data8 = [
  {
    clubName: '당진 RC',
    memberList: '고연수, 임희준, 김상정, 이경민',
    total: '4',
  },
  {
    clubName: '석문 RC',
    memberList: '배성준, 이남호, 박근표',
    total: '3',
  },
  {
    clubName: '합덕 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '당진당나루 RC',
    memberList: '김창훈',
    total: '1',
  },
  {
    clubName: '당진송악 RC',
    memberList: '장소망, 정인희, 고경원, 남연아, 곽송섭',
    total: '5',
  },
  {
    clubName: '당진신평 RC',
    memberList: '한만수, 박건수',
    total: '2',
  },
  {
    clubName: '당진고대 RC',
    memberList: '김종익, 김진수, 심상열, 송현우',
    total: '4',
  },
  {
    clubName: '당진해나루 RC',
    memberList: '한주연, 최윤미, 정연미',
    total: '3',
  },
  {
    clubName: '당진아미 RC',
    memberList: '권지선, 박일룡',
    total: '2',
  },
  {
    clubName: '당진골드 RC',
    memberList: '',
    total: '',
  },
];

const data9 = [
  {
    clubName: '광천 RC',
    memberList: '서종문, 윤명주, 추영준, 정광훈 이화영, 이경모, 박성진, 우민제, 배종호, 전상대',
    total: '10',
  },
  {
    clubName: '홍성 RC',
    memberList: '정민석, 김종원, 김량우, 이태영, 이병민',
    total: '5',
  },
  {
    clubName: '청양 RC',
    memberList: '김영춘, 한상국, 김문환, 이상택, 임종탁, 명재섭, 박창식, 양광영, 신동선, 조한균, 황정일, 김근영, 이진희, 복정진, 조윤희, 이상규',
    total: '16',
  },
  {
    clubName: '홍성홍서 RC',
    memberList: '박경미',
    total: '1',
  },
  {
    clubName: '홍성홍주 RC',
    memberList: '주현노, 김연태, 한문택, 김바다, 박백수, 강동원, 이원용, 이선우',
    total: '8',
  },
  {
    clubName: '청양칠갑산 RC',
    memberList: '최면빈, 이화영',
    total: '2',
  },
  {
    clubName: '홍성조양 RC',
    memberList: '조윤주, 정주희, 김  숙, 김보희, 이나윤, 임선희, 오미정, 박승희, 주희숙, 김영란, 김희라, 황연정',
    total: '12',
  },
  {
    clubName: '내포 RC',
    memberList: '장지윤, 정상수, 임  솔, 장혜림, 김  숙, 최미영,  박사회, 홍지은, 전인영, 이정연, 윤정남',
    total: '11',
  },
  {
    clubName: '광천오서산 RC',
    memberList: '손혜랑, 한명희, 정은미, 강영숙, 김종선, 김미경, 김현애, 고미선, 이수진, 이영숙',
    total: '10',
  },
  {
    clubName: '홍성용봉 RC',
    memberList: '최현준, 곽태영, 김성률, 김기천, 양승용, 엄익상, 장민상, 이권호',
    total: '8',
  },
  {
    clubName: '홍성운주 RC',
    memberList: '원정희',
    total: '1',
  },
];

const data10 = [
  {
    clubName: '대천 RC',
    memberList: '임우혁, 이성수, 이한규, 이병수, 박근진',
    total: '5',
  },
  {
    clubName: '대천중앙 RC',
    memberList: '조영진, 권순선, 유병호, 송관섭, 박정옥, 이보현, 이찬호',
    total: '7',
  },
  {
    clubName: '대천서부 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '뉴대천 RC',
    memberList: '고동균, 최영근, 고광일, 이상수, 서명원, 유근상',
    total: '6',
  },
  {
    clubName: '대천더함 RC',
    memberList: '한규리',
    total: '1',
  }
];

const data11 = [
  {
    clubName: '서산 RC',
    memberList: '황상준, 김선헌, 윤성일, 김정호, 송영하',
    total: '5',
  },
  {
    clubName: '서령 RC',
    memberList: '한상용, 최진엽, 최석락, 정광원, 이동수, 유원동, 문형일, 이강민, 탁건희',
    total: '9',
  },
  {
    clubName: '새서산 RC',
    memberList: '강승구, 이태호',
    total: '2',
  },
  {
    clubName: '충서 RC',
    memberList: '최준형',
    total: '1',
  },
  {
    clubName: '서산팔봉 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '서산한서 RC',
    memberList: '안성찬, 임창신, 김동주',
    total: '3',
  },
  {
    clubName: '서산보라매 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '서산서주 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '서산국화 RC',
    memberList: '변현옥, 고명옥, 강민경, 김선희, 김선아, 박소연, 엄미경, 김동심, 구도흘',
    total: '9',
  },
  {
    clubName: '서산송화 RC',
    memberList: '이승희, 이연수, 박혜은, 박경아',
    total: '4',
  },
  {
    clubName: '서산가온 RC',
    memberList: '',
    total: '',
  }
];

const data12 = [
  {
    clubName: '태안 RC',
    memberList: '전성신',
    total: '1',
  },
  {
    clubName: '안면도 RC',
    memberList: '박세진, 박병근',
    total: '2',
  },
  {
    clubName: '태안소성 RC',
    memberList: '',
    total: '',
  },
  {
    clubName: '태안백화산 RC',
    memberList: '도은종',
    total: '1',
  },
  {
    clubName: '태안해당화 RC',
    memberList: '이난순, 최미경',
    total: '2',
  },
  {
    clubName: '안면도송림 RC',
    memberList: '홍진숙',
    total: '1',
  },
  {
    clubName: '태안한결 RC',
    memberList: '안화숙, 조성화, 강석준, 임화영, 강종필, 김애경, 김민경, 김명희, 이상훈, 이혜지, 이창신, 박순화, 신경숙, 오지후',
    total: '13',
  },
];

const MemberTotal = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 천안1지역 ]</strong>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data1.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>천안1지역 신입회원 합계 : 38명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 천안2지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data2.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>천안2지역 신입회원 합계 : 38명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 천안3지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data3.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>천안3지역 신입회원 합계 : 67명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 천안4지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data4.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>천안4지역 신입회원 합계 : 48명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 아산1지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data5.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>아산1지역 신입회원 합계 : 57명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 아산2지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data6.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>아산2지역 신입회원 합계 : 39명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 예산지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data7.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>예산지역 신입회원 합계 : 45명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 당진지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data8.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>당진지역 신입회원 합계 : 30명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 홍성∙청양지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data9.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>홍성∙청양지역 신입회원 합계 : 84명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 보령지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data10.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>보령지역 신입회원 합계 : 19명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 서산지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data11.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>서산지역 신입회원 합계 : 29명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 태안지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="left" style={{ backgroundColor: '#f0f0f0' }}>명단</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>인원수</TableCell>
                </TableRow>
                {data12.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="left">{row.memberList}</TableCell>
                    <TableCell className={classes.cell} align="center">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <strong>태안지역 신입회원 합계 : 20명 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
          <br/><strong>[ 총합계 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} align="center" style={{ backgroundColor: '#f0f0f0' }}>총합계</TableCell>
                  <TableCell className={classes.cell} align="center">511</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <br/><br/>
    </div>
  );
};

export default MemberTotal;