import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 광천 로타리클럽 ]",
    title2: "클럽사무실 청소",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F0SXfQ%2FbtsHR1XNasP%2FbqFJz15jcvr80SlhvuaCaK%2Fimg.jpg',
    description: '&nbsp;5월 26일(일) 오전 10시, 광천∙광천오서산 로타리클럽이 같이 사용하는 클럽사무실 청소를 실시했다.',
  },
  {
    title1: "[ 내포 로타리클럽 ]",
    title2: "RI가입 11주년 기념 줍깅",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbWQSlJ%2FbtsHR7i81HZ%2FeIQwdpKKmx2StfI3p1MK7K%2Fimg.png',
    description: '&nbsp;5월 9일 오전 7시30분, 홍성읍 내법교차로 주변에서 환경정화 활동을 실시했다.',
  },
  {
    title1: "[ 광천오서산 로타리클럽 ]",
    title2: "편백 조현숙 회장 고별주회",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcWryMA%2FbtsHS0wGGWk%2FExLRwez3Jitz5BfffkJpbK%2Fimg.png',
    description: '&nbsp;5월 12일, 정기모임 및 2023-24년도 편백 조현숙 회장 고별주회를 진행했다.',
  },
  {
    title1: "[ 광천오서산 로타리클럽 ]",
    title2: "찔레꽃 가꾸기 사업",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbw7z4F%2FbtsHTkO6dYV%2FhV20PzloCO2aaZsvE2cUm0%2Fimg.jpg',
    description: '&nbsp;5월 29일(수) 오전 9시, 광천읍 삼봉마을 찔레꽃 거리에서 지구보조금 사업의 일환으로 찔레꽃 가꾸기 사업을 실시했다.',
  },
];

const ClubHc = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" className={classes.title} component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubHc;
