import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  { clubName: '천안 RC', phf: '361,741', rfsm: '14,604', bnf: '1,000', limit: '5,588', total: '382,933' },
  { clubName: '천안삼거리 RC', phf: '615,238', rfsm: '40,300', bnf: '7,000', limit: '828', total: '663,366' },
  { clubName: '천안파로스 RC', phf: '237,903', rfsm: '9,339', bnf: '', limit: '3,536', total: '250,778' },
  { clubName: '천안드림 RC', phf: '86,536', rfsm: '300', bnf: '', limit: '', total: '86,836' },
  { clubName: '천안충남 RC', phf: '116,432', rfsm: '7,600', bnf: '3,000', limit: '574', total: '127,606' },
];

const data2 = [
  { clubName: '성환 RC', phf: '132,001', rfsm: '700', bnf: '', limit: '7', total: '132,708' },
  { clubName: '서천안 RC', phf: '532,212', rfsm: '75,699', bnf: '', limit: '8,576', total: '616,487' },
  { clubName: '남천안 RC', phf: '223,360', rfsm: '6,000', bnf: '', limit: '101', total: '229,461' },
  { clubName: '천안하늘 RC', phf: '107,410', rfsm: '9,401', bnf: '', limit: '132', total: '116,943' },
  { clubName: '천안천룡 RC', phf: '64,512', rfsm: '20,300', bnf: '', limit: '740', total: '85,552' },
  { clubName: '천안백두 RC', phf: '61,919', rfsm: '5,600', bnf: '', limit: '7', total: '67,526' },
  { clubName: '천안쌍봉 RC', phf: '149,416', rfsm: '16,200', bnf: '', limit: '203', total: '165,819' },
  { clubName: '천안새충무 RC', phf: '12,379', rfsm: '9,800', bnf: '', limit: '276', total: '22,455' },
];

const data3 = [
  { clubName: '새천안 RC', phf: '701,741', rfsm: '4,700', bnf: '', limit: '336', total: '706,777' },
  { clubName: '천안능수 RC', phf: '232,926', rfsm: '16,900', bnf: '1,000', limit: '1,699', total: '252,525' },
  { clubName: '천안도솔 RC', phf: '1,108,550', rfsm: '14,173', bnf: '32,000', limit: '15,594', total: '1,170,317' },
  { clubName: '천안동남 RC', phf: '278,220', rfsm: '34,000', bnf: '', limit: '1,352', total: '313,572' },
  { clubName: '천안상록 RC', phf: '138,412', rfsm: '18,700', bnf: '', limit: '7,321', total: '164,433' },
  { clubName: '천안천지 RC', phf: '68,272', rfsm: '5,800', bnf: '', limit: '497', total: '74,569' },
  { clubName: '천안희망 RC', phf: '65,428', rfsm: '11,500', bnf: '', limit: '1,255', total: '78,183' },
  { clubName: '천안리더스 RC', phf: '61,983', rfsm: '', bnf: '1,200', limit: '', total: '63,183' },
  { clubName: '천안동호 RC', phf: '207,539', rfsm: '28,000', bnf: '', limit: '12,740', total: '248,279' },
  { clubName: '천안버들 RC', phf: '25,301', rfsm: '4,600', bnf: '', limit: '', total: '29,901' },
];

const data4 = [
  { clubName: '천안중앙 RC', phf: '222,866', rfsm: '4,900', bnf: '', limit: '156', total: '227,922' },
  { clubName: '천안목주 RC', phf: '209,505', rfsm: '57,600', bnf: '', limit: '3,559', total: '270,664' },
  { clubName: '천안일봉 RC', phf: '164,904', rfsm: '17,321', bnf: '', limit: '451', total: '182,676' },
  { clubName: '천안남산 RC', phf: '55,016', rfsm: '11,200', bnf: '', limit: '1,025', total: '67,241' },
  { clubName: '천안서북 RC', phf: '51,783', rfsm: '', bnf: '', limit: '320', total: '52,103' },
  { clubName: '천안아우내 RC', phf: '53,683', rfsm: '4,400', bnf: '', limit: '', total: '58,083' },
  { clubName: '천안무궁화 RC', phf: '44,513', rfsm: '900', bnf: '', limit: '202', total: '45,615' },
  { clubName: '천안다온 RC', phf: '', rfsm: '17,650', bnf: '', limit: '131', total: '17,781' },
  { clubName: '천안다온 로타랙트', phf: '', rfsm: '2,100', bnf: '', limit: '', total: '2,100' },
];

const data5 = [
  { clubName: '온양 RC', phf: '486,796', rfsm: '30,400', bnf: '', limit: '1,383', total: '518,579' },
  { clubName: '온양목련 RC', phf: '228,748', rfsm: '20,600', bnf: '', limit: '1,011', total: '250,359' },
  { clubName: '아산둔포 RC', phf: '185,102', rfsm: '2,000', bnf: '', limit: '', total: '187,102' },
  { clubName: '아산배방 RC', phf: '61,705', rfsm: '12,600', bnf: '', limit: '384', total: '74,689' },
  { clubName: '아산온궁 RC', phf: '250,246', rfsm: '15,700', bnf: '', limit: '274', total: '266,220' },
  { clubName: '아산성웅 RC', phf: '62,212', rfsm: '6,700', bnf: '', limit: '545', total: '69,457' },
  { clubName: '온양온천 RC', phf: '118,468', rfsm: '6,500', bnf: '', limit: '841', total: '125,809' },
];

const data6 = [
  { clubName: '새온양 RC', phf: '400,601', rfsm: '25,200', bnf: '', limit: '1,123', total: '426,924' },
  { clubName: '온아 RC', phf: '1,273,121', rfsm: '53,412', bnf: '', limit: '8,493', total: '1,335,026' },
  { clubName: '아산 RC', phf: '215,880', rfsm: '16,800', bnf: '', limit: '1,330', total: '234,010' },
  { clubName: '온양중앙 RC', phf: '272,720', rfsm: '11,700', bnf: '', limit: '474', total: '284,894' },
  { clubName: '아산온정 RC', phf: '20,856', rfsm: '1,000', bnf: '', limit: '', total: '21,856' },
  { clubName: '아산현충 RC', phf: '9,860', rfsm: '3,199', bnf: '', limit: '270', total: '13,329' },
  { clubName: '아산단미 RC', phf: '22,229', rfsm: '9,100', bnf: '', limit: '568', total: '31,897' },
  { clubName: '아산온수 RC', phf: '8,553', rfsm: '100', bnf: '', limit: '', total: '8,653' },
];

const data7 = [
  { clubName: '예산 RC', phf: '195,551', rfsm: '17,200', bnf: '2,000', limit: '449', total: '215,200' },
  { clubName: '신례원 RC', phf: '38,190', rfsm: '3,000', bnf: '', limit: '', total: '41,190' },
  { clubName: '고덕 RC', phf: '78,446', rfsm: '3,000', bnf: '', limit: '23', total: '81,469' },
  { clubName: '예산어울림 RC', phf: '', rfsm: '2,900', bnf: '', limit: '', total: '2,900' },
];

const data8 = [
  { clubName: '당진 RC', phf: '308,892', rfsm: '13,004', bnf: '1,000', limit: '2,752', total: '325,648' },
  { clubName: '석문 RC', phf: '362,898', rfsm: '7,774', bnf: '15,000', limit: '101', total: '385,773' },
  { clubName: '합덕 RC', phf: '74,251', rfsm: '', bnf: '1,000', limit: '', total: '75,251' },
  { clubName: '당진당나루 RC', phf: '239,431', rfsm: '5,008', bnf: '', limit: '94', total: '244,533' },
  { clubName: '당진송악 RC', phf: '140,473', rfsm: '17,656', bnf: '', limit: '3,477', total: '161,606' },
  { clubName: '당진신평 RC', phf: '128,558', rfsm: '6,900', bnf: '', limit: '23', total: '135,481' },
  { clubName: '당진고대 RC', phf: '46,666', rfsm: '6,700', bnf: '', limit: '307', total: '53,673' },
  { clubName: '당진해나루 RC', phf: '95,113', rfsm: '16,500', bnf: '', limit: '2,439', total: '114,052' },
  { clubName: '당진아미 RC', phf: '40,476', rfsm: '5,000', bnf: '', limit: '1,345', total: '46,821' },
  { clubName: '당진골드 RC', phf: '6,000', rfsm: '', bnf: '', limit: '37', total: '6,037' },
];

const data9 = [
  { clubName: '광천 RC', phf: '283,013', rfsm: '13,400', bnf: '1,000', limit: '2,665', total: '300,078' },
  { clubName: '홍성 RC', phf: '538,584', rfsm: '24,000', bnf: '1,000', limit: '1,227', total: '564,811' },
  { clubName: '청양 RC', phf: '105,586', rfsm: '4,650', bnf: '', limit: '575', total: '110,811' },
  { clubName: '홍성홍서 RC', phf: '96,892', rfsm: '500', bnf: '', limit: '1,710', total: '99,102' },
  { clubName: '홍성홍주 RC', phf: '291,027', rfsm: '12,145', bnf: '1,000', limit: '1,872', total: '306,044' },
  { clubName: '청양칠갑산 RC', phf: '53,037', rfsm: '3,400', bnf: '', limit: '1,457', total: '57,894' },
  { clubName: '홍성조양 RC', phf: '158,329', rfsm: '29,500', bnf: '', limit: '2,111', total: '189,940' },
  { clubName: '내포 RC', phf: '71,025', rfsm: '6,640', bnf: '', limit: '1,815', total: '79,480' },
  { clubName: '광천오서산 RC', phf: '85,750', rfsm: '25,100', bnf: '', limit: '2,538', total: '113,388' },
  { clubName: '홍성용봉 RC', phf: '28,764', rfsm: '7,700', bnf: '', limit: '1,210', total: '37,674' },
  { clubName: '홍성운주 RC', phf: '33,582', rfsm: '7,500', bnf: '', limit: '2,116', total: '43,198' },
];

const data10 = [
  { clubName: '대천 RC', phf: '317,511', rfsm: '12,519', bnf: '', limit: '196', total: '330,226' },
  { clubName: '대천중앙 RC', phf: '481,039', rfsm: '50,800', bnf: '15,000', limit: '5,087', total: '551,926' },
  { clubName: '대천서부 RC', phf: '54,686', rfsm: '100', bnf: '', limit: '', total: '54,786' },
  { clubName: '뉴대천 RC', phf: '233,861', rfsm: '62,300', bnf: '', limit: '6,638', total: '302,799' },
  { clubName: '대천더함 RC', phf: '', rfsm: '2,400', bnf: '', limit: '1,263', total: '3,663' },
];

const data11 = [
  { clubName: '서산 RC', phf: '821,651', rfsm: '29,046', bnf: '12,000', limit: '12,922', total: '875,619' },
  { clubName: '서령 RC', phf: '60,922', rfsm: '5,170', bnf: '', limit: '124', total: '66,216' },
  { clubName: '새서산 RC', phf: '59,200', rfsm: '3,000', bnf: '', limit: '889', total: '63,089' },
  { clubName: '충서 RC', phf: '160,403', rfsm: '12,800', bnf: '', limit: '94', total: '173,297' },
  { clubName: '서산한서 RC', phf: '135,953', rfsm: '4,575', bnf: '', limit: '', total: '140,528' },
  { clubName: '서산보라매 RC', phf: '173,712', rfsm: '28,200', bnf: '', limit: '94', total: '202,006' },
  { clubName: '서산국화 RC', phf: '177,081', rfsm: '13,300', bnf: '', limit: '6,711', total: '197,092' },
  { clubName: '서산가온 RC', phf: '', rfsm: '2,500', bnf: '', limit: '100', total: '2,600' },
];

const data12 = [
  { clubName: '태안 RC', phf: '147,785', rfsm: '9,533', bnf: '', limit: '77', total: '157,395' },
  { clubName: '안면도 RC', phf: '100,254', rfsm: '17,000', bnf: '', limit: '101', total: '117,355' },
  { clubName: '태안소성 RC', phf: '259,902', rfsm: '16,000', bnf: '1,700', limit: '394', total: '277,996' },
  { clubName: '태안백화산 RC', phf: '108,385', rfsm: '7,600', bnf: '', limit: '94', total: '116,079' },
  { clubName: '태안해당화 RC', phf: '38,019', rfsm: '700', bnf: '', limit: '482', total: '39,201' },
  { clubName: '안면도송림 RC', phf: '22,343', rfsm: '4,500', bnf: '', limit: '199', total: '27,042' },
  { clubName: '태안한결 RC', phf: '65,177', rfsm: '13,796', bnf: '', limit: '1,652', total: '80,625' }
];

const data13 = [
  {
    clubName: '총합',
    phf: '16,927,216',
    rfsm: '1,220,314',
    bnf: '93,700',
    limit: '153,562',
    total: '18,389,292',
  },
];

const ClubTotalB = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안1지역 ]</strong>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data1.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안2지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data2.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안3지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data3.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안4지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data4.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산1지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data5.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산2지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data6.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 예산지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data7.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 당진지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data8.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 홍성∙청양지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data9.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 보령지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data10.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 서산지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data11.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 태안지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>누적기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data12.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 총합계액 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:$)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}></TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>연차기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>영구기금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>제한기금</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#d8d8d8', fontWeight: 'bold' }}>재단 총합계</TableCell>
                </TableRow>
                <TableRow>

                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>기부금</TableCell>
                </TableRow>
                {data13.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <br/><br/>
    </div>
  );
};

export default ClubTotalB;