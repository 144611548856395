import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: '20px',
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  smallLogo: {
    width: '24px', // Adjust the size as needed
    height: 'auto',
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  sectionContent: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
