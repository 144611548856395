import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiF = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbz4FcZ%2FbtsH2sfTcoH%2FiaCcXNxEfCGnabnsNkd3Q0%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원장</span>{'\n'}
          성지 문상식
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FQl2X1%2FbtsIcWuBJaR%2F7XO9kwnGZoj6UHOP3yeY20%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;한국로타리와 지구 최초의 웹진 총재월신을 만드느라 고민많으셨던 편집부위원장과 편집위원들께 진심으로 고생하셨다는 말씀 전합니다.{'\n'} 사실은 저도 핸드폰으로 책을 본다는 것이 익숙치 않다보니 불편했습니다만, 앞서가는 지구로서 선구자 역할을 하려면 감수할 수 밖에 없는 일이었기에 조금씩 적응해 왔습니다.{'\n'} 다행히 우리의 노고가 헛되지 않아 차기의 총재월신 제작방향에 좋은 영향을 주고, 핸드폰안에 영원히 남게 된 점은 기쁜 일입니다.{'\n'} 23-24 회기동안 애쓰신 회원 여러분의 행복과 건강을 기원하며, 차기에도 총재월신에 많은 관심과 응원을 부탁드립니다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 부위원장</span>{'\n'}
          한결 황혜경
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FlAyGq%2FbtsH3oc52Fn%2FRUZwJ92HQyEZp5MZVDgbn1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;"모바일 총재월신요?"{'\n'}
          &nbsp;총재월신도 시대흐름에 맞는 변화가 필요하니 모바일 버전으로 만들어 보자는 제안에 대환영하면서도 두려움이 앞섰습니다. '로타리코리아' 잡지도 PDF(그림이미지)형태인데 살아움직이는 실시간 플랫폼 형태라니~~{'\n'}
          &nbsp;한국로타리 최초의 작업인데다 제작사가 로타리를 잘 모르기에 많은 공부와 소통이 필요했습니다.
          {'\n'}{'\n'}
          &nbsp;누구도 가보지 않은 길.{'\n'}
          낯선 용어와 디자인 구도, 이해하기 어려운 대화에 베테랑 편집위원들도 갸우뚱했지만 그간의 노련함으로 대응하며, 어느정도 틀이 만들어지나 했는데 어느덧 마지막 호를 맞았습니다. 한 해 동안 애쓰신 편집위원장님과 편집위원, 지구기자 및 지역기자께 깊은 감사를 드립니다.
          {'\n'}{'\n'}
          &nbsp;지역을 돌때마다 새로운 총재월신의 취지와 활용법을 설명하는데 많은 시간을 할애했음에도 아직까지 전 회원이 공유하지 못한 것이 아쉽기는 하지만 2023-24년도의 역사가 "내 손안"에서 언제 어디서든 볼 수 있다는 큰 결실이 남았습니다. [로타리 연수영상]과 [로타리 의전음원]도 더불어 언제든 다운받아 활용하기 바랍니다.
          {'\n'}{'\n'}
          &nbsp;세상에 희망을 주신 로타리안 여러분.{'\n'}올 회기 정말 애쓰셨습니다! 앞으로도 핸드폰 안 '행복한 총재월신'에서 언제든 뵙기를 바라며~~ {'\n'}"세상에 희망을! 행복한 총재월신!"
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원</span>{'\n'}
          평원 선우석
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F5wQ3m%2FbtsH3zFDf5c%2Fo7VEFXSmJxYFdDb3u7IFQk%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;2023-24년도 새로운 도전의 시작...{'\n'}총재월신에 대한 기대와 걱정이 교차했습니다. 처음 접해보는 웹을 통한 월신의 발간은 신선했습니다. 시행착오와 우여곡절을 격으며 벌써 한 회기의 마지막 호를 장식하네요. 지면의 한계를 벗어나 자유로이 내용을 원하는대로 게재할 수 있고, 어디서든 스마트폰만 있으면 월신을 열어볼 수 있다는 장점이 있었습니다. 반면, 소식을 책자로 인쇄할 수 없다는 불편함은 좀 아쉬움으로 남네요. 
          {'\n'}{'\n'}
          &nbsp;편집위원들은 한 회기동안 12개지역을 방문하여 편집회의를 하고 수차례 교정을 보느라 모두 고생하셨습니다. 모두 봉사하는 마음으로 활동했기에 가능했으리라 생각하며 여러분들이 있기에 3620지구가 빛나는거 같습니다. 감사합니다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원</span>{'\n'}
          금암 전용우
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcmJNmP%2FbtsH18ibAzt%2F1BDKwsayAbKzZ8swWHL2k1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;'과연 갑작스럽고 획기적인 변화가 괜찮을까?' 라는 우려와 기대 속에서 시작된 2023-24년도 웹진 총재월신. 지난 7월부터 3620지구에서는 처음으로 모바일 환경에서 제작하고 배포되는 총재월신 발행을 시작했습니다. 처음 시도하는 것이라 많은 혼란과 시행착오가 있었지만, 회가 거듭될수록 조금씩 나아지는 것을 느꼈습니다.
          {'\n'}{'\n'}
          &nbsp;변화하지 않으면 진보하기 어렵다고 생각합니다. 그런 면에서 금회기의 새로운 시도는 상당한 긍정적인 의미가 있다고 생각합니다. 
          {'\n'}{'\n'}
          &nbsp;모바일 총재월신 발간에 대해서 각 클럽의 원로회원님들이나 로타리활동에 많은 관심을 가지셨던 분들 중 일부, 아니 상당 수가 불만과 개선의 목소리를 내 주신 것에 대해서도 이해를 합니다. 하지만 한정된 예산 안에서 또 다른 플랫폼을 추가하여 진행하는 것이 무리가 있었습니다. 사전에 좀더 구체적이고 상세한 논의가 있었으면 더 좋았을 것 같은 아쉬움이 남습니다. 
          {'\n'}{'\n'}
          &nbsp;앞으로 급변하는 시대에 맞춰 우리 또한 그에 맞춰 변화해야하는 방향에 금회기의 경험이 밑거름이 되어 좀더 훌륭한 3620지구의 총재월신이 역사에 길이 남기를 희망해 봅니다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원</span>{'\n'}
          여정 박현옥
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbnve0p%2FbtsH2BRX7pP%2FRQyhnTQAbey92yIPBxUnD1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;2023-24년도 총재월신을 만드는 1년 동안의 시간은 저에게 정말 의미있는 시간이였습니다. 봉사를 실천한 내용 하나하나가 모이고, 행사 사진 한장 한장이 모여 매달 소식을 전하기까지 많은 분들의 열정과 봉사의 마음이 있었기에 잘 마무리 할 수 있었습니다.
          {'\n'}{'\n'}
          &nbsp;기존의 편집에서 새로운 방식의 총재월신을 이끌어 가시느라 고생하신 성지 문상식 총재월신편집위원회 위원장님, 한결 황혜경 부위원장님, 편집위원님외 정말 고생하신 조용진 편집위원장님께 박수를 보냅니다. 앞으로 더 멋진 총재월신이 거듭나길 기원합니다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원</span>{'\n'}
          대충산꾼 손봉환
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FlfpCJ%2FbtsH2rIB6Rb%2FiXGwovJGShhMumkgyORKz0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;총재월신이라는 공간에서 무엇을 해야 하는지 고민이 많았던 편집기간이었습니다. 또한 총재월신 웹진이 제대로 나올까 또한 웹진이 무엇이라는 물음에 대한 답을 얻기에는 고민도 의지도 시간도 부족하게만 느껴집니다.{'\n'} 총재월신  편집위 선배와 활성화를 위해 노력해주신 문상식 총재월신 편집위원장님 감사드립니다. 편집위 식구들 사랑합니다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원</span>{'\n'}
          하정 김태권
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FZQBbT%2FbtsH3PBwgX9%2Fslnp0YOAHVIL8FkKVB5K21%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;새롭게 도전하는 총재월신 보령지역편을 시작으로 서산지역편까지 큰 변화가 있었습니다. 웹으로 처음 접해보는 총재월신 처음엔 어떻게 만들어가야 할까 걱정 반 근심반으로 만들었습니다. 변화하는 과정이 조금은 힘들고 어색했던 것 같습니다. 회원들이 쉽게 접할 수있게  많이 홍보가 되었으면 합니다. 
          {'\n'}{'\n'}
          &nbsp;매월 수고하신 성지 문상식 편집위원님, 한결 황혜경 부위원장님 그리고 편집위원님 수고하셨습니다. 감사드립니다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>총재월신편집위원회 위원</span>{'\n'}
          조용진
          {'\n'}{'\n'}
        </Typography>
        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F66J0v%2FbtsH01Kpx36%2F6FOmMKl1qlnlkpjF1rung1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;3620지구 로타리클럽이 사회에 전하는 올바른 영향력을 간접적으로 느낄 수 있었습니다. 이러한 로타리클럽의 봉사활동을 많은 사람들에게 알릴 수 있는 총재월신 편집작업에 참여하여 영광이었습니다.
          {'\n'}{'\n'}
        </Typography>

      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiF;