import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAt6HxWwyF6vPILl4-5GdP-BVXPJ-YFdB8",
  authDomain: "rotary2406.firebaseapp.com",
  projectId: "rotary2406",
  storageBucket: "rotary2406.appspot.com",
  messagingSenderId: "394561094801",
  appId: "1:394561094801:web:4c81a4c3c3a6e95eebeb30",
  measurementId: "G-B3PB6Q0QL6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export { app, auth, db, functions, analytics };
// export { app, auth, db };