import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  {
    clubName: '천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안삼거리 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안파로스 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안드림 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안충남 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data2 = [
  {
    clubName: '성환 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '서천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '남천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안하늘 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안천룡 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안백두 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안쌍봉 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안새충무 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data3 = [
  {
    clubName: '새천안 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안능수 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안도솔 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안동남 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안상록 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안천지 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안희망 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안리더스 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안동호 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안버들 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data4 = [
  {
    clubName: '천안중앙 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안목주 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안일봉 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안남산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안서북 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안아우내 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안무궁화 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안다온 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '천안다온 로타랙트',
    a: '',
    b: '',
    c: '●',
    d: '●',
    e: '',
  },
];

const data5 = [
  {
    clubName: '온양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '온양목련 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산둔포 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산배방 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산온궁 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산성웅 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '온양온천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data6 = [
  {
    clubName: '새온양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '온아 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '온양중앙 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산온정 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산현충 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산단미 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '아산온수 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data7 = [
  {
    clubName: '예산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '신례원 RC',
    a: '',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '고덕 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '예산어울림 RC',
    a: '',
    b: '',
    c: '●',
    d: '●',
    e: '',
  },
];

const data8 = [
  {
    clubName: '당진 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '석문 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '합덕 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진당나루 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진송악 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진신평 RC',
    a: '',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진고대 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진해나루 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진아미 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '당진골드 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data9 = [
  {
    clubName: '광천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '홍성 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '청양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '홍성홍서 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '홍성홍주 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '청양칠갑산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '홍성조양 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '내포 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '광천오서산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '홍성용봉 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '홍성운주 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data10 = [
  {
    clubName: '대천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '대천중앙 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '대천서부 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '뉴대천 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '대천더함 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data11 = [
  {
    clubName: '서산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '서령 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '새서산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '충서 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '서산한서 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '서산보라매 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '서산국화 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '서산가온 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const data12 = [
  {
    clubName: '태안 RC',
    a: '2/1',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '안면도 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '태안소성 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '태안백화산 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '태안해당화 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '안면도송림 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
  {
    clubName: '태안한결 RC',
    a: '●',
    b: '●',
    c: '●',
    d: '●',
    e: '●',
  },
];

const ClubTotalE = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안1지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data1.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안2지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data2.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안3지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data3.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안4지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data4.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산1지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data5.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산2지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data6.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 예산지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data7.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 당진지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data8.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 홍성∙청양지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data9.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 보령지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data10.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 서산지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data11.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 태안지역 ]</strong><br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                <TableRow>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>지구대회비</TableCell>
                  <TableCell className={classes.cell} colSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>국제회비</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>로타리코리아<br/>구독료</TableCell>
                  <TableCell className={`${classes.cell} ${classes.mergedCell}`} rowSpan={2} align="center" style={{ backgroundColor: '#f0f0f0' }}>청소년위원회비<br/>로타리회관유지비</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>상</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>하</TableCell>
                </TableRow>
                {data12.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.a}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.b}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.c}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.d}</TableCell>
                    <TableCell className={classes.cell} align="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>{row.e}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <br/><br/>
    </div>
  );
};

export default ClubTotalE;