import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const QRCodePage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [qrValue, setQrValue] = useState('');

  useEffect(() => {
    const generateQRCode = async () => {
      if (user) {
        // Firestore에서 사용자 정보 가져오기
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          // Firestore 문서에서 사용자 정보 가져오기
          const userData = docSnap.data();
          const attendanceData = {
            userId: user.uid,
            email: user.email, // Auth에서 제공하는 이메일
            name: userData.name, // Firestore 문서에서 가져온 사용자 이름
            club: userData.club, // Firestore 문서에서 가져온 클럽 정보
            phoneNumber: userData.phoneNumber, // Firestore 문서에서 가져온 전화번호
            timestamp: new Date().toISOString(), // 현재 시각
          };

          // QR 코드 생성
          const qrDataURL = await QRCode.toDataURL(JSON.stringify(attendanceData));
          setQrValue(qrDataURL);
        } else {
          console.log("No such document!");
        }
      }
    };

    generateQRCode();
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {qrValue && (
        <>
          <img src={qrValue} alt="QR Code" style={{ maxWidth: '300px', marginBottom: '20px' }} />
          <div style={{ marginBottom: '20px' }}>QR 코드를 스캔하여 출석하세요.</div>
        </>
      )}
    </div>
  );
};

export default QRCodePage;
