import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/areaContents/AreaStyle.js';
import ComplexBtn from '../main/ComplexBtn';

const AreaA = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={`${classes.content} ${classes.preserveNewline}`}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>서산지역대표 인터뷰</span>{'\n'}
          해냄 장광순{'\n'}{'\n'}
        </Typography>

        <CardMedia
          className={classes.media1}
          image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbAEZoh%2FbtsH1kbONlH%2FZGXKFWIHayRhQWC7inowp1%2Fimg.png'
          title="main3"
        />{'\n'}

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>프로필</span>{'\n'}{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          소속클럽 : 서산 로타리클럽{'\n'}
          직업 : 세무사{'\n'}
          재단 : LEVEL3 $50,578{'\n'}
        </Typography>
 
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>로타리와의 인연</span>{'\n'}{'\n'}
        </Typography>
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;개인사정으로 많이 우울해 있을 때 친구가 저녁을 먹자고 전화가 왔다. 고마운 마음에 나간 자리에서 봉고차를 탔는데 어르신들 5분이 벌써 차에 타고 계셨다. 그렇게 도착한 곳이 서산 로타리클럽 사무실이었다.
          {'\n'}{'\n'}
          &nbsp;국민의례도 하고 로타리송 노래도 부르는데 그냥 어느 단체의 모임이라고만 생각했다. 회원으로 입회도 하기 전부터 주회와 이취임식에 첨석 밥 먹으러 다니고 3달 후, 입회 선서를 하고 로타리 뱃지를 받았다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media1}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbqLJxa%2FbtsH1LAeomr%2Fbu6XBWzQZVDYJ1xUkwCVxk%2Fimg.png'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media1}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcVDKoc%2FbtsH1Pbwfs0%2FVpkHjeEu8Qc6qZdAmoolY0%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;2013-14년 서산로타리클럽 총무를 하게 되었고 로타리에 대해서 아무것도 모르는데 회장님께서 다 해주신 덕분에 1년을 지냈다. 총무 임기 마지막쯤 말레이시아 쿠칭(보로네오 섬) 로타리클럽과 국제봉사에 참여했다.
          {'\n'}{'\n'}
          &nbsp;국제봉사 이틀째, 팜농장에서 일하는 원주민 가족들이 사는 롱하우스(Long house)에 설치한 태양광발전과 식수 정수기 설치 완공식장에서 한 가족이 제 손을 꼭 잡으며 고맙다는 말을 전했다.
          {'\n'}{'\n'}
          &nbsp;그 순간 가슴이 뛰고 뭉클함이 느껴졌다. 저는 한 동안 조금 힘들다고 의욕 없이 지냈던 게 부끄러워지면서, 다시 한국에 가면 직업적으로 열심히 일해서 조금이나마 남들에게 도움이 되는 삶을 살아야겠다는 다짐했다. 
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>나에게 봉사란</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media1}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcvRCDW%2FbtsH1LmGxnI%2FP3g3ejw9rwSKeKDN1ScTKk%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;봉사는 나의 만족이다. 좋은 분들과 함께 참여하고 부딪히면서 함께 하는 시간이 행복이라 생각한다.
          {'\n'}{'\n'}
          &nbsp;말레이시아에서 따듯한 음식으로 반겨주던 원주민 가족, 캄보디아 시골학교에서 손잡던 눈이 초롱초롱하던 아이들, 우즈베키스탄 고려인 양로원에서 제 손을 꼭 잡아주시고 한복을 선물했을 때 눈시울이 붉어졌던 고려인 2세 할머니들이 지금도 눈에 선하다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}<span style={{ color: '#194C86', fontWeight: 'bold' }}>앞으로의 다짐</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbojaSM%2FbtsH2IQjaYY%2FY7aO2V4KFdRMApmzUCYBkk%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;이제 서산로타리클럽의 평회원으로서 나의 직업적 가치를 높이고 더 적극적으로 참여하며 스스로가 움직이는 로타리안이 되겠다.
          {'\n'}{'\n'}
          &nbsp;인터뷰를 하면서 지난 10여년의 로타리 생활을 되돌아 볼 수 있는 기회였던 것 같다. 꼭 기회와 시간이 되시면 해외 봉사에 참여해 보시길 부탁드린다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 서산지역기자 이수섭{'\n'}&nbsp;(서산 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default AreaA;
