import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  // {
  //   title1: "[ 보령지역 ]",
  //   title2: "보령지역 합동 환경정화 활동",
  //   imageUrl1: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbXk6EI%2FbtsHm1bXvBa%2FUQI7Wf0OKxkUkT3M1Ne3Tk%2Fimg.jpg',
  //   imageUrl2: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcjiuNn%2FbtsHpN49LV5%2FqqNj8kWiEkXTHrZe0WBWV0%2Fimg.jpg',
  //   description: '&nbsp;4월 11일 17시 30분, 보령시 번화가(동대동 일대) 보령지역 합동 환경정화 활동을 실시했다. 대천 RC, 대천중앙 RC, 뉴대천 RC, 대천더함 RC의 회장∙총무와 50여 명의 회원들이 참석했다. 대야 강한구 지역대표의 지휘하에 환경정화 활동을 잘마무리 할 수 있었다.',
  // },
];

const ClubBl = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl2} title={item.title} />
          <CardMedia className={classes.media} image={item.imageUrl1} title={item.title} style={{ marginTop: '20px' }} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubBl;
