import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  // {
  //   title1: "[ 예산어울림 로타리클럽 ]",
  //   title2: "예산어울림 로타리클럽 창립 및<br/>회장∙임원 취임식",
  //   imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdnlmJS%2FbtsHnkPUopi%2FyhS3YepsTjUJImga1H7vB1%2Fimg.jpg',
  //   description: '&nbsp;4월 20일 더스타 웨딩홀에서 예산어울림 로타리클럽 창립 및 회장∙임원 취임식이 진행되었다.',
  // },
];

const ClubEs = () => {
  const classes = useStyles(); // Initialize the styles

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubEs;
