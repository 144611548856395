import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    height: 'auto',
  },
  cart: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
  },
  centerText: {
    textAlign: 'center', // Center-align text
  },
  imageContainer: {
    // position: 'absolute',
    top: 20,
    left: 25,
    width: '120px', // Half of the card's width
    height: '150px',
    display: 'flex', // Add flex display
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
  },
  image: {
    width: '120px', // Adjusted to 80% of the image container
    height: '150px', // Maintain aspect ratio
    display: 'block',
    margin: '0', // Center horizontally within the container
  },
  // image: {
  //   maxWidth: '40%', // Set the maximum width to maintain aspect ratio
  //   height: '160px', // Automatically adjust the height
  //   marginRight: theme.spacing(2),
  //   alignSelf: 'flex-start', // Align the image to the top
  // },
  textContainer: {
    // position: 'absolute',
    top: 0,
    right: -15,
    width: '60%', // Half of the card's width
    height: '100%',
    padding: theme.spacing(2),
  },
}));

export default useStyles;