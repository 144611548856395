import React from 'react';
import { CustomButtonStyled } from '../../styles/auth/SharedStyles';

const CustomButton = ({ children, type, ...otherProps }) => {
  return (
    <CustomButtonStyled type={type} {...otherProps}>
      {children}
    </CustomButtonStyled>
  );
};

export default CustomButton;

