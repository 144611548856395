import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';

import 'moment/locale/ko';
moment.locale('ko');

const localizer = momentLocalizer(moment);

const CalendarPage = props => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const events = [
  {
    start: new Date('2024-06-01'),
    end: new Date('2024-06-01'),
    title: '서산한서 이.취임식(서산지역) - 서산시 인지면 애정길 101 태림산업'
  },
  {
    start: new Date('2024-06-01'),
    end: new Date('2024-06-01'),
    title: '서산가온 이.취임식(서산지역) - 동문2동 행정복지센터'
  },
  {
    start: new Date('2024-06-04'),
    end: new Date('2024-06-04'),
    title: '온양목련 이.취임식 - 아산터미널웨딩홀'
  },
  {
    start: new Date('2024-06-05'),
    end: new Date('2024-06-05'),
    title: '천안목주 이.취임식(천안4지역) - 이담원'
  },
  {
    start: new Date('2024-06-05'),
    end: new Date('2024-06-05'),
    title: '새천안 이.취임식(천안3지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-06'),
    end: new Date('2024-06-06'),
    title: '천안충남 이.취임식(천안1지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-07'),
    end: new Date('2024-06-07'),
    title: '안면도 이.취임식(태안지역) - 호반리솜'
  },
  {
    start: new Date('2024-06-07'),
    end: new Date('2024-06-07'),
    title: '서령 이.취임식(서산지역) - 아리아컨벤션센터'
  },
  {
    start: new Date('2024-06-07'),
    end: new Date('2024-06-07'),
    title: '새서산 이.취임식(서산지역) - 마블한우'
  },
  {
    start: new Date('2024-06-09'),
    end: new Date('2024-06-09'),
    title: '홍성조양 이.취임식(홍성지역) - 리첸시아 웨딩홀'
  },
  {
    start: new Date('2024-06-10'),
    end: new Date('2024-06-10'),
    title: '홍성 이.취임식(홍성지역) - 청운대 청운홀'
  },
  {
    start: new Date('2024-06-11'),
    end: new Date('2024-06-11'),
    title: '남천안 이.취임식(천안2지역) - 남천안 RC 사무실 유량동 3번 들꽃식당'
  },
  {
    start: new Date('2024-06-12'),
    end: new Date('2024-06-12'),
    title: '광천오서산 이.취임식(청양지역) - 현대예식장'
  },
  {
    start: new Date('2024-06-12'),
    end: new Date('2024-06-12'),
    title: '천안버들 이.취임식(천안3지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-12'),
    end: new Date('2024-06-12'),
    title: '천안파로스 이.취임식(천안1지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-13'),
    end: new Date('2024-06-13'),
    title: '대천중앙 이.취임식'
  },
  {
    start: new Date('2024-06-13'),
    end: new Date('2024-06-13'),
    title: '대천더함 이.취임식 - 보령베이스리조트'
  },
  {
    start: new Date('2024-06-14'),
    end: new Date('2024-06-14'),
    title: '홍성홍주 이.취임식(홍성지역) - 리첸시아 웨딩홀'
  },
  {
    start: new Date('2024-06-14'),
    end: new Date('2024-06-14'),
    title: '당진송악 이.취임식(당진지역) - 설악웨딩타운'
  },
  {
    start: new Date('2024-06-15'),
    end: new Date('2024-06-15'),
    title: '로타리협의회, 재단세미나, 공공이미지세미나 (덕산 스플라스 리솜)'
  },
  {
    start: new Date('2024-06-16'),
    end: new Date('2024-06-16'),
    title: '홍성운주 이.취임식(홍성지역) - 홍성문화원'
  },
  {
    start: new Date('2024-06-16'),
    end: new Date('2024-06-16'),
    title: '백화산 이.취임식(태안지역) - 태안 예술회관 소강당'
  },
  {
    start: new Date('2024-06-16'),
    end: new Date('2024-06-16'),
    title: '홍성홍서 이.취임식(홍성지역) - 더휠링 식물원 야영장'
  },
  {
    start: new Date('2024-06-17'),
    end: new Date('2024-06-17'),
    title: '천안능수 이.취임식(천안3지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-18'),
    end: new Date('2024-06-18'),
    title: '천안 이.취임식(천안1지역) - 이담원'
  },
  {
    start: new Date('2024-06-18'),
    end: new Date('2024-06-18'),
    title: '천안도솔 이.취임식(천안3지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-19'),
    end: new Date('2024-06-19'),
    title: '천안쌍봉 이.취임식(천안2지역) - S컨벤션웨딩홀'
  },
  {
    start: new Date('2024-06-19'),
    end: new Date('2024-06-19'),
    title: '천안동호 이.취임식(천안3지역) - S컨벤션웨딩홀'
  },
  {
    start: new Date('2024-06-20'),
    end: new Date('2024-06-20'),
    title: '당진 이.취임식(당진지역) - 중앙메디컬센터'
  },
  {
    start: new Date('2024-06-21'),
    end: new Date('2024-06-21'),
    title: '서산국화 이.취임식(서산지역) - 클럽사무실-서산시 한성지2길 30-1'
  },
  {
    start: new Date('2024-06-21'),
    end: new Date('2024-06-21'),
    title: '태안한결 이.취임식(태안지역) - 태안문화예술회관'
  },
  {
    start: new Date('2024-06-21'),
    end: new Date('2024-06-21'),
    title: '서산보라매 이.취임식(서산지역) - 아르델웨딩홀'
  },
  {
    start: new Date('2024-06-22'),
    end: new Date('2024-06-22'),
    title: '당진신평 이.취임식(당진지역) - 서해중앙신협'
  },
  {
    start: new Date('2024-06-22'),
    end: new Date('2024-06-22'),
    title: '해나루 이.취임식(당진지역) - 메가뷔페'
  },
  {
    start: new Date('2024-06-22'),
    end: new Date('2024-06-22'),
    title: '천안천룡 이.취임식(천안2지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-23'),
    end: new Date('2024-06-23'),
    title: '태안소성. 태안해당화 합동 이취임식 (태안문화원)'
  },
  {
    start: new Date('2024-06-23'),
    end: new Date('2024-06-23'),
    title: '내포 이취임식 - 클럽사무실'
  },
  {
    start: new Date('2024-06-23'),
    end: new Date('2024-06-23'),
    title: '뉴대천 이.취임식(보령지역)'
  },
  {
    start: new Date('2024-06-24'),
    end: new Date('2024-06-24'),
    title: '광천 이.취임식 - 클럽사무실'
  },
  {
    start: new Date('2024-06-24'),
    end: new Date('2024-06-24'),
    title: '합덕 이.취임식 - 클럽사무실'
  },
  {
    start: new Date('2024-06-24'),
    end: new Date('2024-06-24'),
    title: '태안 이.취임식(태안지역) - 클럽사무실'
  },
  {
    start: new Date('2024-06-25'),
    end: new Date('2024-06-25'),
    title: '석문 이.취임식(당진지역) - 석문스포츠센터'
  },
  {
    start: new Date('2024-06-25'),
    end: new Date('2024-06-25'),
    title: '당나루 이취임식(당진지역) - 설악가든'
  },
  {
    start: new Date('2024-06-25'),
    end: new Date('2024-06-25'),
    title: '천안백두 이.취임식(천안2지역) - 컨벤션웨딩홀'
  },
  {
    start: new Date('2024-06-26'),
    end: new Date('2024-06-26'),
    title: '안면도송림 이.취임식(태안지역) - 안면읍사무소'
  },
  {
    start: new Date('2024-06-26'),
    end: new Date('2024-06-26'),
    title: '당진골드 이취임식 - 메가뷔페'
  },
  {
    start: new Date('2024-06-26'),
    end: new Date('2024-06-26'),
    title: '천안하늘 이.취임식(천안2지역) - 서부대로 300. 3층'
  },
  {
    start: new Date('2024-06-26'),
    end: new Date('2024-06-26'),
    title: '천안동남 이.취임식(천안3지역) - S컨벤션웨딩홀'
  },
  {
    start: new Date('2024-06-27'),
    end: new Date('2024-06-27'),
    title: '천안중앙 이취임식(천안4지역) - 천안 쎌레네 웨딩하우스'
  },
  {
    start: new Date('2024-06-27'),
    end: new Date('2024-06-27'),
    title: '서천안 이.취임식(천안2지역) - S컨벤션 웨딩홀'
  },
  {
    start: new Date('2024-06-28'),
    end: new Date('2024-06-28'),
    title: '충서 이.취임식(서산지역) - 아르델 컨벤션'
  },
  {
    start: new Date('2024-06-28'),
    end: new Date('2024-06-28'),
    title: '당진아미 이.취임식(당진지역) - 메가뷔페'
  },
  {
    start: new Date('2024-06-29'),
    end: new Date('2024-06-29'),
    title: '예산 이.취임식(예산지역) - 더스타컨벤션'
  },
  {
    start: new Date('2024-06-29'),
    end: new Date('2024-06-29'),
    title: '고덕 이.취임식(예산지역) - 고덕명품뷔페'
  },
  {
    start: new Date('2024-06-29'),
    end: new Date('2024-06-29'),
    title: '당진고대 이.취임식(당진지역) - 당진종합운동장내 당진트레이닝센터'
  },
  {
    start: new Date('2024-06-29'),
    end: new Date('2024-06-29'),
    title: '천안남산 이.취임식(천안4지역) - S컨벤션웨딩홀'
  },
  {
    start: new Date('2024-06-29'),
    end: new Date('2024-06-29'),
    title: '아산단미 창립주회(아산2지역) - 아산터미널웨딩홀'
  },
  {
    start: new Date('2024-06-30'),
    end: new Date('2024-06-30'),
    title: '칠갑산 이.취임식(청양지역) - 정산면사무소'
  },
  {
    start: new Date('2024-06-30'),
    end: new Date('2024-06-30'),
    title: '청양 이취임식(청양지역) - 라온웨딩홀'
  }
];

  const handleEventSelect = event => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null);
  };

  return (
    <div style={{ height: 600 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, fontSize: '5px' }}
        defaultView="month"
        views={['month']}
        onSelectEvent={handleEventSelect}
      />
      {selectedEvent && (
        <Dialog open={true} onClose={handleClose}>
          <DialogContent>
            <Typography variant="body1">
              {moment(selectedEvent.start).format('YYYY년 MM월 DD일')}
              <DialogTitle>{selectedEvent.title}</DialogTitle>
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default CalendarPage;


// {
//       start: new Date('2024-03-05'),
//       end: new Date('2024-03-05'),
//       title: '임원 회의'
//     },
//     {
//       start: new Date('2024-03-09'),
//       end: new Date('2024-03-09'),
//       title: '2023-24년도 회장 간담회'
//     },
//     {
//       start: new Date('2024-03-09'),
//       end: new Date('2024-03-09'),
//       title: '2024-25년도 회장 연수회 (PETS)'
//     },
//     {
//       start: new Date('2024-03-16'),
//       end: new Date('2024-03-16'),
//       title: '지구대회 발대식'
//     },
//     {
//       start: new Date('2024-03-16'),
//       end: new Date('2024-03-16'),
//       title: '총재단 자문회의'
//     },
//     {
//       start: new Date('2024-03-23'),
//       end: new Date('2024-03-23'),
//       title: '24-25년도 회장∙총무 연수회(PETS)'
//     },
//     {
//       start: new Date('2024-03-13'),
//       end: new Date('2024-03-13'),
//       title: '24-25년도 청소년지구협의회'
//     },