import React from 'react';
import { Link } from 'react-router-dom';
import { Button, List, ListItem } from '@mui/material';

const buttonData = [
  {
    route: "/training", // URL 대신 라우팅 경로를 사용
    label: "🧑🏻‍💻 연수동영상 보러가기 👩‍🏫",
  },
];

const SubBtn = () => {
  return (
    <List style={styles.listStyle}>
      {buttonData.map((button, index) => (
        <ListItem key={index} style={styles.listItemStyle}>
          <Link to={button.route} rel="noopener noreferrer" style={styles.linkStyle}>
            <Button
              variant="contained"
              sx={{
                fontSize: '20px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#194C86',
                color: '#fff',
                width: '350px',
                textAlign: 'center',
                textTransform: 'none' // Add this line
              }}
            >
              {button.label.toLowerCase()}
            </Button>
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

const styles = {
  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItemStyle: {
    width: '100%', // 각 항목의 너비를 조절
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px', // 항목 간 간격
  },
  linkStyle: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
  }
};

export default SubBtn;
