import React from 'react';
import ComplexBtn from './ComplexBtn';
import GiguNews from './GiguNews';
import AreaNews from './AreaNews';
import ClubNews from './ClubNews';
import MainNews from './MainNews';
import Footer from '../footer/Footer';
import useStyles from '../../styles/contents/MainScreenStyle';
// import QRAttendButton from './QRAttendButton';

const MainScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <ComplexBtn />
      <MainNews />
      <br/>
      <GiguNews />
      <br/>
      <AreaNews />
      <br/>
      <ClubNews />
      <br/>
      <ComplexBtn />
      <br/>
      <Footer />
      {/* <QRAttendButton /> QR 출석 버튼 추가 */}
    </div>
  );
};

export default MainScreen;
