import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data = [
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcxmyzP%2FbtsIt2nkoTf%2FlZPDMv1PVuUA6iwwu4B1QK%2Fimg.jpg',
    name: 'LEVEL1 황영택',
    affiliation: '대천중앙 RC',
    position: '전회장',
    donationAmount: '$1,000.00',
    donationAchievement: '$11,676',
    joinDate: '2008. 6. 24',
  },
];


const Donation = () => {
  const classes = useStyles(); // Initialize useStyles hook here

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{textAlign: 'center', marginTop: '10px'}}>
            <strong>2023-24년도 &lt;6월&gt;<br/>기부자 현황 </strong>
          </Typography>
        </Grid>

        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <Paper elevation={3} className={classes.cardA}>
              <div className={classes.imageContainer}>
                <img src={item.imageUrl} alt="img" className={classes.image} />
              </div>
              <div className={classes.textContainer}>
                <Typography variant="h6" className={classes.title}>
                  {item.name}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.affiliation}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.position}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부금액: </strong> {item.donationAmount}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부실적:</strong> {item.donationAchievement}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>입회일:</strong> {item.joinDate}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Donation;
