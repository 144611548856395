import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const ComplexBtn = () => {
  return (
    <Box display="flex" justifyContent="center" style={{ marginBottom: '20px' }}>
      <Stack direction="row" spacing={2}>
        <Link to="/page1" style={{ textDecoration: 'none' }}>
          <Button variant="contained" sx={{ fontSize: '15px', padding: '10px 15px', fontWeight: 'bold', backgroundColor: '#194C86' }}>
            지구소식
          </Button>
        </Link>
        <Link to="/page2" style={{ textDecoration: 'none' }}>
          <Button variant="contained" sx={{ fontSize: '15px', padding: '10px 15px', fontWeight: 'bold', backgroundColor: '#941B4C' }}>
            지역소식
          </Button>
        </Link>
        <Link to="/page3" style={{ textDecoration: 'none' }}>
          <Button variant="contained" sx={{ fontSize: '15px', padding: '10px 15px', fontWeight: 'bold', backgroundColor: '#EDA123' }}>
            클럽소식
          </Button>
        </Link>
      </Stack>
    </Box>
  );
};

export default ComplexBtn;


