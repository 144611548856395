import React, { useState } from 'react';
import { AppBar, Paper, Tab, Tabs, ThemeProvider, createTheme, Box } from '@mui/material';
import DonationAJ from './DonationAJ';
import DonationBJ from './DonationBJ';
import DonationCJ from './DonationCJ';
import DonationDJ from './DonationDJ';
import DonationEJ from './DonationEJ';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const DonationScreen = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div>
        <AppBar position="static">
          <Box sx={{ maxWidth: { xs: 400, sm: 680 }, bgcolor: 'background.paper' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label="기부자명단" style={{ color: '#941B4C' }} />
              <Tab label="기부자명단" style={{ color: '#EDA123', marginLeft: '-15px' }} />
              <Tab label="봉사의인" style={{ color: '#194C86', marginLeft: '-15px' }} />
              <Tab label="개인별 재단기부현황" style={{ color: '	#228B22', marginLeft: '-15px' }} />
            </Tabs>
          </Box>
        </AppBar>
        <Paper>
          {value === 0 && <div><DonationAJ /><DonationBJ /></div>}
          {value === 1 && <DonationCJ />}
          {value === 2 && <DonationDJ />}
          {value === 3 && <DonationEJ />}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default DonationScreen;
