import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  { clubName: '천안 RC', phf: '74,000,000', rfsm: '90,715,830', bnf: '3,300,000', limit: '94,015,830' },
  { clubName: '천안삼거리 RC', phf: '77,500,000', rfsm: '103,200,000', bnf: '9,000,000', limit: '112,200,000' },
  { clubName: '천안파로스 RC', phf: '12,900,000', rfsm: '105,600,000', bnf: '300,000', limit: '105,900,000' },
  { clubName: '천안드림 RC', phf: '11,400,000', rfsm: '15,300,000', bnf: '300,000', limit: '15,600,000' },
  { clubName: '천안충남 RC', phf: '3,900,000', rfsm: '33,000,000', bnf: '1,800,000', limit: '34,800,000' },
];

const data2 = [
  { clubName: '천안 RC', phf: '73,420,000', rfsm: '46,990,000', bnf: '', limit: '46,990,000' },
  { clubName: '천안삼거리 RC', phf: '59,900,000', rfsm: '111,900,000', bnf: '12,000,000', limit: '123,900,000' },
  { clubName: '천안파로스 RC', phf: '39,400,000', rfsm: '18,900,000', bnf: '', limit: '18,900,000' },
  { clubName: '천안드림 RC', phf: '6,300,000', rfsm: '1,800,000', bnf: '', limit: '1,800,000' },
  { clubName: '천안충남 RC', phf: '', rfsm: '4,200,000', bnf: '600,000', limit: '4,800,000' },
  { clubName: '성환 RC', phf: '', rfsm: '2,100,000', bnf: '900,000', limit: '3,000,000' },
  { clubName: '서천안 RC', phf: '3,300,000', rfsm: '15,900,000', bnf: '1,500,000', limit: '17,400,000' },
  { clubName: '남천안 RC', phf: '300,000', rfsm: '300,000', bnf: '1,200,000', limit: '1,500,000' },
];

const data3 = [
  { clubName: '새천안 RC', phf: '77,500,000', rfsm: '49,563,800', bnf: '660,000', limit: '50,223,800' },
  { clubName: '천안능수 RC', phf: '30,300,000', rfsm: '28,800,000', bnf: '3,000,000', limit: '31,800,000' },
  { clubName: '천안도솔 RC', phf: '52,200,000', rfsm: '149,500,000', bnf: '6,000,000', limit: '155,500,000' },
  { clubName: '천안동남 RC', phf: '12,000,000', rfsm: '30,900,000', bnf: '600,000', limit: '31,500,000' },
  { clubName: '천안상록 RC', phf: '2,700,000', rfsm: '8,100,000', bnf: '5,400,000', limit: '13,500,000' },
  { clubName: '천안천지 RC', phf: '5,700,000', rfsm: '4,500,000', bnf: '', limit: '4,500,000' },
  { clubName: '천안희망 RC', phf: '', rfsm: '300,000', bnf: '', limit: '300,000' },
  { clubName: '천안리더스 RC', phf: '600,000', rfsm: '1,500,000', bnf: '', limit: '1,500,000' },
  { clubName: '천안동호 RC', phf: '1,500,000', rfsm: '12,000,000', bnf: '5,400,000', limit: '17,400,000' },
  { clubName: '천안버들 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data4 = [
  { clubName: '천안중앙 RC', phf: '85,300,000', rfsm: '18,300,000', bnf: '600,000', limit: '18,900,000' },
  { clubName: '천안목주 RC', phf: '38,000,000', rfsm: '19,900,000', bnf: '12,300,000', limit: '32,200,000' },
  { clubName: '천안일봉 RC', phf: '39,000,000', rfsm: '20,100,000', bnf: '900,000', limit: '21,000,000' },
  { clubName: '천안남산 RC', phf: '600,000', rfsm: '300,000', bnf: '', limit: '300,000' },
  { clubName: '천안서북 RC', phf: '', rfsm: '3,000,000', bnf: '', limit: '3,000,000' },
  { clubName: '천안아우내 RC', phf: '3,300,000', rfsm: '2,400,000', bnf: '', limit: '2,400,000' },
  { clubName: '천안무궁화 RC', phf: '300,000', rfsm: '', bnf: '', limit: '-' },
  { clubName: '천안다온 RC', phf: '', rfsm: '4,800,000', bnf: '2,700,000', limit: '7,500,000' },
  { clubName: '천안다온 로타랙트', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data5 = [
  { clubName: '온양 RC', phf: '131,000,000', rfsm: '103,835,000', bnf: '11,400,000', limit: '115,235,000' },
  { clubName: '온양목련 RC', phf: '28,200,000', rfsm: '17,600,000', bnf: '1,500,000', limit: '19,100,000' },
  { clubName: '아산둔포 RC', phf: '79,500,000', rfsm: '22,500,000', bnf: '660,000', limit: '23,160,000' },
  { clubName: '아산배방 RC', phf: '5,400,000', rfsm: '2,700,000', bnf: '3,900,000', limit: '6,600,000' },
  { clubName: '아산온궁 RC', phf: '31,500,000', rfsm: '30,000,000', bnf: '2,400,000', limit: '32,400,000' },
  { clubName: '아산성웅 RC', phf: '300,000', rfsm: '600,000', bnf: '300,000', limit: '900,000' },
  { clubName: '온양온천 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data6 = [
  { clubName: '새온양 RC', phf: '121,700,000', rfsm: '61,345,000', bnf: '3,000,000', limit: '64,345,000' },
  { clubName: '온아 RC', phf: '152,500,000', rfsm: '412,600,000', bnf: '9,900,000', limit: '422,500,000' },
  { clubName: '아산 RC', phf: '32,200,000', rfsm: '30,500,000', bnf: '4,800,000', limit: '35,300,000' },
  { clubName: '온양중앙 RC', phf: '62,300,000', rfsm: '163,400,000', bnf: '5,100,000', limit: '168,500,000' },
  { clubName: '아산온정 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
  { clubName: '아산현충 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
  { clubName: '아산단미 RC', phf: '900,000', rfsm: '300,000', bnf: '', limit: '300,000' },
  { clubName: '아산온수 RC', phf: '', rfsm: '300,000', bnf: '', limit: '300,000' },
];

const data7 = [
  { clubName: '예산 RC', phf: '95,900,000', rfsm: '45,600,000', bnf: '2,100,000', limit: '47,700,000' },
  { clubName: '신례원 RC', phf: '27,600,000', rfsm: '8,400,000', bnf: '', limit: '8,400,000' },
  { clubName: '고덕 RC', phf: '36,100,000', rfsm: '28,200,000', bnf: '480,000', limit: '28,680,000' },
  { clubName: '예산어울림 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data8 = [
  { clubName: '당진 RC', phf: '64,400,000', rfsm: '65,435,000', bnf: '2,100,000', limit: '67,535,000' },
  { clubName: '석문 RC', phf: '34,200,000', rfsm: '35,700,000', bnf: '1,200,000', limit: '36,900,000' },
  { clubName: '합덕 RC', phf: '38,000,000', rfsm: '4,500,000', bnf: '', limit: '4,500,000' },
  { clubName: '당진당나루 RC', phf: '64,800,000', rfsm: '40,000,000', bnf: '300,000', limit: '40,300,000' },
  { clubName: '당진송악 RC', phf: '21,600,000', rfsm: '30,000,000', bnf: '3,000,000', limit: '33,000,000' },
  { clubName: '당진신평 RC', phf: '53,400,000', rfsm: '31,200,000', bnf: '1,500,000', limit: '32,700,000' },
  { clubName: '당진고대 RC', phf: '5,000,000', rfsm: '6,000,000', bnf: '', limit: '6,000,000' },
  { clubName: '당진해나루 RC', phf: '600,000', rfsm: '900,000', bnf: '', limit: '900,000' },
  { clubName: '당진아미 RC', phf: '', rfsm: '1,800,000', bnf: '600,000', limit: '2,400,000' },
  { clubName: '당진골드 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data9 = [
  { clubName: '광천 RC', phf: '152,300,000', rfsm: '69,616,000', bnf: '3,000,000', limit: '72,616,000' },
  { clubName: '홍성 RC', phf: '184,900,000', rfsm: '352,600,000', bnf: '11,100,000', limit: '363,700,000' },
  { clubName: '청양 RC', phf: '62,700,000', rfsm: '9,030,000', bnf: '', limit: '9,030,000' },
  { clubName: '홍성홍서 RC', phf: '37,500,000', rfsm: '11,300,000', bnf: '', limit: '11,300,000' },
  { clubName: '홍성홍주 RC', phf: '84,300,000', rfsm: '91,960,000', bnf: '3,000,000', limit: '94,960,000' },
  { clubName: '청양칠갑산 RC', phf: '22,200,000', rfsm: '3,300,000', bnf: '', limit: '3,300,000' },
  { clubName: '홍성조양 RC', phf: '12,300,000', rfsm: '31,500,000', bnf: '1,500,000', limit: '33,000,000' },
  { clubName: '내포 RC', phf: '300,000', rfsm: '300,000', bnf: '600,000', limit: '900,000' },
  { clubName: '광천오서산 RC', phf: '1,500,000', rfsm: '15,000,000', bnf: '3,000,000', limit: '18,000,000' },
  { clubName: '홍성용봉 RC', phf: '', rfsm: '600,000', bnf: '1,200,000', limit: '1,800,000' },
  { clubName: '홍성운주 RC', phf: '', rfsm: '2,400,000', bnf: '300,000', limit: '2,700,000' },
];

const data10 = [
  { clubName: '대천 RC', phf: '105,200,000', rfsm: '206,610,000', bnf: '2,700,000', limit: '209,310,000' },
  { clubName: '대천중앙 RC', phf: '91,500,000', rfsm: '104,700,000', bnf: '9,300,000', limit: '114,000,000' },
  { clubName: '대천서부 RC', phf: '16,100,000', rfsm: '15,700,000', bnf: '600,000', limit: '16,300,000' },
  { clubName: '뉴대천 RC', phf: '141,700,000', rfsm: '50,400,000', bnf: '4,800,000', limit: '55,200,000' },
  { clubName: '대천더함 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data11 = [
  { clubName: '서산 RC', phf: '77,600,000', rfsm: '287,300,000', bnf: '4,800,000', limit: '292,100,000' },
  { clubName: '서령 RC', phf: '35,900,000', rfsm: '15,900,000', bnf: '', limit: '15,900,000' },
  { clubName: '새서산 RC', phf: '37,700,000', rfsm: '6,700,000', bnf: '1,200,000', limit: '7,900,000' },
  { clubName: '충서 RC', phf: '36,600,000', rfsm: '31,300,000', bnf: '2,400,000', limit: '33,700,000' },
  { clubName: '서산한서 RC', phf: '1,800,000', rfsm: '40,700,000', bnf: '1,200,000', limit: '41,900,000' },
  { clubName: '서산보라매 RC', phf: '29,700,000', rfsm: '57,900,000', bnf: '2,700,000', limit: '60,600,000' },
  { clubName: '서산국화 RC', phf: '2,700,000', rfsm: '12,600,000', bnf: '2,400,000', limit: '15,000,000' },
  { clubName: '서산가온 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
];

const data12 = [
  { clubName: '태안 RC', phf: '58,500,000', rfsm: '900,000', bnf: '', limit: '900,000' },
  { clubName: '안면도 RC', phf: '32,300,000', rfsm: '5,600,000', bnf: '', limit: '5,600,000' },
  { clubName: '태안소성 RC', phf: '46,100,000', rfsm: '44,800,000', bnf: '780,000', limit: '45,580,000' },
  { clubName: '태안백화산 RC', phf: '30,800,000', rfsm: '33,200,000', bnf: '1,500,000', limit: '34,700,000' },
  { clubName: '태안해당화 RC', phf: '300,000', rfsm: '2,700,000', bnf: '', limit: '2,700,000' },
  { clubName: '안면도송림 RC', phf: '', rfsm: '', bnf: '', limit: '-' },
  { clubName: '태안한결 RC', phf: '', rfsm: '300,000', bnf: '300,000', limit: '600,000' }
];

const data13 = [
  {
    clubName: '총합계액',
    phf: ' 3,100,920,000 ',
    rfsm: ' 3,552,200,630 ',
    bnf: ' 181,380,000 ',
    limit: ' 3,733,580,630 ',
  },
];


const ClubTotalC = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안1지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>봉사의인<br/>총액</TableCell>
                </TableRow>
                {data1.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안2지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data2.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안3지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data3.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 천안4지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data4.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산1지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data5.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 아산2지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data6.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 예산지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data7.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 당진지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data8.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 홍성∙청양지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data9.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 보령지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data10.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 서산지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data11.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 태안지역 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data12.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <br/><strong>[ 총합계액 ]</strong><br/><br/>
        </Typography>
        <Typography variant="body2" style={{textAlign: 'right'}}>
          (단위:원)<br/><br/>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <Table>
              <TableBody className={classes.preserveNewline}>
                {/* <TableRow>
                  <TableCell className={classes.cell} colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>봉사의인</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>클럽명</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(탈회회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.6.30<br/>(활동회원)</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>2023.7.1<br/>~ 2024.5.31<br/>봉사의인</TableCell>
                  <TableCell className={classes.cell} align="center" style={{ backgroundColor: '#f0f0f0' }}>총액</TableCell>
                </TableRow>
                {data13.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} align="center">{row.clubName}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.phf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.rfsm}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.bnf}</TableCell>
                    <TableCell className={classes.cell} align="right">{row.limit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHead>
        </Table>
      </TableContainer>
      <br/><br/>
    </div>
  );
};

export default ClubTotalC;