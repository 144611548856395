import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const ClubNews = () => {
  const newsData = [
    {
      id: 1,
      title: '',
      description: '3620지구의 클럽소식을 전해드립니다. 클릭하세요!',
      date: '2024년 6월',
      imageUrl: 'https://blog.kakaocdn.net/dn/d7yvyI/btsyTXhfUxE/jRxEkiydyJZ25gFfkp8iqK/img.gif',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontSize: '2rem', color: '#194C86', textAlign: 'center', borderBottom: '4px solid #194C86', padding: '1rem', cursor: 'pointer' }}>
          <strong>클럽소식</strong>
        </Typography>
      </Grid>
      {newsData.map((news) => (
        <Grid item xs={12} sm={6} md={4} key={news.id}>
          <Link to={`/clubNews/${news.id}`} style={{ textDecoration: 'none', color: 'black' }}>
            <Card>
              <CardMedia
                component="img"
                height="150"
                image={news.imageUrl}
                alt={news.title}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {news.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news.description}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {news.date}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default ClubNews;
