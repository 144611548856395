import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    textAlign: 'center', // Center align content
    marginTop: '10px',
  },
  logo: {
    width: '100%', // Adjust the photo size as needed
    height: 'auto',
    display: 'block',
    marginTop: '-50px',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-150px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-300px',
    },
  },
  pictureFrame: {
    boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.5)', // Add a shadow effect
    padding: theme.spacing(1), // Add padding for spacing between the image and border
    display: 'inline-block', // Display the image as an inline block
    borderRadius: '10px', // Add rounded corners to the border
    overflow: 'hidden', // Hide any part of the image that exceeds the container height
    height: '89%',
  },
  profile: {
    width: '100%', // Adjust the photo size as needed
    height: 'auto',
    display: 'block',
    margin: '0 auto', // Center align the image
  },
  profileContainer: {
    textAlign: 'center', // Center align text
  },
  profileName: {
    marginTop: theme.spacing(2),
  },
  profilePosition: {
    marginTop: theme.spacing(1),
  },
  messageText: {
    textAlign: 'left', // Left align text
    whiteSpace: 'pre-line',
    fontSize: '16px', // Adjust the font size as needed
  },
}));

export default useStyles;