import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 서산지역 ]",
    title2: "협의회 회의",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcAuemL%2FbtsHSsHkBQ9%2FH4CWg4G1ZBv4SiloECuDe1%2Fimg.jpg',
    description: '&nbsp;5월 6일(화), 재단 및 각 클럽 이∙취임식 일정을 공유하는 등 서산지역 협의회를 진행했다.',
  },
  {
    title1: "[ 서산보라매 로타리클럽 ]",
    title2: "환경정화 봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fw5Cqy%2FbtsHSCbKHTs%2FTt7KlknKbNYqJQTrnOt5r0%2Fimg.png',
    description: '&nbsp;5월 26일(일), 서산 팔봉산 등산로 환경정화 봉사를 실시했다.',
  },
  {
    title1: "[ 서산국화 로타리클럽 ]",
    title2: "성봉학교 미용봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbnGwgh%2FbtsHSV3iVvn%2F4RdRGZ3Xe9sqkojWpqaOkK%2Fimg.png',
    description: '&nbsp;5월 13일(월) 오전 10~12시, 성봉학교 2층에서 미용봉사를 실시했다.',
  },
  {
    title1: "[ 서산가온 로타리클럽 ]",
    title2: "서산 사랑의집 나눔회 집짓기",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fby4qkF%2FbtsHSDu4h0G%2Fhb4UJK8Ray6Z3NzoR5Y7N1%2Fimg.png',
    description: '&nbsp;5월 25일(토) 서산 사랑의집 나눔회 집짓기 현장(서산시 부석면)에 다녀왔다. 아직 초반이라 할 수 있는 일이 많지는 않았지만 현장 주변정리를 하기위해 방문했다. 집짓기 봉사는 현회기의 마지막과 차기의 시작을 함께하는 봉사로, 김은자 회장과 박수경 차기회장이 함께 참석했다. 방문 당일 외부 판넬작업을 하고 있어 현장 주변 정리와 함께 작은 힘이나마 도움을 주고 왔다.',
  },
];

const ClubSs = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubSs;
