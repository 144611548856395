import React, { useState } from 'react';
import { AppBar, Paper, Tab, Tabs, ThemeProvider, createTheme, Box } from '@mui/material';
import ClubCaA from './ClubCaA';
import ClubCaB from './ClubCaB';
import ClubCaC from './ClubCaC';
import ClubCaD from './ClubCaD';
import ClubAsA from './ClubAsA';
import ClubAsB from './ClubAsB';
import ClubEs from './ClubEs';
import ClubDj from './ClubDj';
import ClubHc from './ClubHc';
import ClubSs from './ClubSs';
import ClubBl from './ClubBl';
import ClubTa from './ClubTa';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const ClubScreen = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div>
        <AppBar position="static">
          <Box sx={{ maxWidth: { xs: 400, sm: 680 }, bgcolor: 'background.paper' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label="서산지역" style={{ color: '#1E90FF', marginLeft: '-15px' }} />
              <Tab label="태안지역" style={{ color: '#00008B	', marginLeft: '-15px' }} />
              <Tab label="천안1지역" style={{ color: '#941B4C' }} />
              <Tab label="천안2지역" style={{ color: '#EDA123', marginLeft: '-15px' }} />
              <Tab label="천안3지역" style={{ color: '#194C86', marginLeft: '-15px' }} />
              <Tab label="천안4지역" style={{ color: '#228B22', marginLeft: '-15px' }} />
              <Tab label="아산1지역" style={{ color: '#8B0000	', marginLeft: '-15px' }} />
              <Tab label="아산2지역" style={{ color: '#32CD32', marginLeft: '-15px' }} />
              <Tab label="예산지역" style={{ color: '#228B22', marginLeft: '-15px' }} />
              <Tab label="당진지역" style={{ color: '#00BFFF', marginLeft: '-15px' }} />
              <Tab label="홍성∙청양지역" style={{ color: '#9400D3', marginLeft: '-15px' }} />
              <Tab label="보령지역" style={{ color: '#2F4F4F', marginLeft: '-15px' }} />
            </Tabs>
          </Box>
        </AppBar>
        <Paper>
          {value === 0 && <ClubSs />}
          {value === 1 && <ClubTa />}
          {value === 2 && <ClubCaA />}
          {value === 3 && <ClubCaB />}
          {value === 4 && <ClubCaC />}
          {value === 5 && <ClubCaD />}
          {value === 6 && <ClubAsA />}
          {value === 7 && <ClubAsB />}
          {value === 8 && <ClubEs />}
          {value === 9 && <ClubDj />}
          {value === 10 && <ClubHc />}
          {value === 11 && <ClubBl />}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default ClubScreen;