import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 새온양 로타리클럽 ]",
    title2: "폴리오 기금 마련 및<br/>친목도모 스크린골프 대회",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F6W8vT%2FbtsHTDHLwkb%2FKCQBsvKHNuPtfyJ54hmcG0%2Fimg.jpg',
    description: '&nbsp;5월 4일(토) 오후 2시, 온천동 VIP 스크린에서 폴리오 기금 마련을 목적으로 스크린골프 대회를 개최했다. 회원들의 많은 참여로 즐거운 모임을 성공적으로 끝냈다.',
  },
  {
    title1: "[ 온아 로타리클럽 ]",
    title2: "2024-25년도 보조금관리 세미나 및<br/>보조금 프리젠테이션",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FAqhzw%2FbtsHTTcyb6i%2FMV4fafIQbGcOQkUgKzr0f1%2Fimg.png',
    description: '&nbsp;5월 4일(토) 오전 10시, 2024-25년 보조금관리 세미나 및 보조금 프리젠테이션을 진행했다. 프리젠테이션 신청한 각 클럽이 모여 클럽의 봉사 계획과 보조금 신청을 발표하고 위원장들과 개선점과 발전 방향을 이야기했다.',
  },
  {
    title1: "[ 아산 로타리클럽 ]",
    title2: "전국 장애인 당구대회 봉사활동",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FmiCu1%2FbtsHRKaUSmx%2FlTADcMKKbkuKNZFVN82K00%2Fimg.png',
    description: '&nbsp;55월 31일, 아산시장애인당구협회주관인 "전국 장애인당구대회" 사전준비와 개회식 진행등 봉사활동을 진행하였다. 전국에서 아산을 찾아준 200여명의 장애인당구 선수들이 불편함이 없도록 행사 진행에 작은 보탬이 되었다.',
  },
];

const ClubAsB = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubAsB;
