import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 천안2지역 ]",
    title2: "2024-25회기 협의회",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbgAb4b%2FbtsHR9nHYFl%2F5k6kgKsQr8VC4mKaqMKvC0%2Fimg.png',
    description: '&nbsp;5월 2일 서천안 로타리클럽 사무실에서 2024-25회기 천안2지역 5월 협의회가 있었다. 조성희 천안2지역 대표의 인사말과 안태홍 2지역 사무차장의 지구소식 전달 사항을 듣고 주문종 협의회장 주관하에 협의회를 진행하였다.',
  },
  {
    title1: "[ 서천안 로타리클럽 ]",
    title2: "어린이날 식사봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F9qtsV%2FbtsHTCoziV1%2Fjw8AEt8phdHwNiSXDZP6U1%2Fimg.jpg',
    description: '&nbsp;5월 5일, 꿈찬 성환 그룹홈 친구들과 선생님을 모시고 어린이날 식사봉사를 진행했다. 클럽 회원이 운영하는 자신담 한정식 식당에서 불고기를 대접하였다.',
  },
  {
    title1: "[ 서천안 로타리클럽 ]",
    title2: "어버이날 기념 행복 나들이",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbJ4PEL%2FbtsHR8bg87s%2FvxMZXN5G5u8QxkkX6by0g0%2Fimg.png',
    description: '&nbsp;5월 9일, 어버이날 기념 행복 나들이 봉사를 진행했다. 마당공연 관람후 식사하고 독립기념관 나들이를 하고 왔다. 거동이 불편한 어르신들 휠체어도 끌어드리고 손잡고 같이 동행하니 무척 좋아해주셨다.',
  },
];

const ClubCaB = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubCaB;
