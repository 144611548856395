import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const DonationC = () => {
  const classes = useStyles();
  const rawData = `
예산RC 오주석 25.36 
예산RC 유선국 1.45 
예산RC 이재춘 25.36 
예산RC 장은종 1.45 
예산RC 황관희 25.36 
천안능수RC 추예령 100.00 
천안쌍봉RC 김성호 100.00 
천안쌍봉RC 박종해 100.00 
천안쌍봉RC 손성춘 100.00 
천안쌍봉RC 신상우 100.00 
천안쌍봉RC 이재우 100.00 
천안쌍봉RC 이택규 100.00 
천안쌍봉RC 임현오 100.00 
천안쌍봉RC 전유진 100.00 
천안쌍봉RC 최광용 100.00 
천안쌍봉RC 황재순 100.00 
천안하늘RC 조욱현 101.45 
태안한결RC 안해은 126.50 
홍성조양RC 김미경 100.00 
홍성조양RC 조연화 100.00 
`;

  // Split and parse the raw data
  const data = rawData
    .trim()
    .split('\n')
    .map((line) => {
      const [category, donor, total] = line.split(' ');
      return { category, donor, total: parseFloat(total) };
    });

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
    <Grid item xs={12}>
          <Typography variant="h5" style={{textAlign: 'center', marginTop: '10px'}}>
            <strong>2023-24년도 &lt;5월&gt;<br/>RFSM, 폴리오기부자 명단</strong>
          </Typography>
        </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>클럽명</TableCell>
            <TableCell className={classes.cell}>회원명</TableCell>
            {/* <TableCell className={classes.cell}>기부실적</TableCell> */}
            <TableCell className={classes.cell}>기부($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.cell}>{row.category}</TableCell>
              <TableCell className={classes.cell}>{row.donor}</TableCell>
              {/* <TableCell className={classes.cell}>{row.pay}</TableCell> */}
              <TableCell className={classes.cell}>{row.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DonationC;
