import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiA = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          국제로타리 3620지구{'\n'} 2023-24년도{'\n'}{'\n'}
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>제29년차 지구대회</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbR9CFc%2FbtsH1he5yPF%2F3tOKKu1QykgBVdMH16h190%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;2024년 5월 18~19일, 충남 보령시 소재 보령머드테마파크 컨벤션관에서 3620지구의 제 29년 차 지구대회가 열렸다.
          {'\n'}{'\n'}
          &nbsp;박병달 RI 회장대리(3680지구 전총재)와 3620지구의 수장인 해암 박홍전 총재 내외를 비롯해 지구 역대 총재단과 지구 임원, 회원들이 참석해 지구대회를 그 어느때보다 즐겼다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fed5p36%2FbtsH13gonA1%2FI9sghSQnEZsdv1iKF9hWAk%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F27f6f%2FbtsH00SbebK%2FGJtO0X1GYfwp9PLLadvix0%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcoLlKy%2FbtsH3PnrzPZ%2FiQN5x7whH7vwfkFVbbLaW0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;이번 연도에는 그 어느 때 보다 국제 봉사에 초점을 맞추고 지구촌 곳곳에 희망을 전하기 위해 노력했다. 특히 캄보디아 르비아로타리3620초등학교를 준공하고, 필리핀 오지의 비냔지역에 깨끗하고 살기좋은 마을을 조성하는 등 열악한 국가에 기반시설을 만드는 데 힘썼으며, 총 15건의 글로벌 보조금사업으로 인류애를 실천했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbB76tb%2FbtsH2VaKRFc%2FVWePEVRjkh4mUIbPXgUytk%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;해암 박홍전 총재는 "보다 많은 곳에 희망을 전하기 위해 애쓴 회원들의 노고로, 우리는 다양한 지역의 어려운 이웃들을 만나고 교감할 수 있었다. 우리가 행한 봉사가 쌓여 모두에게 경사스러운 일이 가득 하길 바란다. 더불어 경사스러운 오늘을 다함께 즐기길 바란다"고 감사를 전했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FwRe0R%2FbtsH3oKnXdr%2FKJAtE21ESik9LMzXy5G4BK%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;박병달 RI 회장대리는 RI 현황을 보고하며 "지금은 역사적으로 매우 중요한 시간이 다. 국제 사회가 하나로 이어지면서 코로나 19와 같은 위기도 있었지만, 로타리가 선한 영향력을 더욱 넓히고 확산시키는 기회를 맞기도 했다. 여러분의 행동 하나하나 가 국제 사회에 큰 영향을 미칠 수 있다는 것을 기억해주기 바란다"며 회원들을 독려했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FleoZ2%2FbtsH2k90p7k%2FauJ5chqP5TkxLDk4Nkm0Qk%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;둘째날 본회의에서는 각 부문별 클럽 표창이 이뤄졌다. 천안목주 로타리클럽이 종합대상을 수상했으며, 대상에는 뉴대천, 서천안 로타리클럽이 이름을 올렸다. 시상식 후에는 수상 클럽 회장들의 로타리 모멘트 발표도 이어져 청중들이 축하의 박수를 보냈다. 김구 천안목주 RC 회장은 "앞으로도 초아의 봉사를 통해 어두운 곳에 밝은 빛을 비추는 클럽이 되도록 노력하겠다"고 소감을 말했다.
          {'\n'}{'\n'}
          &nbsp;이번 지구대회에서는 여성회원들로 결성된 지구 공연팀의 퓨전 국악 공연, 한국 K팝 고등학교의 인터랙트클럽 회원들이 선보인 춤과 노래가 많은 회원의 호응을 얻었다. 또한 자 매지구인 필리핀 3830지구와 선물을 교환하는 등 우정을 나누며, 지구의 국제적인 역량을 확인하고 회원들과 공유하는 시간도 가졌다. 
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fl8tJb%2FbtsH16RH8PL%2F6GupjomfrYg2GGKH0lVvG1%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FA2d8P%2FbtsH3PVhtBa%2FjzeZWNG5vgMQ8Ar56uH430%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbTVcVD%2FbtsH2nZZAOv%2FD7sHeFImzv4PDfgC5d2Zs1%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbtcejU%2FbtsH1NY3FTz%2FAkzj4eA75MPZ7ltuzx8kl0%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbA2Acl%2FbtsH2r869J2%2FqS3t77EY3upAaD47U5Rkt0%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FxOLBj%2FbtsH1kiCYE8%2FmXiSYq7e0wIZgrYhwGlnC1%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FFhTp3%2FbtsH1oSMpYP%2FsWBe8dpfmegvOBM50tmiuk%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcBJhwn%2FbtsH3wO7SOf%2FobuK7IyF5sOvlnfVZxWBNK%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbcoG7s%2FbtsH2RsL9k4%2FlFr4FZD9oru9dN0ZbNle31%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FnzuPT%2FbtsH3Oa0QAJ%2FSK2FU5CpqPhievRTmEJOmk%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcar434%2FbtsH2WtXCth%2FfKCABms7PbvnNVctvIFjXk%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc7XpdN%2FbtsH3RS6rKz%2FGnpKxeEkf2hKZkYNOCXNiK%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdeKusM%2FbtsH2SFb9EU%2FOHApwTaWvlWAbzNEgA927K%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FnCeMO%2FbtsH2CimiIS%2FgpkQx5SreEYsFnEYzdhlC0%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdJrTDQ%2FbtsH2o5Dg6G%2FkuLTwQKB83h9ZagKxAoEG1%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpQHW8%2FbtsH15rJvFg%2FKQsQxrps5VGSxyde0zFJt0%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 로타리코리아 지구기자{'\n'}&nbsp;금암 전용우{'\n'}&nbsp;(천안남산 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiA;