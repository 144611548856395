import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const MainContents = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          {'\n'}<span style={{ fontSize: '20px' }}>서산가온 로타리클럽{'\n'}</span>
          <span style={{ fontWeight: 'bold' }}>서산 사랑의집 나눔회 집짓기</span>{'\n'}{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;5월 25일(토) 서산가온 로타리클럽은 서산사랑의집 나눔회 집짓기 현장(서산시 부석면)에 다녀왔다. 아직은 초반이라 우리가 할 수 있는 일이 많지는 않지만 현장 주변정리를 하기위해 방문했다.
          {'\n'}{'\n'}
        </Typography>

        <CardMedia
          className={classes.media3}
          image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FPq5LH%2FbtsH3QNYgvr%2FFDjXkYEzLBef27etS57upk%2Fimg.png'
          title="main3"
        />
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          {'\n'}
        </Typography>

        <CardMedia
          className={classes.media3}
          image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FoNhUN%2FbtsH4ndrHBD%2F4eOQzfL7ZxI27LNJ3zZhOk%2Fimg.png'
          title="main3"
        />
        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          {'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;집짓기 봉사는 현회기의 마지막과 차기의 시작을 함께하는 봉사로, 김은자 회장님과 박수경 차기회장님이 함께 참석했다. 방문 당일 외부 판넬작업을 하고 있어 현장 주변 정리와 함께 작은 힘이나마 도움을 주고 왔다.
          {'\n'}{'\n'}
        </Typography>

        
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FwGlAh%2FbtsH2UXOpd8%2FaEz86RPZMq9uB6PvhNHyYk%2Fimg.png'
            title="main3"
          />
          <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
            {'\n'}
          </Typography>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcovY54%2FbtsH2UjcyGr%2F28pk4xMSKaPk1e8FxwrCJ0%2Fimg.png'
            title="main3"
          />
          <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
            {'\n'}
          </Typography>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F2P4QQ%2FbtsH3x19IYE%2F0vbaTCG5uZiANbFqd0TYN0%2Fimg.png'
            title="main3"
          />

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          {'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 서산지역기자 이수섭{'\n'}&nbsp;(서산 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default MainContents;