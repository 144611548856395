import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const DonationC = () => {
  const classes = useStyles();
  const rawData = `
당진당나루RC 김정곤 100.00
당진송악RC 김만석 1.45
당진송악RC 김만석 100.00
당진송악RC 김민정 100.00
당진송악RC 김병수 100.00
당진송악RC 김병학 21.74
당진송악RC 김진용 36.23
당진송악RC 박승석 21.74
당진송악RC 박영환 1.45
당진송악RC 박영환 100.00
당진송악RC 성지용 1.45
당진송악RC 성지용 100.00
당진송악RC 신용제 1.45
당진송악RC 신용제 100.00
당진송악RC 윤건 1.45
당진송악RC 윤건 100.00
당진송악RC 윤형순 21.74
당진송악RC 임채연 48.61
당진송악RC 장용정 72.46
당진송악RC 정인희 21.74
당진송악RC 최종호 21.74
당진송악RC 한재석 1.45
당진송악RC 한재석 100.00
서산한서RC 김병식 100.00
서산한서RC 김종국 100.00
서산한서RC 신훈재 100.00
서산한서RC 이수현 100.00
서산한서RC 한성진 100.00
아산RC 강덕원 100.00
아산RC 곽노봉 100.00
아산RC 권철우 100.00
아산RC 김건기 100.00
아산RC 김기수 100.00
아산RC 김명관 100.00
아산RC 김상수 100.00
아산RC 김순고 200.00
아산RC 김은중 100.00
아산RC 김주호 200.00
아산RC 김진선 100.00
아산RC 김철규 300.00
아산RC 김한수 100.00
아산RC 김헌수 100.00
아산RC 박승우 100.00
아산RC 박한정 100.00
아산RC 박호순 100.00
아산RC 박희근 100.00
아산RC 방준오 100.00
아산RC 서한규 100.00
아산RC 신기훈 100.00
아산RC 심한주 300.00
아산RC 오창식 100.00
아산RC 윤규석 100.00
아산RC 윤영곤 100.00
아산RC 이병철 100.00
아산RC 이석일 100.00
아산RC 이석헌 100.00
아산RC 이선지 100.00
아산RC 이정열 100.00
아산RC 이정훈 100.00
아산RC 전주규 100.00
아산RC 정덕영 100.00
아산RC 정세영 100.00
아산RC 정제훈 100.00
아산RC 조은석 100.00
아산RC 최만영 100.00
아산RC 최성우 100.00
아산RC 한운택 100.00
아산RC 홍성혁 100.00
아산RC 홍순복 400.00
아산성웅RC 권오일 100.00
아산성웅RC 김광남 100.00
아산성웅RC 김상민 100.00
아산성웅RC 김세용 100.00
아산성웅RC 김영권 100.00
아산성웅RC 김종성 100.00
아산성웅RC 김종훈 100.00
아산성웅RC 김지성 21.74
아산성웅RC 김형석 21.74
아산성웅RC 박원순 100.00
아산성웅RC 박정우 100.00
아산성웅RC 박종성 100.00
아산성웅RC 박천규 100.00
아산성웅RC 서광원 100.00
아산성웅RC 송영식 100.00
아산성웅RC 신진섭 21.74
아산성웅RC 심규환 21.74
아산성웅RC 엄태준 100.00
아산성웅RC 우희찬 100.00
아산성웅RC 원종윤 100.00
아산성웅RC 이덕호 21.74
아산성웅RC 이성재 21.74
아산성웅RC 이정호 100.00
아산성웅RC 이황재 100.00
아산성웅RC 임재항 100.00
아산성웅RC 전상혁 100.00
아산성웅RC 정광수 100.00
아산성웅RC 정오영 100.00
아산성웅RC 조성인 100.00
아산성웅RC 하영욱 100.00
아산성웅RC 황재만 100.00
예산RC 김선용 25.36
예산RC 김영준 25.36
예산RC 박춘희 25.36
예산RC 원경모 25.36
천안남산RC 김기분 21.74
천안남산RC 김기춘 21.74
천안남산RC 김영진 21.74
천안남산RC 김철희 21.74
천안남산RC 박석용 21.74
천안남산RC 심상현 21.74
천안남산RC 안봉훈 21.74
천안남산RC 이승근 21.74
천안남산RC 임현묵 21.74
천안남산RC 정재경 21.74
천안남산RC 채수복 21.74
천안남산RC 한갑영 21.74
천안천지RC 김영주 100.00
천안천지RC 김영주 21.74
천안천지RC 박수혁 100.00
천안천지RC 박수혁 21.74
천안천지RC 송현웅 100.00
천안천지RC 송현웅 21.74
천안천지RC 윤위섭 100.00
천안천지RC 윤위섭 21.74
천안천지RC 이기승 100.00
천안천지RC 이기승 21.74
천안천지RC 최종찬 100.00
천안천지RC 최종찬 21.74
천안충남RC 윤보배 2.90
천안하늘RC 고락희 100.00
천안하늘RC 김현숙 200.00
천안하늘RC 조욱현 101.45
충서RC 김은호 100.00
충서RC 유병국 100.00
충서RC 정재영 100.00
태안한결RC 안해은 100.00
태안한결RC 안해은 26.50
홍성홍주RC 강동원 100.00
`;

  // Split and parse the raw data
  const data = rawData
    .trim()
    .split('\n')
    .map((line) => {
      const [category, donor, total] = line.split(' ');
      return { category, donor, total: parseFloat(total) };
    });

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
    <Grid item xs={12}>
          <Typography variant="h5" style={{textAlign: 'center', marginTop: '10px'}}>
            <strong>2023-24년도 &lt;6월&gt;<br/>RFSM, 폴리오기부자 명단</strong>
          </Typography>
        </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>클럽명</TableCell>
            <TableCell className={classes.cell}>회원명</TableCell>
            {/* <TableCell className={classes.cell}>기부실적</TableCell> */}
            <TableCell className={classes.cell}>기부($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.cell}>{row.category}</TableCell>
              <TableCell className={classes.cell}>{row.donor}</TableCell>
              {/* <TableCell className={classes.cell}>{row.pay}</TableCell> */}
              <TableCell className={classes.cell}>{row.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DonationC;
