// "short_name": "RI3620 총재월신",
// "name": "국제로타리 3620지구 총재월신",

// {
//   "hosting": {
//     "public": "build",
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ],
//     "rewrites": [{
//     "source": "**",
//     "destination": "/index.html"
//     }]
//   }
// }

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from '../../styles/header/headerStyle';
import { Link } from 'react-router-dom'; 
import { auth } from '../../firebase'; // Firebase 설정 import
import { onAuthStateChanged, signOut } from 'firebase/auth';

const logoUrl = 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcMZI1x%2Fbtss9BRwq2z%2FBP1x54Cxwvjd0KKWZO1C70%2Fimg.png';

const Header = () => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState({
    category1: true,
    category2: true,
    category3: true,
    category4: true,
  });
  const [user, setUser] = useState(null); // 사용자 상태 관리

  const handleMenuClick = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleCategoryClick = (category) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  // 로그인 상태 감지
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // 로그아웃 처리 함수
  const handleLogout = async () => {
    await signOut(auth);
  };

  const handleLogoutClose = () => {
    handleDrawerClose();
    handleLogout();
  }

  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" className={classes.menuTransition} style={{ backgroundColor: '#ffffff' }}>
        <Toolbar className={classes.root}>
          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <Typography variant="h6">
              <img
                src={logoUrl}
                alt="Your Logo"
                style={{ height: 'auto', width: '50%', verticalAlign: 'middle' }}
              />
            </Typography>
          </Link>
          <IconButton
            className={classes.menuButton}
            edge="end"
            aria-label="menu"
            onClick={handleMenuClick}
            style={{ color: '#000000' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <div style={{ width: '280px', borderBottom: '1px solid #ccc' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px', paddingRight: '5px' }}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <List>
            <List>
              {user ? (
                <>
                  <ListItem>
                    <Button variant="outlined" color="primary" fullWidth component={Link} to="/qr-code-page" onClick={handleDrawerClose}>
                      QR 출석하기
                    </Button>
                  </ListItem>
                  {/* <ListItem>
                    <Button variant="outlined" color="primary" fullWidth component={Link} to="/quiz" onClick={handleDrawerClose}>
                      3월호 퀴즈풀기
                    </Button>
                  </ListItem> */}
                  <ListItem>
                    <Button variant="outlined" color="secondary" fullWidth component={Link} to="/" onClick={handleLogoutClose} >
                      로그아웃
                    </Button>
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem>
                    <Button variant="outlined" color="primary" fullWidth component={Link} to="/signup" onClick={handleDrawerClose}>
                      회원가입
                    </Button>
                  </ListItem>
                  {/* <ListItem>
                    <Button variant="outlined" color="primary" fullWidth component={Link} to="/quiz" onClick={handleDrawerClose}>
                      3월호 퀴즈풀기
                    </Button>
                  </ListItem> */}
                  <ListItem>
                    <Button variant="outlined" color="secondary" fullWidth component={Link} to="/login" onClick={handleDrawerClose}>
                      로그인
                    </Button>
                  </ListItem>
                </>
              )}
            </List>

            <ListItem button onClick={() => handleCategoryClick('category1')}>
              <ListItemText onClick={handleDrawerClose}>
                <Link to="/purpose" style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>로타리의 목적 및 네 가지 표준</Link>
              </ListItemText>
            </ListItem>

            <ListItem button onClick={() => handleCategoryClick('category1')}>
              <ListItemText onClick={handleDrawerClose}>
                <Link to="/calendar" style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>지구월력</Link>
              </ListItemText>
            </ListItem>

            <ListItem button onClick={() => handleCategoryClick('category1')}>
              <ListItemText onClick={handleDrawerClose}>
                <Link to="/planlist" style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>총재동정</Link>
              </ListItemText>
            </ListItem>

            <ListItem style={{ marginTop: '-15px' }} button onClick={() => handleCategoryClick('category2')}>
              <ListItemText primary={<h3>메시지</h3>} />
              {openCategories.category2 ? '-' : '+'}
            </ListItem>
            {openCategories.category2 && (
              <List style={{ transition: 'max-height 0.3s ease-in-out', overflow: 'hidden', maxHeight: '500px', marginTop: '-30px' }}>
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/ri" style={{ textDecoration: 'none', color: 'black' }}>- RI회장 6월 메시지</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={handleDrawerClose}>
                <ListItemText>
                  <Link to="/3620" style={{ textDecoration: 'none', color: 'black' }}>- 해암 박홍전 총재 6월 메시지</Link>
                </ListItemText>
                </ListItem>
              </List>
            )}

            <ListItem style={{ marginTop: '-15px' }} button onClick={() => handleCategoryClick('category3')}>
              <ListItemText primary={<h3>지구공고(5월)</h3>} />
              {openCategories.category3 ? '-' : '+'}
            </ListItem>
            {openCategories.category3 && (
              <List style={{ transition: 'max-height 0.3s ease-in-out', overflow: 'hidden', maxHeight: '500px', marginTop: '-30px' }}>
                {/* <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice1" style={{ textDecoration: 'none', color: 'black' }}>- 총재공식방문 사진</Link>
                  </ListItemText>
                </ListItem> */}
                {/* <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice2" style={{ textDecoration: 'none', color: 'black' }}>- 신입회원현황</Link>
                  </ListItemText>
                </ListItem> */}
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice3" style={{ textDecoration: 'none', color: 'black' }}>- 재단기부현황</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice4" style={{ textDecoration: 'none', color: 'black' }}>- 3620지구 클럽현황</Link>
                  </ListItemText>
                </ListItem>
              </List>
            )}

            <ListItem style={{ marginTop: '-15px' }} button onClick={() => handleCategoryClick('category4')}>
              <ListItemText primary={<h3>지구공고(6월)</h3>} />
              {openCategories.category4 ? '-' : '+'}
            </ListItem>
            {openCategories.category4 && (
              <List style={{ transition: 'max-height 0.3s ease-in-out', overflow: 'hidden', maxHeight: '500px', marginTop: '-30px' }}>
                {/* <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice6" style={{ textDecoration: 'none', color: 'black' }}>- 신입회원현황</Link>
                  </ListItemText>
                </ListItem> */}
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice7" style={{ textDecoration: 'none', color: 'black' }}>- 재단기부현황</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemText>
                    <Link to="/notice8" style={{ textDecoration: 'none', color: 'black' }}>- 3620지구 클럽현황</Link>
                  </ListItemText>
                </ListItem>
              </List>
            )}

            <ListItem button onClick={() => handleCategoryClick('category1')}>
              <ListItemText onClick={handleDrawerClose}>
                <Link to="/training" style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>🧑🏻‍🏫 연수동영상</Link>
              </ListItemText>
            </ListItem>

            <ListItem button onClick={() => handleCategoryClick('category1')}>
              <ListItemText onClick={handleDrawerClose}>
                <Link to="/source" style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>🎵 로타리 의전음원</Link>
              </ListItemText>
            </ListItem>
          </List>
        </div>

        <ListItem>
          <Link to="https://rotary2406.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#EDA123',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 5월호<span style={{ fontSize: '20px' }}>태안지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary2405.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#194C86',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 4월호<span style={{ fontSize: '20px' }}>아산1지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary2404.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#941B4C',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 3월호<span style={{ fontSize: '20px' }}>당진지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary2403-9a1bd.web.app" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#EDA123',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 2월호<span style={{ fontSize: '20px' }}>천안4지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary2402-9a002.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#194C86',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 1월호<span style={{ fontSize: '20px' }}>천안1지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary2401.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#941B4C',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 12월호<span style={{ fontSize: '20px' }}>천안2지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary12-d40f2.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '14px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#EDA123',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 11월호<span style={{ fontSize: '20px' }}>아산2지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary11-2c5bc.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '13px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#194C86',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 10월호<span style={{ fontSize: '17px' }}>홍성∙청양지역편</span>
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          <Link to="https://rotary10.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '15px',
                    padding: '10px 5px',
                    fontWeight: 'bold',
                    backgroundColor: '#941B4C',
                    color: '#fff',
                    textDecoration: 'none',
                    display: 'block',
                    width: '250px',
                    textAlign: 'center',
                  }}
                >
                  2023-24년도 9월호<span style={{ fontSize: '20px' }}>천안3지역편</span>
                </Button>
            </Link>
          </ListItem>

        <ListItem>
          <Link to="https://rotary9-6b8e3.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '15px',
                    padding: '10px 5px',
                    fontWeight: 'bold',
                    backgroundColor: '#EDA123',
                    color: '#fff',
                    textDecoration: 'none',
                    display: 'block',
                    width: '250px',
                    textAlign: 'center',
                  }}
                >
                  2023-24년도 8월호<span style={{ fontSize: '20px' }}>예산지역편</span>
                </Button>
            </Link>
          </ListItem>

        <ListItem>
          <Link to="https://rotary-test-9c476.web.app/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
            <Button
              variant="contained"
              sx={{
                fontSize: '15px',
                padding: '10px 5px',
                fontWeight: 'bold',
                backgroundColor: '#194C86',
                color: '#fff',
                textDecoration: 'none',
                display: 'block',
                width: '250px',
                textAlign: 'center',
              }}
            >
              2023-24년도 7월호<span style={{ fontSize: '20px' }}>보령지역편</span>
            </Button>
          </Link>
        </ListItem>
      </Drawer>
    </div>
  );
}

export default Header;
