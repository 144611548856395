import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  {
    category: '천안 RC',
    name: '$100이상',
    donationAmount: '이정일, 고선희, 노수문, 이진우, 박성건, 정호동',
    finalCumulativeAmount: '$21,192.96',
  },
  {
    category: '천안 RC',
    name: '$1,000이상',
    donationAmount: '인태수,  최재용 $3,004, 오승화 $2,000, 김정해, 문영빈, 박순임, 우승한, 이종구, 함석영, 이영호, 전영권, 황보미정',
    finalCumulativeAmount: '$21,192.96',
  },
  {
    category: '천안 RC',
    name: 'GG, 폴리오',
    donationAmount: '김동우 $123, 김두희 $123, 오승화 $123, 원세환 $123, 이종곤 $123, 정인삼 123, 최재용 $123, 황의삼 $123, 고석주 $100, 고선희 $100, 고진동 $100, 김석희 $100, 김선미 $100, 김성환 $100, 김영중 $100, 김이교 $100, 김정해 $100, 김태호 $100, 김향숙 $100, 남궁윤 $100, 남세진 $100, 노수문 $100, 문영빈 $100, 박명순 $100, 박성건 $100, 박순영 $100, 박순임 $100, 박은수 $100, 방명숙 $100, 방연정 $100, 손영명 $100, 송영철 $100, 왕은정 $100, 우승한 $100, 유영찬 $100, 윤재선 $100, 이상익 $100, 이소영 $100, 이영호 $100, 이일연 $100, 이정일 $100, 이종구 $100, 이준영 $100, 이진우 $100, 인태수 $100, 전영권 $100, 전창복 $100, 정수영 $100, 최임순 $100, 한재선 $100, 함석영 $100, 허준혁 $100, 홍인정 $100, 황보미정 $100',
    finalCumulativeAmount: '$21,192.96',
  },
  {
    category: '천안삼거리 RC',
    name: '$100이상',
    donationAmount: '서용숙 $500, 조한정 $300, 박일현 $300, 김광섭 $200, 임용태 $200, 윤효진 $200, 김석원 $600, 도구훈, 이기태 $200, 방기원, 송범호, 박언섭 $200, 황진선 $200, 양태영 $300, 천안삼거리 RC $200, 최대현 $300, 김학봉, 김영세 $200, 김이항, 강동복, 강민재, 강영민 $200, 강의규, 고영삼, 구성욱, 김경수, 김경식, 김광섭, 김근선, 김병두, 김상국, 김선호, 김영직, 김영철, 김완수, 김용진, 김이항, 김제훈, 김준호, 김지철, 김진국, 김태희, 김학봉, 김현민, 노성원, 도구훈, 류두열, 류인희, 명노경, 박기정, 박상천, 박언섭, 박일용, 박일현, 박종식, 방기원, 백왕태, 서창석, 손병길, 손영주, 송범호, 신용희, 심창용, 안영동, 안찬열, 양병배, 양태영, 염기용, 유동근, 유병주, 유재룡, 윤광영, 윤일현, 윤종환, 윤효진, 이근형, 이기동, 이기태, 이기화, 이동석, 이상목, 이상복, 이석종, 이성철, 이성호, 이승한, 이승후, 이태운, 임기창, 임도운, 임선혁, 임용태, 장세현, 전영수, 정만진, 정성권, 정종호, 정필영, 조성재, 조한정, 조항린, 조호룡, 차명환, 채문병, 채용준, 최대현, 최대훈, 최영렬, 최우석, 하현영, 한도희, 함석중, 황정동, 황진선',
    finalCumulativeAmount: '$48,128,57',
  },
  {
    category: '천안삼거리 RC',
    name: '$1,000이상',
    donationAmount: '홍춘기 $4,100, 이상복 $1,300, 김태희 $1,100, 채문병 $2,100 강용갑 $1,100, 강현봉 $1,100, 박재홍 $1,100, 심희용 $1,100, 한대현 $1,100, 양윤섭 $1,100, 이용갑 $1,100, 정문관 $1,100, 주홍선 $1,100, 최남성 $1,100, 권재만 $1,100, 김현태 $1,100, 박항규 $1,100, 이동석 $1,200, 김종익 $1,100',
    finalCumulativeAmount: '$48,128,57',
  },
  {
    category: '천안삼거리 RC',
    name: 'BNF',
    donationAmount: '차명환 $5,000, 이성호 $1,000, 이기동 $1,000, 김영철',
    finalCumulativeAmount: '$48,128,57',
  },
  {
    category: '천안삼거리 RC',
    name: 'GG, 폴리오',
    donationAmount: '김태희 $25, 이용갑 $73, 채문병 $48, 조한정 $25, 김광섭 $25, 윤효진 $25, 방기원 $25, 김석원 $7, 송범호 $25, 강영민 $23, 박상천 $23, 심희용 $23, 양윤섭 $23, 이동석 $48, 이상복 $23, 이성호 $38, 이태운 $23, 전영수 $23, 채용준 $23, 함석중 $23, 홍춘기 $23, 김지철 $225',
    finalCumulativeAmount: '$48,128,57',
  },
  {
    category: '천안파로스 RC',
    name: '$100 이상',
    donationAmount: '강응순, 고명신, 길재화, 김경숙, 김금숙, 김윤호, 김윤희, 김은희, 김찬숙, 류은경, 박경숙, 박금자, 박종숙, 박주희, 박채연, 반은주, 배옥분, 백정은, 소경숙, 안기선, 오순석, 오은숙, 윤하윤, 이경숙, 이경자, 이영자, 이우열, 이은정, 이종민, 이주희, 장현서, 정덕순, 정지수, 조금숙, 조혜숙, 홍은주, 홍정희',
    finalCumulativeAmount: '$12,876.07',
  },
  {
    category: '천안파로스 RC',
    name: '$1,000 이상',
    donationAmount: '정은주 $1,200, 김정숙 $1,100, 김현숙 $1,100, 최공주 $1,100, 한재숙 $1,139',
    finalCumulativeAmount: '$12,876.07',
  },
  {
    category: '천안파로스 RC',
    name: 'GG, 폴리오',
    donationAmount: '이종민 $2,000, 정은주 $1,336, 백정은 $23, 윤하윤 $23, 이우열 $23, 정지수 $23, 백정은 $26, 윤하윤 $26, 이우열 $26, 정지수 $26',
    finalCumulativeAmount: '$12,876.07',
  },
  {
    category: '천안드림 RC',
    name: '$100이상',
    donationAmount: '이덕주, 이상철, 최영민',
    finalCumulativeAmount: '$300.00',
  },
  {
    category: '천안충남 RC',
    name: '$100이상',
    donationAmount: '김영찬, 전희열, 손동철, 이제철, 김태하, 신동현, 김상운, 서광호, 김석훈, 김한식, 박동성, 신태환, 장규식, 전희연',
    finalCumulativeAmount: '$11,176.67',
  },
  {
    category: '천안충남 RC',
    name: '$1,000이상',
    donationAmount: '양승화 $1,100, 안영태, 양지영, 윤보배, 윤재성, 정명수 $1,100',
    finalCumulativeAmount: '$11,176.67',
  },
  {
    category: '천안충남 RC',
    name: 'BNF',
    donationAmount: '안영태 $3,000',
    finalCumulativeAmount: '$11,176.67',
  },
  {
    category: '천안충남 RC',
    name: 'GG, 폴리오',
    donationAmount: '김금옥 $22, 김석훈 $22, 김영찬 $22, 박기호 $22, 박대선 $22, 박동성 $22, 박은경 $44, 서광호 $22, 안영태 $74, 양승화 $74, 양지영 $22, 유희원 $22, 윤은정 $22, 이민영 $22, 이석한 $22, 이충원 $22, 장규식 $22, 정명수 $22, 하영근 $22, 현재호 $22',
    finalCumulativeAmount: '$11,176.67',
  },
];

const data2 = [
  {
    category: '성환 RC',
    name: '$100',
    donationAmount: '양종철 $600, 정길영',
    finalCumulativeAmount: '$707.75',
  },
  {
    category: '성환 RC',
    name: 'GG, 폴리오',
    donationAmount: '백두현 $7',
    finalCumulativeAmount: '$707.75',
  },
  {
    category: '서천안 RC',
    name: '$100이상',
    donationAmount: '구자형, 권태수, 김광겸, 김현수 $300, 동인규, 류성훈 $500, 박상구 $300, 김동우, 김보철 $200, 배영후, 부영태 $500, 신민수, 오동경, 유주원 $400, 윤세민 $500, 장균택 $300,  한창목, 류성훈, 김수한 $300, 유성열 $200,송하준, 안동준 $400, 유준오, 유택상 $300, 이무성,  김태명, 김태환, 현영주, 고경환 $200, 박윤재, 최길호,최재호, 문봉일, 김민철, 김성용, 김완수, 김창길 $600, 남석호 $600, 박종일, 복원순, 염은형, 유정하, 최진근, 이성우 $300, 이승룡, 이재훈, 이종기, 이창주, 임영갑, 전대규, 전용길, 최진열 $200, 홍성로, 홍종갑 황규철, 한신, 김명환, 김성호, 이상진 $200, 김경환, 김근보, 김만석, 김명환, 김민철 $200, 김병대, 김봉석, 김성윤, 김영근, 황광현, 황민승, 김응규, 김일근, 김정석, 김종기, 김태환1, 남양희, 이길상, 이응필, 정우영, 조홍래, 진정현 $600',
    finalCumulativeAmount: '$84,276.64',
  },
  {
    category: '서천안 RC',
    name: '$1,000이상',
    donationAmount: '권안석 $9,200, 신정섭 $2,100, 김경도 $4,800, 도회남 $2,200, 김정호 $2,500, 나윤찬 $1,100, 배우진 $1,400, 박노봉 $2,100, 전상범 $2,100, 정관호 $5,700, 구인교 $1,200, 김종일 $1,100, 이재범 $5,300, 이호배 $1,100, 황현승 $2,300, 강선일 $2,100, 김성기 $1,500, 송영호 $1,100, 이상훈 $1,100, 김재유 $1,100, 박건수 $1,300, 윤승현 $1,100, 이성재 $1,300, 이승룡 $1,100, 이재근 $1,100, 주문종 $3,300, 윤유현 $1,100',
    finalCumulativeAmount: '$84,276.64',
  },
  {
    category: '서천안 RC',
    name: 'GG, 폴리오',
    donationAmount: '구자형 $1,050, 권안석 $1,073, 권태수 $1,073, 박노봉 $1,050, 박상구 $1,073, 신정섭 $1,073, 황현승 $1,130, 김경도 $562, 김정호 $23, 김태명 $23, 박건수 $23, 부영태 $23, 유택상 $23, 구인교 $50, 이성우 $23, 이재범 $73, 정관호 $60, 주문종 $23, 황민승 $23, 나윤찬 $7, 윤유현 $7, 김종일 $50, 이호배 $50',
    finalCumulativeAmount: '$84,276.64',
  },
  {
    category: '남천안 RC',
    name: 'PHF',
    donationAmount: '김민규 $2,000, 강봉규, 길이기, 김정훈, 서정호',
    finalCumulativeAmount: '$6,102.23',
  },
  {
    category: '남천안 RC',
    name: 'GG, 폴리오',
    donationAmount: '김석우 $23, 김광태 $23, 김정훈 $23, 박준영 $23, 위성우 $7',
    finalCumulativeAmount: '$6,102.23',
  },
  {
    category: '천안하늘 RC',
    name: '$100 이상',
    donationAmount: '공  훈, 김태중, 김현숙, 서문성 $300, 신현국, 유병수, 조성호, 하외란, 김상진 $200, 김은옥, 고락희, 김현숙 $200',
    finalCumulativeAmount: '$9,936.14',
  },
  {
    category: '천안하늘 RC',
    name: '$1,000 이상',
    donationAmount: '유영진, 정민웅, 정산은, 조미선, 함상철, 조욱현 $1,202, 권민경, 홍진옥',
    finalCumulativeAmount: '$9,936.14',
  },
  {
    category: '천안하늘 RC',
    name: 'GG, 폴리오',
    donationAmount: '권도운 $23, 김상진 $23, 조욱현 $61, 최백운 $23',
    finalCumulativeAmount: '$9,936.14',
  },
  {
    category: '천안천룡 RC',
    name: '$100 이상',
    donationAmount: '김용관 $300, 변창선 $300, 정정순 $200, 강성종, 구본규, 구한서, 권기동, 김성준, 김용환, 명성호, 박동수, 심규남, 오종환, 오  현, 이상열, 이상현, 이용해, 이재진, 이재학, 이태영, 조금철, 조선호, 최경진, 최민준, 홍기웅',
    finalCumulativeAmount: '$21,041.48', 
  },
  {
    category: '천안천룡 RC',
    name: '$1,000 이상',
    donationAmount: '유호민 $3,100, 홍용민 $3,100, 박상민 $1,100, 박정규 $1,100, 오홍균 $1,100, 윤용훈 $1,100, 이용철 $1,100, 정상원 $1,100, 조성희 $3,400, 홍동표 $1,100',
    finalCumulativeAmount: '$21,041.48',
  },
  {
    category: '천안천룡 RC',
    name: 'GG, 폴리오',
    donationAmount: '오홍균 $23, 유호민 $30, 이태영 $23, 정상원 $288, 조성희 $326, 홍동표 $23, 변창선 $15, 조선호 $7',
    finalCumulativeAmount: '$21,041.48',
  },
  {
    category: '천안백두 RC',
    name: '$100 이상',
    donationAmount: '김대식, 박경흠, 윤광석, 이흥구, 정우택, 최종곽, 김완호, 안덕남, 이근복, 최성희, 탁현국, 김태훈',
    finalCumulativeAmount: '$5,607.75',
  },
  {
    category: '천안백두 RC',
    name: '$1,000 이상',
    donationAmount: '김재남 $1,100, 윤길병 $1,100, 주대명 $1,100, 김기택 $1,100',
    finalCumulativeAmount: '$5,607.75',
  },
  {
    category: '천안백두 RC',
    name: 'GG, 폴리오',
    donationAmount: '김기택 $7',
    finalCumulativeAmount: '$5,607.75',
  },
  {
    category: '천안쌍봉 RC',
    name: '$100 이상',
    donationAmount: '권기용, 김종규, 김청원, 도민욱, 성용규, 송태환, 윤동진, 이강호, 이광철, 이순민, 이진성, 김선웅, 전병훈, 정성종, 한상우, 한인돈, 함노태, 김효중 $200, 박주철, 방대희, 송영재, 오우택, 온경일, 윤동진, 윤석희, 이광철, 이병희, 이수한, 이용태, 이종대, 임환영, 조범규, 조진섭, 최승호, 최영남, 한석원, 함노태, 홍승표, 조현진, 최혁락, 하태갑, 현명주, 김성호, 박종해, 손성춘, 이재우, 이택규, 임현오, 전유진, 최광용, 황재순',
    finalCumulativeAmount: '$20,404.46',
  },
  {
    category: '천안쌍봉 RC',
    name: '$1,000 이상',
    donationAmount: '신상우 $1,100, 안진용 $1,100, 이근옥 $3,100, 이근원 $1,100, 김용태, 안태홍 $1,100, 이경호, 이득우 $1,100, 김성규 $1,100, 이종혁 $1,100, 이춘식 $3,100',
    finalCumulativeAmount: '$20,404.46',
  },
  {
    category: '천안쌍봉 RC',
    name: 'GG, 폴리오',
    donationAmount: '방대희 $23, 신상우 $23, 안태홍 $23, 이경호 $23, 이득우 $23, 이종혁 $23, 이춘식 $23, 임환영 $23, 안태홍 $7, 오우택 $7',
    finalCumulativeAmount: '$20,404.46',
  },
  {
    category: '천안새총무 RC',
    name: '$100 이상',
    donationAmount: '강진호, 김은진, 도민수, 박성기 $300, 박정식 $200, 방승철, 백종우, 신병섭, 신은경 $300, 안태현 $200, 아오키노리꼬, 우보영, 이명자, 이수원, 이인희, 이흥욱, 임재길, 임희정, 조광옥, 고기건, 신광현 $200',
    finalCumulativeAmount: '$10,078.36',
  },
  {
    category: '천안새총무 RC',
    name: '$1,000 이상',
    donationAmount: '신은경 $2,000, 최미숙, 권영배, 박정석, 송길영, 박하식',
    finalCumulativeAmount: '$10,078.36',
  },
  {
    category: '천안새총무 RC',
    name: 'GG, 폴리오',
    donationAmount: '강진호 $23, 송길영 $23, 신광현 $23, 안태현 $23, 이수원 $23, 이현서 $23, 이흥욱 $23, 임재길 $23, 이수원 $22, 신은경 $66',
    finalCumulativeAmount: '$10,078.36',
  },
];

const data3 = [
  {
    category: '새천안 RC',
    name: '$100 이상',
    donationAmount: '박순호, 윤규희, 장석강, 장인희 $300, 정진명',
    finalCumulativeAmount: '$5,037.11',
  },
  {
    category: '새천안 RC',
    name: '$1,000 이상',
    donationAmount: '김석화, 유영동, 김영목, 유경환',
    finalCumulativeAmount: '$5,037.11',
  },
  {
    category: '새천안 RC',
    name: 'GG, 폴리오',
    donationAmount: '김도원 $23, 김성봉 $23, 김현용 $23, 윤규희 $23, 임종수 $23, 전종영 $23, 정민구 $23, 한윤희 $23, 천안여자상업고등학교 $148',
    finalCumulativeAmount: '$5,037.11',
  },
  {
    category: '천안능수 RC',
    name: '$100 이상',
    donationAmount: '강미정, 구경희, 김미라, 김은진, 김태용, 노혜원, 박명옥, 박미선, 안미영, 유슌이, 윤경희, 이상미, 김현미, 이수진, 이승순, 이진성, 임선화, 장복순, 정미옥, 정세희 $200, 정은서 $200, 정희자, 최명순, 김현숙, 최영미, 현은정, 현진옥 $600, 홍성남, 최지호, 최은미, 최혜숙, 추예령',
    finalCumulativeAmount: '$19,600.79',
  },
  {
    category: '천안능수 RC',
    name: '$1,000 이상',
    donationAmount: '김미영 $2,200, 박미화 $1,100, 이미숙 $1,200, 이서영 $1,100, 임화정 $1,100, 전용자 $3,100, 정지숙 $1,100, 조미진 $1,100, 홍승남',
    finalCumulativeAmount: '$19,600.79',
  },
  {
    category: '천안능수 RC',
    name: 'BNF',
    donationAmount: '강미정 $1,000',
    finalCumulativeAmount: '$19,600.79',
  },
  {
    category: '천안능수 RC',
    name: 'GG, 폴리오',
    donationAmount: '강미정 $27, 구경희 $27, 김미라 $27, 김미영 $104, 김은진 $27, 김태용 $27, 노혜원 $27, 김현미 $22, 박명옥 $51, 박미선 $27 빅마화 $88, 안미영 $27, 유순이 $27, 윤경희 $27, 이미숙 $88, 김현숙 $22, 이상미 $51, 이서영 $65, 이수진 $27, 이승순 $34, 이진성 $27, 임선화 $27, 임화정 $72, 최은미 $22, 장복순 $27, 전용자 $147, 정미옥 $51, 정세희 $31, 정은서 $31, 정지숙 $112, 정희자 $27, 최혜숙 $22, 조미진 $88, 최명순 $27, 최영미 $27, 현은정 $27, 현진옥 $46, 홍성남 $27, 최지호 $22',
    finalCumulativeAmount: '$19,600.79',
  },
  {
    category: '천안도솔 RC',
    name: '$100이상',
    donationAmount: '강명석 $300, 강창훈, 강희영, 구교찬, 길호진 $300, 김관수 $300, 김동민 $600, 김동후, 김민수, 김상성, 김성준, 김세한, 김영한 $600, 김용식, 김우성 $300, 김제명, 김종식 $200, 김주성, 김지완, 김태성, 김판중, 류승형, 문은수, 박상훈, 박준원, 방승도, 백종선 $200, 서민교, 선우석 $600, 손병민, 송낙현, 송창석, 송해전 $200, 박영순 $300, 신상섭, 신승범, 신진우, 안경득, 안용진 $400, 양철선, 오만수, 오승민, 왕성근, 유병조, 유재영, 유재필, 유정곤, 유해용, 윤승제, 이동구, 이명의 $200, 이문배, 이상철, 이성인, 이승준 $200, 이시석 $300, 남궁근, 이영우 $473, 이영원, 이영집 $300, 이은상, 이응덕, 이정목, 이정문, 이종문, 이주영, 이주훈, 이화수,장명식, 전은태, 정진양, 정철기 , 조남현, 조선형, 진현주, 차재국, 채수훈, 채원태, 최경돈, 최병권, 한상만 $600, 한상열, 한지영 $300, 함석문 $300, 홍종철 $400, 황일면, 임도형 $300',
    finalCumulativeAmount: '$61,767.48',
  },
  {
    category: '천안도솔 RC',
    name: 'BNF',
    donationAmount: '강명석 $300, 강창훈, 강희영, 구교찬, 길호진 $300, 김관수 $300, 김동민 $600, 김동후, 김민수, 김상성, 김성준, 김세한, 김영한 $600, 김용식, 김우성 $300, 김제명, 김종식 $200, 김주성, 김지완, 김태성, 김판중, 류승형, 문은수, 박상훈, 박준원, 방승도, 백종선 $200, 서민교, 선우석 $600, 손병민, 송낙현, 송창석, 송해전 $200, 박영순 $300, 신상섭, 신승범, 신진우, 안경득, 안용진 $400, 양철선, 오만수, 오승민, 왕성근, 유병조, 유재영, 유재필, 유정곤, 유해용, 윤승제, 이동구, 이명의 $200, 이문배, 이상철, 이성인, 이승준 $200, 이시석 $300, 남궁근, 이영우 $473, 이영원, 이영집 $300, 이은상, 이응덕, 이정목, 이정문, 이종문, 이주영, 이주훈, 이화수,장명식, 전은태, 정진양, 정철기 , 조남현, 조선형, 진현주, 차재국, 채수훈, 채원태, 최경돈, 최병권, 한상만 $600, 한상열, 한지영 $300, 함석문 $300, 홍종철 $400, 황일면, 임도형 $300',
    finalCumulativeAmount: '$61,767.48',
  },
  {
    category: '천안도솔 RC',
    name: 'GG, 폴리오',
    donationAmount: '문은수 $5,000, 강희영 $23, 김상성 $2,123, 박준원 $23',
    finalCumulativeAmount: '$61,767.48',
  },
  {
    category: '천안동남 RC',
    name: '$100이상',
    donationAmount: '김원택 $600, 유영길 $200, 최동민 $200, 최영기 $200, 한영수 $200, 한종인 $200, 현충환 $200, 강태희 $200, 강환봉 $200, 권오창 $200, 김민구 $200, 김병군 $200, 김성환 $200, 김원택 200, 노형규 $200, 박상준 $200, 배대웅 $200, 양현석 $200, 오세영 $200, 오준경 $200, 이수진 $200, 이용규 $200, 이용연 $200, 이준의 $200, 이채섭 $200, 이천우 $200, 임정묵 $200, 장민성 $200, 전재만 $200, 정경문 $200, 조태현 $200, 진희식 $200, 한상선 $200, 황동식 $200, 구기성, 김남섭, 김영학, 김창덕 $200, 문춘주 $200, 박대순, 송정현 $200, 유진호',
    finalCumulativeAmount: '$35,353.77',
  },
  {
    category: '천안동남 RC',
    name: '$1,000이상',
    donationAmount: '권복주 $1,200, 김영욱 $1,200, 김진식 $1,200, 방길춘 $1,200, 신태규 $2,200, 양성모 $1,200, 안정현, 최동민, 유일선 $2,200, 이경표 $1,200, 이상룡, 이상범 $1,200, 정윤성 $1,200, 박상수, 이남일 $3,000, 이정석 $1,100, 김충섭, 한상선, 현우주 $1,200',
    finalCumulativeAmount: '$35,353.77',
  },
  {
    category: '천안동남 RC',
    name: 'GG, 폴리오',
    donationAmount: '권복주 $23, 김영욱 $23, 김원택 $47, 김진식 $47, 박상수 $23, 방길춘 $23, 신태규 $23, 이용연 $23, 안정현 $23, 양성모 $23, 유영길 $23, 유일선 $47, 이경표 $23, 이상룡 $23, 이상범 $23, 이용규 $23, 정윤성 $23, 최동민 $23, 최영기 $23, 한영수 $23, 한종인 $23, 현충환 $23, 강태희 $23, 강환봉 $23, 권오창 $23, 김민구 $23, 김병군 $23, 김선복 $23, 김성환 $23, 김원택 $23, 노형규 $23, 박상준 $23, 배대웅 $23, 양현석 $23, 오세영 $23, 오준경 $23, 이수진 $23, 이용규 $23, 이용연 $23, 이준의 $23, 이채섭 $23, 이천우 $23, 임정묵 $23, 장민성 $23, 전재만 $23, 정경문 $23, 조태현 $23, 진희식 $23, 한상선 $200, 황동식 $23, 김영학 $22, 문춘주 $22, 박재규 $22, 송정현 $22, 현우주 $22',
    finalCumulativeAmount: '$35,353.77',
  },
  {
    category: '천안상록 RC',
    name: '$100 이상',
    donationAmount: '"강명란 $600, 이대원 $600, 김숙자 $400, 신현미 $300. 강민서, 구효경, 김미형, 김민경, 김애라, 김영아, 김영옥, 김은정, 김인경, 김지숙, 김채원, 노경희, 박경선, 박정숙, 박찬임, 방미일, 배은영, 송민정, 신하나, 심연우, 안정민 $300, 우연주, 유경자, 윤혜경, 이서연, 이예린 $200, 이예진, 이정희, 이지영, 이진옥, 이화숙, 전진선, 조윤희, 홍양숙',
    finalCumulativeAmount: '$26,022.38',
  },
  {
    category: '천안상록 RC',
    name: '$1,000 이상',
    donationAmount: '김시유 $1,200, 이은조 $2,223, 김리원 $1,123, 김명희 $2,100, 김선경 $1,100, 손명기 $1,100, 이영애 $1,100, 장진원 $1,100, 김명순 $1,100, 김진경',
    finalCumulativeAmount: '$26,022.38',
  },
  {
    category: '천안상록 RC',
    name: 'GG, 폴리오',
    donationAmount: '강민서 $52, 구효경 $52, 김리원 $52, 김명순 $52, 김미형 $82, 김애라 $52, 김영아 $52, 김인경 $1,052, 김진경 $52, 김채원 $52, 노경희 $52, 박경선 $52, 박정숙 $52, 방미일 $1,052, 배은영 $30, 손명기 $52, 송민정 $52, 신현미 $52, 안정민 $30, 유경자 $52, 이대원 $52, 이영애 $52, 이예린 $1,052, 이은조 $59, 이정희 $52, 이진옥 $52, 이화숙 $52, 장진원 $52, 전진선 $52, 조윤희 $52, 김선경 $29,김명희 $1,022, 강명란 $45, 김채원 $23, 박찬임 $23, 윤혜경 $23, 김민경 $22, 김숙자 $22, 김시유 $22, 김은정 $22, 김지숙 $22, 박찬임 $22, 신하나 $22, 심연우 $22, 이서연 $22, 이예진 $22, 이지영 $22, 천안상록  RC $250, 홍양숙 $22',
    finalCumulativeAmount: '$26,022.38',
  },
  {
    category: '천안천지 RC',
    name: '$100 이상',
    donationAmount: '공병혁, 권성학, 김노석,  김대기, 김영삼, 김희성, 노현만, 여인상, 유만근, 이선환, 이용석, 김영주, 박수혁, 전지현, 정이래, 한성희, 송현웅, 윤위섭, 이기승, 최종찬',
    finalCumulativeAmount: '$7,027.94',
  },
  {
    category: '천안천지 RC',
    name: '$1,000 이상',
    donationAmount: '문상철 $1,100, 김현용 $1,100, 정선희 $1,100, 전영배 $1,100',
    finalCumulativeAmount: '$7,027.94',
  },
  {
    category: '천안천지 RC',
    name: 'GG, 폴리오',
    donationAmount: '김대기 $23, 전영배 $45, 정선희 $45, 한성희 $45, 공병혁 $22, 권성학 $22, 김노석 $22, 김대기 $22, 김영삼 $22, 김형용 $22, 김희성 $22, 노현만 $22, 문상철 $22, 여인상 $22, 유만근 $22, 이선환 $22, 이용석 $22, 전지현 $22, 정일재 $22, 김영주 $21, 박수혁 $21, 송현웅 $21, 윤위섭 $21, 이기승 $21, 최종찬 $21',
    finalCumulativeAmount: '$7,027.94',
  },
  {
    category: '천안희망 RC',
    name: '$100이상',
    donationAmount: '곽동신, 곽수호, 김교태, 김귀영, 김기덕, 김상필, 김완두, 김재구, 김재섭, 김학진, 박정수, 송원식, 신용배, 신현복, 안병관, 양세직, 오규훈, 오영근, 이기봉, 이동준, 이래만, 이병태, 이상준, 이영찬, 이응렬, 이정길, 이호열, 최광호, 추대식, 홍선희, 황인철',
    finalCumulativeAmount: '$12,755.93',
  },
  {
    category: '천안희망 RC',
    name: '$1,000이상',
    donationAmount: '권봉석 $1,100, 김영환 $1,100, 김창기 $1,100, 이우철 $5,100',
    finalCumulativeAmount: '$12,755.93',
  },
  {
    category: '천안희망 RC',
    name: 'GG, 폴리오',
    donationAmount: '곽동신 $27, 곽수호 $27,권봉석 $66, 김교태 $27, 김귀영 $27, 김기덕 $51, 김상필 $51, 김영환 $90, 김완두 $27, 김재구 $27, 김재섭 $27, 김창기 $66, 김학진 $27, 박정수 $27, 송원식 $27, 신용배 $27, 신현복 $27, 안병관 $27, 양세직 $27, 오규훈 $51, 오영근 $27, 이기봉 $27, 이동준 $27, 이래만 $27, 이병태 $27, 이상준 $27, 이영찬 $27, 이우철 $106, 이응렬 $27, 이정길 $27, 이호열 $27, 최광호 $27, 추대식 $27, 홍선희 $27, 황인철 $27',
    finalCumulativeAmount: '$12,755.93',
  },
  {
    category: '천안리더스 RC',
    name: 'GG, 폴리오',
    donationAmount: '김우진 $100, 김은희 $100, 김인희 $100, 신예서 $100, 여상연 $100, 원유경 $100, 이경순 $100, 최숭미 $100, 한영미 $100, 한은하 $100, 황미현 $100, 황순덕 $100',
    finalCumulativeAmount: '$1,200.00',
  },
  {
    category: '천안동호 RC',
    name: '$100 이상',
    donationAmount: '김도윤, 김민기, 김병석, 김보성, 김영운, 김지식, 김하중, 김  휘, 노규현, 맹정열, 문두호, 박상태, 박준규, 박희정, 성태기, 신경진, 심호준, 안영태, 유성현, 이계진, 이국희, 이용재, 이원재, 이은구, 이은필, 이현노, 임창묵, 장재식, 전병락, 전성민, 조대현, 조병규, 지은열, 최광민, 최홍윤, 함용식, 황규원, 황두용, 황명환',
    finalCumulativeAmount: '$40,740.99',
  },
  {
    category: '천안동호 RC',
    name: '$1,000 이상',
    donationAmount: '김경주 $1,100, 김대헌 $1,123, 김순중 $1,100, 나기영 $1,100, 김욱중 $1,100, 배윤섭 $1,100, 이승배 $1,100, 안광희 $1,100, 이상원 $1,100, 이우진 $1,100, 이호진 $1,100, 임수현 $1,100, 임승환 $2,100, 조용운 $1,100, 임태수 $1,100, 정낙봉 $1,100, 최재형 $1,100, 허성회 $1,100, 홍은표 $1,100, 백병석 $1,100, 최기수 $1,100',
    finalCumulativeAmount: '$40,740.99',
  },
  {
    category: '천안동호 RC',
    name: 'GG, 폴리오',
    donationAmount: '임승환 $38, 홍은표 $7, 김도윤 $1,000, 김민기 $1,000, 심호준 $1,000, 유성현 $1,000, 이승배 $1,000, 임창묵 $1,000, 장재식 $1,000, 조용운 $3,000, 지은열 $1,000, 천안동호 RC $600, 황명환 $1,000, 김하중 $23, 김   휘 $23, 심호준 $23',
    finalCumulativeAmount: '$40,740.99',
  },
  {
    category: '천안버들 RC',
    name: '$100 이상',
    donationAmount: '고경윤, 김민호, 김석준, 문동윤, 서봉진, 손봉관, 윤주헌, 이성우, 이승종, 이시헌, 이일희, 임정수, 정원욱, 정재철, 함인창, 허승호',
    finalCumulativeAmount: '$5,600.00',
  },
  {
    category: '천안버들 RC',
    name: '$1,000 이상',
    donationAmount: '손봉관 $1,100, 이성우 $1,100, 정원욱 $1,100, 이시헌',
    finalCumulativeAmount: '$5,600.00',
  },
];

const data4 = [
  {
    category: '천안중앙 RC',
    name: '$100이상',
    donationAmount: '한미향, 강익현',
    finalCumulativeAmount: '$5,057.22',
  },
  {
    category: '천안중앙 RC',
    name: '$1,000이상',
    donationAmount: '박민서, 조동기, 정경온, 이상무, 김해성, 박민서, 백주은, 신현라, 윤진원, 이광호, 정세미',
    finalCumulativeAmount: '$5,057.22',
  },
  {
    category: '천안중앙 RC',
    name: 'GG, 폴리오',
    donationAmount: '배영환 $23, 김해성 $23, 이상무 $38, 송명규 $23, 정경온 $23, 황평식 $23',
    finalCumulativeAmount: '$5,057.22',
  },
  {
    category: '천안목주 RC',
    name: '$100이상',
    donationAmount: '이병열 $300, 곽노훈, 권세완, 김대교, 김성기 $300, 김수환, 김학수, 박우람, 박재영, 변경빈 $200, 김평기, 김한성, 김형규 $300, 라윤석 $300, 박만수, 박영복, 박영순, 박주혁 $300, 박찬운, 박홍규, 성참모, 손제원, 배진형, 안승돈, 유주현, 윤두형, 윤  호, 이구성, 이근정, 이병학, 이영수, 이우정, 이우현 $300, 이일석, 이정희, 서재성 $300, 이진용, 정병수 $400, 정진욱, 신동욱, 이형준, 황호근, 김홍균, 신도현 $300, 원종욱, 김용근, 김용수 $200, 문현수, 양진호 $400, 성창모 $200, 유주현 $200',
    finalCumulativeAmount: '$61,160.47',
  },
  {
    category: '천안목주 RC',
    name: '$1,000이상',
    donationAmount: '가우현 $1,300, 김  구 $10,400, 김범수 $1,300, 박관영 $2,300, 양병훈 $1,300, 원영민 $2,300, 이계만 $1,100, 이기석 $2,300, 정환우 $4,600, 이일석, 이홍구, 고영택 $1,600, 최부영 $1,400, 황정훈 $1,100, 김선길 $2,100, 김동윤 $1,100, 김영성 $1,100, 김웅태 $2,100, 문동기 $1,100, 윤호 $3,100, 이호철 $1,400, 이홍구 $1,100, 정중근 $1,300, 한만선 $1,500',
    finalCumulativeAmount: '$61,160.47',
  },
  {
    category: '천안목주 RC',
    name: 'GG, 폴리오',
    donationAmount: '이병열 $1,023, 김  구 $150, 박관영 $148, 정환우 $132,  박영복 $25, 이근정 $125, 김영성 $23, 원영민 $23, 이병학 $23, 이영수 $23, 이호철 $23, 황정훈 $23, 김선길 $100, 김성기 $25, 김웅태 $100, 김형규 $100, 문동기 $25, , 박주혁 $25, 박홍규 $100, 손제원 $100, 양병훈 $100, 원영민 $100, 유주현 $125, 이기석 $125, 이우현 $100, 이일석 $125, 이진용 $100, 이호철 $100, 정병수 $100, 최부영 $100, 한만선 $100, 고영택 $38,  고영택 $25',
    finalCumulativeAmount: '$61,160.47',
  },
  {
    category: '천안일봉 RC',
    name: '$100이상',
    donationAmount: '박병수 $300, 남기남 $200, 배경희 $200, 김동길, 김홍표, 박찬수, 변상민, 엄기탁, 여준구, 유영일, 이권희, 임원희, 정진모, 홍웅기',
    finalCumulativeAmount: '$17,774.17',
  },
  {
    category: '천안일봉 RC',
    name: '$1,000이상',
    donationAmount: '박건순 $3,021, 김남식 $1,100, 김재곤 $1,100, 백  찬 $1,100, 장순백 $1,100, 장창수 $1,100, 최동석, 고춘식, 김은동, 김인식, 남택선, 이병선, 이  철',
    finalCumulativeAmount: '$17,774.17',
  },
  {
    category: '천안일봉 RC',
    name: 'GG, 폴리오',
    donationAmount: '김재곤 $23, 박건순 $61, 박찬수 $23, 여준구 $23, 이병선 $23, 장순백 $23, 장창수 $23, 정진모 $23, 고춘식 $38, 김상용 $26, 김현수 $26,  배경희 $26, 유건호 $26, 이원택 $26, 이희송 $26, 허권행 $26',
    finalCumulativeAmount: '$17,774.17',
  },
  {
    category: '천안남산 RC',
    name: '$100이상',
    donationAmount: '강원석 $200, 김기분, 김기춘, 김영진, 김인섭, 김철희, 박석용, 신홍재, 심상현 안봉훈, 유준석, 이남성, 이승근, 이현식, 임기현, 임성묵, 임현묵, 전용석, 정의광, 정재경, 정지해, 채수복, 한갑영',
    finalCumulativeAmount: '$12,487.80',
  },
  {
    category: '천안남산 RC',
    name: '$1,000이상',
    donationAmount: '김정기 $1,500, 김철호 $1,400, 나종운 $1,100, 송대웅 $1,100, 이인기 $1,100, 전용수 $1,300, 전용우 $1,300',
    finalCumulativeAmount: '$12,487.80',
  },
  {
    category: '천안남산 RC',
    name: 'GG, 폴리오',
    donationAmount: '이현식 $25, 정의광 $50, 전용우 $63, 강원석 $112, 김기춘 $63, 김정기 $75, 김철호 $40, 나종운 $40, 송대웅 $40, 신홍재 $78, 유준석$40, 이인기 $40, 이현식 $47, 임성묵 $75, 전용석 $40, 전용수 $65, 전용우 $24, 정의광 $40, 정지해 $24, 이남성 $37, 김기분 $21, 김기춘 $21, 김영진 $21, 김철희 $21, 박석용 $21, 심상현 $21, 안봉훈 $21, 이승근$21, 임현묵 $21, 정재경 $21, 채수복 $21, 한갑영 $21',
    finalCumulativeAmount: '$12,487.80',
  },
  {
    category: '천안서북 RC',
    name: 'GG, 폴리오',
    donationAmount: '강완식 $26, 권영진 $26, 김성중 $26, 김준영 $26, 박명수 $26, 변상문 $26, 안종부 $26, 유재석 $26, 이승선 $26, 이준열 $26, 전수학 $26, 조현준 $26',
    finalCumulativeAmount: '$320.04',
  },
  {
    category: '천안아우내 RC',
    name: '$100 이상',
    donationAmount: '김명숙, 김선옥, 김세민, 김정주, 김행미, 단영숙, 류숙자, 문재복, 박해정, 반선희, 양은심,연은혜, 차정희, 이순애, 이정미, 이종란, 장석분, 장숙경, 조영숙 $200, 최다연, 최현숙',
    finalCumulativeAmount: '$4,400.00',
  },
  {
    category: '천안아우내 RC',
    name: '$1,000 이상',
    donationAmount: '박영숙 $1,100, 송춘영 $1,100',
    finalCumulativeAmount: '$4,400.00',
  },
  {
    category: '천안무궁화 RC',
    name: '$100이상',
    donationAmount: '김명주, 송옥자, 양명희, 전서이 $200, 전주연, 함현미, 황애리, 황연경',
    finalCumulativeAmount: '$1,102.78',
  },
  {
    category: '천안무궁화 RC',
    name: 'GG, 폴리오',
    donationAmount: '김선자 $23, 이미용 $23, 강지혜 $22, 김선자 $22, 송미정 $22, 신회숙 $22, 심귀희 $22, 이미옥 $22, 이미용 $22',
    finalCumulativeAmount: '$1,102.78',
  },
  {
    category: '천안다온 RC',
    name: '$100 이상',
    donationAmount: '김민수, 윤지희, 이승희, 안재성, 이혜연',
    finalCumulativeAmount: '$17,781.52',
  },
  {
    category: '천안다온 RC',
    name: '$1,000 이상',
    donationAmount: '정상영 $3,000, 임승범, 한교근, 김주영, 이민성, 홍미영 $10,000',
    finalCumulativeAmount: '$17,781.52',
  },
  {
    category: '천안다온 RC',
    name: 'GG, 폴리오',
    donationAmount: '정상영 $60, 김민수 $23, 윤지희 $23, 천안다온 RC $23, 김진주 $25, 남궁호돌 $25',
    finalCumulativeAmount: '$17,781.52',
  },
  {
    category: '천안다온 RC',
    name: '$100 이상',
    donationAmount: '공나영, 김기욱, 김다혜, 김도윤, 김영태, 김주영, 김태연, 송예화, 송유나, 신찬식, 심완석, 이명학, 이수경, 이채린, 정다솜 $200, 정주란, 조소현, 최홍석, 한창희, 황성언',
    finalCumulativeAmount: '$2,100.00',
  },
];

const data5 = [
  {
    category: '온양 RC',
    name: '$100이상',
    donationAmount: '이흥복, 조광원, 지준선, 최성민 $300, 홍승태 $400, 김종문 $700, 박병규 $200, 심재철, 서동현 $200, 이성구 $200, 박종민 $200, 김태권, 박호림, 김대환 $200, 맹치성, 문준태, 박인용, 신대호, 안승훈, 오세윤, 윤찬희, 이민우, 이성천, 이정욱, 이진복, 정해룡, 제상렬, 조광원, 차승원, 이진복, 지준선',
    finalCumulativeAmount: '$31,786.72',
  },
  {
    category: '온양 RC',
    name: '$1,000이상',
    donationAmount: '양재우 $5,100, 최명근 $1,800, 최명진 $3,000, 권오진 $1,100, 박승명 $2,300, 박재철 $1,100, 안현준 $1,100, 왕홍기 $1,400, 이민수 $1,400, 최완수 $3,200, 홍영기 $1,100, 성기복, 노수천 $1,100, 조원영',
    finalCumulativeAmount: '$31,786.72',
  },
  {
    category: '온양 RC',
    name: 'GG, 폴리오',
    donationAmount: '최명근 $37, 최명진 $22, 김용선 $23, 김종문 $62, 노수천 $23, 박인용 $23, 박재철 $23, 이민수 $23, 이성구 $23, 조원영 $23, 최명근 $23, 최성민 $23, 최완수 $129, 홍영기 $23, 배성윤 $22, 강규식 $7, 권오진 $7, 김남주 $22, 김대환 $7, 김종문 $7, 김태석 $7, 남흥우 $7, 노수천 $7, 박병규 $7, 박순우 $7, 박승명 $7, 박인용 $22, 박재철 $7, 박종민 $7, 박호림 $7, 신대호 $44, 심재철 $29, 심정수 $7, 안현준 $7, 양재우 $7, 엄병호 $29, 오세윤 $7, 왕홍기 $7, 윤달석 $7, 윤종무 $7, 윤종호 $7, 이민수 $7, 이성구 $7, 이종근 $7, 이진복 $29, 정해룡 $29, 제상렬 $7, 조광원 $29, 조원영 $7, 지준선 $29, 지희승 $7, 차승원 $7, 최명근 $29, 최성민 $7, 최완수 $14, 홍승태 $7, 홍영기 $7, 김병모 $38, 이흥복 $23, 곽용훈 $7, 김대경 $7, 김응규 $7, 김의주 $7, 김태권 $7, 맹치성 $7, 문준태 $7, 박우승 $7, 박원종 $7, 박충환 $7,  배장한 $7, 서동현 $7, 성기복 $7, 안승훈 $7, 양원모 $7, 오규한 $7, 윤중호 $7, 윤찬희 $7, 이민우 $7, 이성천 $7, 이승헌 $7, 이정욱 $7, 이주섭 $7, 이태호 $7, 이호성 $7, 장덕진 $7. 전만권 $7, 정연욱 $7, 정우천 $7, 차영석 $7, 천해성 $7, 최기석 $7, 최명철 $7, 최상국 $7, 최재인 $7, 한진섭 $7',
    finalCumulativeAmount: '$31,786.72',
  },
  {
    category: '온양목련 RC',
    name: '$100 이상',
    donationAmount: '강순희, 김수영, 김순희, 김영미, 김영자, 김윤정, 김은주, 김의영, 김희주, 박효진, 방주현, 소근혜, 송승은, 신명수 $200, 신인순, 안소연, 양우희, 윤하연 $300, 이   정, 이수월, 이애정, 이은열, 이의숙, 이현정, 임수빈, 장혜숙 $200, 전정미, 전주영, 전혜진, 정미나, 정재순, 정정영, 정정은, 최옥희, 최이정, 한승진, 황혜경 $500, 뢰해연',
    finalCumulativeAmount: '$21,613.19',
  },
  {
    category: '온양목련 RC',
    name: '$1,000 이상',
    donationAmount: '김민채 $1,100, 김소영 $1,200, 김은희 $1,100, 박옥연 $1,100, 신연주 $1,100, 이상미 $1,100, 하경희 $1,200, 정영희 $1,300, 정유진 $1,300, 조숙희 $1,200, 현은영 $1,100, 황윤정 $1,200, 임유자 $1,100',
    finalCumulativeAmount: '$21,613.19',
  },
  {
    category: '온양목련 RC',
    name: 'GG, 폴리오',
    donationAmount: '김영미 $23, 송승은 $23, 신명수 $67, 신연주 $67, 이  정 $89, 정미나 $45, 조숙희 $67, 한승진 $67, 김민채 $44, 김소영 $22. 신인순 $44, 윤하연 $44. 전정미 $44, 정영희 $44, 정유진 $51, 하경희 $44, 현은영 $44, 황윤정 $73, 황혜경 $66, 뢰해연 $15, 정정영 $15',
    finalCumulativeAmount: '$21,613.19',
  },
  {
    category: '아산둔포 RC',
    name: '$1,000이상',
    donationAmount: '강재호, 김용근',
    finalCumulativeAmount: '$2,000.00',
  },
  {
    category: '아산배방 RC',
    name: '$100 이상',
    donationAmount: '김희수, 홍선자 $500, 엄병수, 유은희, 이태준 $200, 최인상 $300, 권덕주',
    finalCumulativeAmount: '$12,984.74',
  },
  {
    category: '아산배방 RC',
    name: '$1,000 이상',
    donationAmount: '강성용 $3,000, 김도일 $1,100, 윤주원, 이재훈 $3,000, 최영석, 성기연, 이재영',
    finalCumulativeAmount: '$12,984.74',
  },
  {
    category: '아산배방 RC',
    name: 'GG, 폴리오',
    donationAmount: '김도일 $23, 유은희 $23, 이병하 $45, 이재영 $23, 이재훈 $45, 강성용 $22, 엄병수 $22, 홍선자 $22, 박은희 $22, 안용진 $22, 양장훈 $22, 유호준 $22, 임희수 $22, 임희숙 $22, 조관승 $22',
    finalCumulativeAmount: '$12,984.74',
  },
  {
    category: '아산온궁 RC',
    name: '$100 이상',
    donationAmount: '김운호, 오기석, 오현석, 조명희, 최경용, 김세영, 이강로, 한상식. 김영렬, 나영주, 이석일, 차주성, 권용삼 $300, 이재영',
    finalCumulativeAmount: '$16,075.81',
  },
  {
    category: '아산온궁 RC',
    name: '$1,000 이상',
    donationAmount: '박은서, 황윤석, 가유진, 박상호 $3,000, 신동찬, 정형모 $2,000, 박완석, 윤정희, 유동수 $2,000, 장기영',
    finalCumulativeAmount: '$16,075.81',
  },
  {
    category: '아산온궁 RC',
    name: 'GG, 폴리오',
    donationAmount: '권용삼 $23, 서봉원 $23, 신동찬 $23, 정형모 $23, 황윤석 $113, 서윤적 $22, 신동익 $22, 박은서 $7, 송혜준 $15',
    finalCumulativeAmount: '$16,075.81',
  },
  {
    category: '아산성웅 RC',
    name: '$100 이상',
    donationAmount: '김강석, 백현석, 이태호, 이한기, 이현석, 이호기, 지현준, 최동욱, 최중한, 김광남, 김상민, 김세용, 김영권, 김종훈, 박원순, 박정우, 박종성, 박천규, 서광원, 송영식, 우희찬, 원종윤, 이정호, 이황재, 임재항, 전상혁, 정광수, 정오영, 조성인, 하영욱',
    finalCumulativeAmount: '$9,877.07',
  },
  {
    category: '아산성웅 RC',
    name: '$1,000 이상',
    donationAmount: '권순관 $1,300,  권오일 $1,600 , 황재만 $1,100, 엄태준 $1,100, 김종성 $1,100',
    finalCumulativeAmount: '$9,877.07',
  },
  {
    category: '아산성웅 RC',
    name: 'GG, 폴리오',
    donationAmount: '권순관 $45, 김강석 $45, 이성재 $45, 이한기 $45, 권오일 $22, 김광남 $22, 김세용 $22, 김종성 $29, 김종훈 $22, 박원순 $22, 박종성 $22, 백현석 $22, 서광원 $22, 송영식 $22, 엄태준 $22, 이덕호 $22, 이태호 $22, 정오영 $22, 최동욱 $22, 황재만 $22, 김지성 $21, 김형석 $21, 신진섭 $21, 심규환 $21, 이덕호 $21, 이성재 $21',
    finalCumulativeAmount: '$9,877.07',
  },
  {
    category: '온양온천 RC',
    name: '$100 이상',
    donationAmount: '강정민, 김관태, 김기성, 김명기, 김민영, 김종목, 김환용, 박민규, 박영신, 박용기, 손복주, 안성원, 안양준, 엄성용, 오성호, 원유광, 이민철, 이상훈, 이지환, 임영철, 장학보, 정영호, 정진철, 정해준, 최순권, 최종원, 최진성, 하민성, 허승욱, 황덕원, 황재하, 황재호, 장정호',
    finalCumulativeAmount: '$7,342.39',
  },
  {
    category: '온양온천 RC',
    name: '$1,000 이상',
    donationAmount: '강찬호 $1,100, 이상천 $1,100, 손복주 $1,100',
    finalCumulativeAmount: '$7,342.39',
  },
  {
    category: '온양온천 RC',
    name: 'GG, 폴리오',
    donationAmount: '온양온천 RC $787, 김명기 $23, 정관호 $23, 임영철 $7',
    finalCumulativeAmount: '$7,342.39',
  },
];

const data6 = [
  {
    category: '새온양 RC',
    name: '$100이상',
    donationAmount: '공지행, 김경복 $200, 김영진, 김필수, 봉혁종, 장원섭, 복정욱, 윤근영, 이현상 $300, 양진수, 이호철, 심중근, 고명석, 유지호, 하건우, 윤승일 $200, 이현철 $200',
    finalCumulativeAmount: '$26,370.43',
  },
  {
    category: '새온양 RC',
    name: '$1,000이상',
    donationAmount: '송치운 $5,700, 이승호 $1,200, 박성호 $1,100, 송  욱 $1,100, 심원근, 양병성, 유지호, 윤형근, 이광희, 정한희 $1,400, 차호열 $2,000, 지영관, 엽성식 $1,300, 서광원, 노승일 $1,200, 정유성',
    finalCumulativeAmount: '$26,370.43',
  },
  {
    category: '새온양 RC',
    name: 'GG, 폴리오',
    donationAmount: '김영진 $22, 송치운 $44, 윤승일 $23, 이현철 $45, 정한희 $44, 김경복 $22, 이승호 $22, 공정식 $21, 김대식 $57, 김민수 $36, 김병수 $21, 김복현 $21, 김성중 $21, 김재봉 $21, 김정호 $21, 김태영 $21, 박성호 $21, 박원삼 $21, 박정식 $21, 방제현 $21, 송   욱$21, 심규성 $21, 심원근 $21, 심중근 $21, 양태백 $21, 양효석 $21, 유지호 $21, 윤승일 $21, 윤형근 $21, 이광희 $21, 이규문 $21, 이명수 $21, 이승호 $21, 이현철 $21, 이호철 $21, 장성준 $21, 장순표 $21, 장창국 $21, 전현철 $21, 정경현 $21, 정명순 $21, 정운진 $21, 정유성 $21, 정해동 $21, 최병천 $21, 허광회 $21, 홍석화 $21, 황명근 $21',
    finalCumulativeAmount: '$26,370.43',
  },
  {
    category: '온아 RC',
    name: '$100 이상',
    donationAmount: '권종안 $150, 박승규 $150, 박연수 $832, 원강희 $150, 윤재명 $250, 현병용 $154, 박정원$300, 윤여권 $250, 이순빈 $350, 김무선 $300, 김재인 $300, 윤지상 $350, 이태현 $350, 강경엽 $50, 강범식 $250, 강석정 $50, 권장섭 $50, 김경환 $50, 김동찬 $50, 김두경 $50, 김무선 $50, 김병흠 $50, 김빈 $50, 김성락 $50, 김송 $50, 김영운 $50, 김영필 $50, 김재인 $50, 김재하 $50, 김정재 $150, 김정태 $250, 김진묵 $50, 김택환 $50, 김혁수 $50, 박경귀 $50, 박선규 $50, 박주일 $50, 박호균 $150, 백경 $50, 복상배 $50, 서병찬 $50 신승훈 $250, 안병열 $50, 안상조$50, 엄정진$250, 연다환 $250, 오세관 $50,오세진 $50, 유승호 $50, 유재형$50, 유제봉 $50, 유지훈 $250, 윤신군 $250, 윤용원 $50, 윤종각 $50, 윤태호 $50, 윤필희 $250, 이규태 $50, 이대열 $50, 이대휘 $250, 이동복 $50, 이상득 $550, 이성열 $50, 이성종 $50, 이영호 $150, 이용주 $50, 이재균 $50, 이창열 $50, 이춘근 $50, 이충구 $50, 임동현 $50, 임상빈 $50, 임재학 $50, 임종태 $50 장동석 $50, 장진환 $50, 장한규 $50, 전용모 $50, 전용석 $50, 정만수 $350, 정성훈 $350, 조상훈 $550, 조원호 $250, 지상혁 $50, 진푸름 $50, 최도환 $250, 최동근 $350, 최범진 $50, 최석원 $50, 최정문 $50, 최정술 $50, 최진규 $150, 표영주 $250, 한기섭 $150, 한상빈 $50, 함한수 $150, 현만섭 $250, 홍기식 $150, 홍성준 $150, 황준철 $50',
    finalCumulativeAmount: '$61,906.36',
  },
  {
    category: '온아 RC',
    name: '$1,000 이상',
    donationAmount: '한상현 $1,050, 김시중 $3,050, 한주희 $4,500, 박상길 $1,250, 박정원 $1,050, 서보민 $1,050, 원재연 $1,050, 윤창희 $1,050, 이승준 $1,150, 이종수 $1,250, 장광석 $1,050, 전용철 $1,050, 한석호 $1,050, 권혁규 $1,250, 나병오 $3,825, 양성화 $1,050, 이호석 $1,050, 조함찬 $1,050, 박상우 $1,050, 박우준 $1,050, 김병대 $1,050, 김재명 $3,050, 이관진 $1,050, 이만영 $1,050, 이민현 $1,050, 이병희 $1,050, 이성득 $1,050',
    finalCumulativeAmount: '$61,906.36',
  },
  {
    category: '온아 RC',
    name: 'GG, 폴리오',
    donationAmount: '한주희 $1,000, 한상현 $2,000, 김시중 $78, 김재명 $23, 박상길 $39, 박선규 $23, 안상조 $23, 전용철 $23, 황준철 $23, 강경엽 $1,000, 박연수 $2,250, 원강희 $1,000, 장광석 $1,000, 장한규 $7',
    finalCumulativeAmount: '$61,906.36',
  },
  {
    category: '아산 RC',
    name: '$100 이상',
    donationAmount: '김철규, 김한수, 박승우, 이석일 $300, 최만영, 이병철 $400, 홍순복, 강덕원, 심한주, 강덕원, 곽노봉, 권철우, 김명관, 김은중, 김주호 $200, 김진선, 김철규 $300, 김한수, 박승우, 박호순, 박희근, 방준오, 서한규, 신기훈, 심한주 $300, 오창식, 윤규석, 윤영곤, 이병철, 이석일, 이석헌, 이선지, 이정열, 이정훈, 전주규, 정덕영, 정세영, 최만영, 최성우, 한운택, 홍순복',
    finalCumulativeAmount: '$23,130.68',
  },
  {
    category: '아산 RC',
    name: '$1,000 이상',
    donationAmount: '권철우 $1,100, 김건기 $1,700, 김헌수 $1,100, 남제국 $1,100, 정제훈 $1,500, 조은석 $1,200, 박한정 $1,100, 김순고 $3,200, 김기수$1,100, 박호순 $1,100, 홍성혁 $1,100, 김상수 $1,100',
    finalCumulativeAmount: '$23,130.68',
  },
  {
    category: '아산 RC',
    name: 'GG, 폴리오',
    donationAmount: '권철우 $23, 김순고 $1,023, 김진선 $23, 남제국 $23, 박승우 $23, 박한정 $23, 서한규 $23, 오창식 $23, 이석헌 $23, 전주규 $23, 정덕영 $23, 조은석 $23, 한운택 $23, 홍성혁 $23',
    finalCumulativeAmount: '$23,130.68',
  },
  {
    category: '온양중앙 RC',
    name: '$100이상',
    donationAmount: '강희준, 문한목, 박윤성 $200, 송영호, 홍정민, 강관호, 강동호, 김승호 $300, 김용범, 김경태, 최진철 $200, 김준태, 문한목, 송영호, 신두식, 유정우, 이성주, 정강민, 정상현, 조상원, 최종규, 최현구, 곽이신, 김병열, 김은환, 김정수, 김지원, 김현복, 김형준, 문한목, 박창용, 송제욱, 오세웅, 유성준, 이경무 $300, 이관현, 이두현, 이상준, 이용승, 이정복, 이창하, 이태헌, 임재혁, 정강민, 최은호, 최종규, 최태호, 최현구',
    finalCumulativeAmount: '$12,175.11',
  },
  {
    category: '온양중앙 RC',
    name: '$1,000이상',
    donationAmount: '김형구 $1,100, 김희석 $1,100, 이금용 $2,000, 이찬휘 $1,100, 천철호',
    finalCumulativeAmount: '$12,175.11',
  },
  {
    category: '온양중앙 RC',
    name: 'GG, 폴리오',
    donationAmount: '김형구 $23, 이금용 $23, 조상원 $23, 최종규 $23, 맹관재 $22, 박한철 $22, 안효중 $22, 우지완 $22, 이문규 $22, 이상진 $22, 이선일 $22, 이오주 $22, 이완하 $22, 이용곤 $22, 이종구 $22, 장인동 $22, 정낙진 $22, 조재묵 $22, 조중열 $22, 최창옥 $22, 편도흥 $22',
    finalCumulativeAmount: '$12,175.11',
  },
  {
    category: '아산온정 RC',
    name: '$1,000이상',
    donationAmount: '이병헌',
    finalCumulativeAmount: '$1,000.00',
  },
  {
    category: '아산현충 RC',
    name: '$1,00 이상',
    donationAmount: '김금숙, 김영식, 김현숙, 나진균, 서선금, 성원선, 이은호, 정재신, 주을규, 최승옥',
    finalCumulativeAmount: '$3,470.72',
  },
  {
    category: '아산현충 RC',
    name: '$1,000 이상',
    donationAmount: '김형태 $1,100, 황명희 $1,099',
    finalCumulativeAmount: '$3,470.72',
  },
  {
    category: '아산현충 RC',
    name: 'GG, 폴리오',
    donationAmount: '김경욱 $22, 김금숙 $22, 김선애 $22, 김영식 $22, 김현숙 $22, 김형태 $22, 서선금 $22, 성원선 $22, 이은호 $22, 주을규 $22, 최승옥 $22, 황명희 $22',
    finalCumulativeAmount: '$3,470.72',
  },
  {
    category: '아산단미 RC',
    name: '$100이상',
    donationAmount: '강서윤 $200, 김선미, 김정임 $200, 김종미, 김춘아 $300, 박미숙, 박영자 $200, 엄태경 $500, 오혜란, 이서진 $300, 이수연, 정해영, 조미정, 최대령 $400, 추현아 $300, 박은미, 최영애',
    finalCumulativeAmount: '$9,670.08',
  },
  {
    category: '아산단미 RC',
    name: '$1,000이상',
    donationAmount: '성낙숙 $2,500, 권희숙 $2,000, 이상미 $1,300',
    finalCumulativeAmount: '$9,670.08',
  },
  {
    category: '아산단미 RC',
    name: 'GG, 폴리오',
    donationAmount: '권희숙 $70, 김선미 $23, 성낙숙 $224, 염태경 $47, 이상미 $23, 이서진 $23, 최대령 $86, 추현아 $23, 박미숙 $23',
    finalCumulativeAmount: '$9,670.08',
  },
  {
    category: '아산온수 RC',
    name: '$100이상',
    donationAmount: '임명호',
    finalCumulativeAmount: '$100.00',
  },
];

const data7 = [
  {
    category: '예산 RC',
    name: '$100이상',
    donationAmount: '박관영, 신훈섭, 이기남 $300, 이정후, 강전현, 김관제, 양정민, 박영수, 박영준 $200, 박주광, 박찬수 $300, 임승한, 장화균 $200, 조준기, 강병준, 조병력',
    finalCumulativeAmount: '$19,572.07',
  },
  {
    category: '예산 RC',
    name: '$1,000이상',
    donationAmount: '손동철 $2,000, 김충호, 신원석, 이병철, 이종근, 조형석, 지태선, 김인환, 김해경, 박상호, 강희권, 양준서, 지홍배, 황순태',
    finalCumulativeAmount: '$19,572.07',
  },
  {
    category: '예산 RC',
    name: 'BNF',
    donationAmount: '박우범 $1,000, 손동철 $1,000',
    finalCumulativeAmount: '$19,572.07',
  },
  {
    category: '예산 RC',
    name: 'GG, 폴리오',
    donationAmount: '박현우 $23, 손준영 $23, 유선국 $23, 장은종 $23, 윤희남 $10, 김철호 $10, 김영국 $10, 고진석 $25, 김영국 $16, 김철호 $16, 박관영 $16, 박천수 $25, 오영대 $25, 유성조 $25, 윤희남 $16, 이대복 $25, 최선철 $25, 최원진 $25',
    finalCumulativeAmount: '$19,572.07',
  },
  {
    category: '신례원 RC',
    name: '$1,000 이상',
    donationAmount: '윤대근,  윤선길, 현상도',
    finalCumulativeAmount: '$3,000.00',
  },
  {
    category: '고덕 RC',
    name: '$1,000 이상',
    donationAmount: '박승기, 성종경, 함상수',
    finalCumulativeAmount: '$3,023.25',
  },
  {
    category: '고덕 RC',
    name: 'GG, 폴리오',
    donationAmount: '곽영호 $7, 신성철 $7, 윤정호 $7',
    finalCumulativeAmount: '$3,023.25',
  },
  {
    category: '예산어울림 RC',
    name: '$100 이상',
    donationAmount: '권희경, 김경개, 김나영, 김명자, 김미라, 김미배, 김미숙, 김순애, 김영신, 김영애, 김정아, 김해숙, 김현정, 노수진, 동미숙, 명게남, 명지민, 안정란, 엄영미, 이문자, 이미형, 이연우, 이영미, 장대순, 장명희, 장병희, 주현실, 차난호, 최기영',
    finalCumulativeAmount: '$2,900.00',
  },
];

const data8 = [
  {
    category: '당진 RC',
    name: '$100이상',
    donationAmount: '강병수 $200, 강진수, 고창국, 곽유신, 권동호, 권석인, 권혁대, 김광수, 김규호, 김남호, 김명훈, 김범찬, 김선욱, 김수길, 김수동, 김수한, 김영광 $200, 김영길, 김재완, 김정운, 김한식, 맹붕재, 박경호, 박성기, 배정영, 백승현 $400, 백종석, 손인동, 손   한, 송노섭, 송성현, 유현근, 유홍종, 이권용, 아규정, 이금설, 이병관, 이수동, 이승관 $204, 이영식, 이재기, 이재범, 이정복, 이정환, 이주원, 이춘광, 인정남, 장기훈, 정봉식, 정윤래, 정종연, 조대연, 차준환, 최남용, 최재우, 최종덕, 최한귀, 최희열, 한범수, 홍윤표 $300, 고연수 $200, 임희준 $200, 김상정 $150, 이경민 $150',
    finalCumulativeAmount: '$16,758.98',
  },
  {
    category: '당진 RC',
    name: '$1,000이상',
    donationAmount: '김도호 $1,100, 홍현선 $1,100, 권대한 $1,100, 성창모 $1,100, 천정순 $1,100',
    finalCumulativeAmount: '$16,758.98',
  },
  {
    category: '당진 RC',
    name: 'BNF',
    donationAmount: '김도호 $1,000, 김선욱 $1,000',
    finalCumulativeAmount: '$16,758.98',
  },
  {
    category: '당진 RC',
    name: 'GG, 폴리오',
    donationAmount: '성창모 $75, 홍윤표 $60, 홍현선 $22, 김도호 $223, 김영광 $67, 박성기 $23, 최한귀 $23, 강병수 $23, 권대한 $7, 김남호 $21, 김선욱 $7, 김수동 $7, 김재완 $7, 맹붕재 $7, 배정영 $7, 성창모 $7, 송노섭 $7, 이병관 $7, 이수동 $1,023, 임희준 $7, 최남용 $7',
    finalCumulativeAmount: '$16,758.98',
  },
  {
    category: '석문 RC',
    name: '$100 이상',
    donationAmount: '박석이 $174, 강성근, 곽명식, 김성환, 김운학, 김익한, 김철민, 김태우, 김희곤, 류재일, 문상식, 박병근, 박상완, 박석이, 박석현, 배성준, 백미란, 백주호, 서시원, 서형석, 선양규, 송민수, 심의수, 원완희, 유소연, 이남호, 이연배, 이옥식, 이종억, 이형선, 이홍식, 인기명, 인태환, 인현환, 임성민, 장재영, 전명환, 조무현, 조선형, 조소행, 조영현, 조재연, 최장옥, 한기종, 황   빈',
    finalCumulativeAmount: '$22,876.23',
  },
  {
    category: '석문 RC',
    name: '$1,000 이상',
    donationAmount: '권오환 $2,100, 김태권 $1,100',
    finalCumulativeAmount: '$22,876.23',
  },
  {
    category: '석문 RC',
    name: 'BNF',
    donationAmount: '문상식 $15,000',
    finalCumulativeAmount: '$22,876.23',
  },
  {
    category: '석문 RC',
    name: 'GG, 폴리오',
    donationAmount: '권오환 $23, 김태권 $23, 문상식 $23, 조무현 $23, 손광원 $7',
    finalCumulativeAmount: '$22,876.23',
  },
  {
    category: '합덕 RC',
    name: 'GG, 폴리오',
    donationAmount: '표효열 $1,000',
    finalCumulativeAmount: '$1,000.00',
  },
  {
    category: '당진당나루 RC',
    name: '$100 이상',
    donationAmount: '김태원, 김성용, 최천천, 홍운표, 정해경 $108, 김중달, 신도균, 정경재, 김정곤',
    finalCumulativeAmount: '$5,203.01',
  },
  {
    category: '당진당나루 RC',
    name: '$1,000 이상',
    donationAmount: '김영식, 신영철, 최병국 $1,100, 최석기 $1,100',
    finalCumulativeAmount: '$5,203.01',
  },
  {
    category: '당진당나루 RC',
    name: 'GG, 폴리오',
    donationAmount: '김중달 $23, 송홍석 $23, 이철수 $23, 최병국 $23',
    finalCumulativeAmount: '$5,203.01',
  },
  {
    category: '당진송악 RC',
    name: '$100이상',
    donationAmount: '고경원, 김규중, 남연아, 박성남, 심현오, 안병권, 안상용, 이수정, 이한복 $200, 인치룡, 장용정, 심대섭, 이창휘, 정덕순 $200, 최재선, 최종호, 한명숙, 곽송섭, 김광일, 김왕기, 김용례, 이상열, 임채연 $56, 장소망, 조용재, 김만석, 김병수, 박영환, 성지용, 신용제, 윤건, 임채연$48, 한재석',
    finalCumulativeAmount: '$22,209.86',
  },
  {
    category: '당진송악 RC',
    name: '$1,000이상',
    donationAmount: '김민정 $1,100, 김영노 $2,100, 김이권 $1,100, 김형기 $1,100, 배정진 $1,100, 이대호 $1,100, 인명진 $1,100, 허충기 $1,100, 김원정 $2,000, 김진용 $1,100, 이동주 $1,100, 홍영철 $1,100',
    finalCumulativeAmount: '$22,209.86',
  },
  {
    category: '당진송악 RC',
    name: 'GG, 폴리오',
    donationAmount: '장용정 $33, 최재선 $33, 홍영철 $118, 고경원 $87, 곽송섭 $8, 김광일 $8, 김규중 $10, 김민정 $139, 김영노 $1,196, 김왕기 $8, 김용례 $8, 김원정 $155, 김이권 $149, 김진용 $131, 김형기 $149, 남연아 $48, 박성남 $10, 배정진 $149, 신용제 $77, 심대섭 $47, 심현오 $10, 안병권 $48, 안상용 $10, 이대호 $110, 이동주 $95, 이상열 $39, 이수정 $87, 이지혜 $38, 이창휘 $8, 이한복 $10, 인명진 $72, 인치룡 $48, 김병학 $21, 임채연 $23, 장소망 $8, 정덕순 $76, 조용재 $47, 최종호 $83, 한명숙 $48, 허충기 $72, 김만석 $1, 박승석 $21, 박영환 $1, 성지용 $1, 신용제 $1, 윤건 $1, 윤형순 $21, 장용정 $72, 정인희 $21, 최종호 $21, 한재석 $1',
    finalCumulativeAmount: '$22,209.86',
  },
  {
    category: '당진신평 RC',
    name: '$100 이상',
    donationAmount: '김병욱, 김원석, 김정태, 김진규, 김태성, 박건수, 오성환, 원국희, 윤영한, 윤홍식, 이문수, 이순주, 이윤재, 이정원, 이정재, 이진권, 이호주, 이호준, 전만덕, 조성명, 조한규, 조한청, 한근섭, 한기영, 한만수',
    finalCumulativeAmount: '$6,923.25',
  },
  {
    category: '당진신평 RC',
    name: '$1,000 이상',
    donationAmount: '곽병진 $1,100, 김연구 $1,100, 이정호 $1,100, 고완순 $1,100',
    finalCumulativeAmount: '$6,923.25',
  },
  {
    category: '당진신평 RC',
    name: 'GG, 폴리오',
    donationAmount: '김원석 $7, 원국희 $7. 이진권 $7',
    finalCumulativeAmount: '$6,923.25',
  },
  {
    category: '당진고대 RC',
    name: '$100 이상',
    donationAmount: '강세구, 강은식, 강현규, 김남철, 김명식, 김선호, 김정호, 김태황, 노창엽, 박근철, 박석천, 박준서, 손성원, 손요한, 손자승, 손정훈, 손종선, 손학승, 송재석, 신동석, 신성철, 신인철, 신준호, 안영수, 유성근, 이수호, 이용호, 이원선, 이종석, 이중호, 이철선, 이태호, 정상길, 정종희, 허   석',
    finalCumulativeAmount: '$7,007.75',
  },
  {
    category: '당진고대 RC',
    name: '$1,000 이상',
    donationAmount: '박경태 , 이광선',
    finalCumulativeAmount: '$7,007.75',
  },
  {
    category: '당진고대 RC',
    name: 'GG, 폴리오',
    donationAmount: '김선호 $7, 박경태 $100, 손경수 $1,100, 조남영 $100, 최광일 $100',
    finalCumulativeAmount: '$7,007.75',
  },
  {
    category: '당진해나루 RC',
    name: '$100 이상',
    donationAmount: '박은주 $200, 최화선 $200, 권문숙, 김경희, 김민정, 김봉순, 김영숙 $400, 김은경, 박정숙 $200, 류승연, 박민서, 박수경, 박진희, 박해리, 송성미, 양미순, 윤보영, 이경은, 이명순, 이선옥, 임미순, 이시연, 이자영, 임미순, 임서은, 정희정 $200, 최선례, 최윤미, 한주연, 허봉선, 홍세비, 황세진',
    finalCumulativeAmount: '$18,939.92',
  },
  {
    category: '당진해나루 RC',
    name: '$1,000 이상',
    donationAmount: '김명순 $1,400, 최영미 $1,300, 김민영 $2,200, 박진영 $1,100, 유지숙 $1,100, 윤수영 $1,100, 이미숙 $1,100, 주순임 $1,100, 정연미, 박정숙 $1,200',
    finalCumulativeAmount: '$18,939.92',
  },
  {
    category: '당진해나루 RC',
    name: 'GG, 폴리오',
    donationAmount: '김명순 $291, 최영미 $56, 김민영 $26, 박진영 $1,053, 유지숙 $41, 윤수영 $26, 이미숙 $41, 주순임 $26, 박은주 $26, 박정숙 $26, 최화선 $26, 권문숙 $26, 김경희 $26, 김민정 $26, 김봉순 $26, 김영숙 $26, 김은경 $26, 류승연 $26, 박민서 $26, 박수경 $26, 박진희 $26, 박해리 $26, 송성미 $26, 양미순 $26, 윤보영 $26, 이경은 $53, 이명순 $26, 이선옥 $26, 이시연 $53, 이자영 $26, 임미순 $26, 임서은 $26, 정희정 $26, 최선례 $26, 최윤미 $26, 한주연 $26, 허봉선 $26, 홍세비 $26, 황세진 $26',
    finalCumulativeAmount: '$18,939.92',
  },
  {
    category: '당진아미 RC',
    name: '$100 이상',
    donationAmount: '김성호, 김영소 $200, 김은심, 김재인, 박순천, 박은숙 $200, 박일룡 $200, 신수식, 유형민, 이보연, 이성진, 이수정, 전경숙 $200, 조원식',
    finalCumulativeAmount: '$6,345.67',
  },
  {
    category: '당진아미 RC',
    name: '$1,000 이상',
    donationAmount: '최지웅 $1,200, 오동원, 유병수',
    finalCumulativeAmount: '$6,345.67',
  },
  {
    category: '당진아미 RC',
    name: 'GG, 폴리오',
    donationAmount: '전경숙 $50, 조원식 $50, 김민아 $27, 김은심 $27, 김재인 $27, 박순천 $1,027, 박일룡 $54, 유형민 $27, 이수정 $27, 최지웅 $27',
    finalCumulativeAmount: '$6,345.67',
  },
  {
    category: '당진골드 RC',
    name: 'GG, 폴리오',
    donationAmount: '김용연 $37',
    finalCumulativeAmount: '$37.04',
  },
];

const data9 = [
  {
    category: '광천 RC',
    name: '$100이상',
    donationAmount: '박성진, 우민제, 이경모, 이화영 $200, 정광훈 $200, 추영준 $200, 김건태, 정일희, 조권행, 최명규, 이영준, 전용선, 노상진 $200, 이명석 $200, 한용규, 성하용, 전상대, 채희관 $300, 홍영대, 김종범, 김준환, 장재목, 장재석',
    finalCumulativeAmount: '$17,070.02',
  },
  {
    category: '광천 RC',
    name: '$1,000이상',
    donationAmount: '윤명주 $1,100, 이성찬 $1,100, 이화영, 김효태, 양민석, 이천우 $3,000, 고영찬 $1,100, 김노성',
    finalCumulativeAmount: '$17,070.02',
  },
  {
    category: '광천 RC',
    name: 'BNF',
    donationAmount: '고영찬 $1,000',
    finalCumulativeAmount: '$17,070.02',
  },
  {
    category: '광천 RC',
    name: 'GG, 폴리오',
    donationAmount: '고영찬 $85, 김건태 $55, 김기성 $47, 김노성 $61, 노상진 $1,184, 윤명주 $101, 이성찬 $54, 이천우 $102, 정일희 $47, 조권행 $30, 이영준 $29, 박승철 $27, 성하용 $42, 오현석 $27, 이명석 $27, 이화영 $65, 정광훈 $27, 채희관 $14, 추영준 $50, 한용규 $42, 함영란 $34, 김정배 $31, 김효태 $31, 문경복 $23, 신동규 $7, 안영수 $7,  이규동 $23, 이근우 $7, 장재목 $7, 장재석 $34, 전상대 $45,  조영호 $29, 홍영대 $7, 김종범 $$22, 김준환 $22, 김교칠 $22, 김동진 $22, 류제혁 $22, 배종호 $22, 석영관 $22, 송규석 $22, 이기풍 $22, 이주용 $22',
    finalCumulativeAmount: '$17,070.02',
  },
  {
    category: '홍성 RC',
    name: '$100이상',
    donationAmount: '이종설, 주정현, 현병철, 김종원, 정민석, 김종원, 정민석, 김량우, 이병민, 이태영, 신용식, 김근영, 복정진, 신동선, 조윤희, 조한균, 황정일, 고진석, 김병효 $200, 김보람, 김용기, 김영순 $200, 김영택, 김완희, 김진호, 김태관, 박민성, 박병수, 박해덕 $200, 안문기 $200, 우석제, 이병창, 이서형, 이원학, 이의석, 이창훈, 인형진, 임기웅, 임원혁, 장인훈, 전명수, 전충하, 조성만, 조충훈, 주정호, 차득환, 채수윤, 하태윤, 한석규, 한석철, 황상현',
    finalCumulativeAmount: '$26,227.76',
  },
  {
    category: '홍성 RC',
    name: '$1,000이상',
    donationAmount: '김기서, 이근남, 이래석, 주광섭, 김범수, 김준영, 남상문, 서용희, 윤종산, 이상철, 전동수, 박임희, 유선길, 전재일, 박관형, 윤대영, 김성창, 박정진, 이성엽',
    finalCumulativeAmount: '$26,227.76',
  },
  {
    category: '홍성 RC',
    name: 'BNF',
    donationAmount: '남상문 $1,000',
    finalCumulativeAmount: '$26,227.76',
  },
  {
    category: '홍성 RC',
    name: 'GG, 폴리오',
    donationAmount: '김복렬 $1,000, 홍성 RC $50, 복정진 $22, 조윤희 $22, 김구한 $22, 송순동 $22, 오병찬 $22, 윤주선 $22, 이풍우 $22, 최덕순 $22, 최철수 $22, 하창수 $22',
    finalCumulativeAmount: '$26,227.76',
  },
  {
    category: '청양 RC',
    name: '$100이상',
    donationAmount: '김문환, 명재섭, 박창식, 양광영, 이상택, 임종탁, 한상국, 김기태, 김대경, 김문환, 김영춘, 노중호, 명재섭, 민근기, 박창식, 복창범, 신동선, 신철호, 양광영, 이상춘, 이상택, 이용일, 이진희, 이형호, 임재천, 임종탁, 조한균, 한상국',
    finalCumulativeAmount: '$5,227.74',
  },
  {
    category: '청양 RC',
    name: '$1,000이상',
    donationAmount: '김종대 $1,150',
    finalCumulativeAmount: '$5,227.74',
  },
  {
    category: '청양 RC',
    name: 'GG, 폴리오',
    donationAmount: '신동선 $37, 김기태 $7, 김대경 $7, 김문환 $77, 김영춘 $46, 김종대 $31, 노중호 $7, 명재섭 $15, 박창식 $15, 양광영 $77, 이상춘 $23, 이상택 $31, 이용일 $7, 이진희 $7, 임재천 $7, 임종택 $31, 한상국 $38, 황정일 $38, 이상규 $22',
    finalCumulativeAmount: '$5,227.74',
  },
  {
    category: '홍성홍서 RC',
    name: '$100이상',
    donationAmount: '김용덕, 송신범, 이상연, 손봉환, 정진혁',
    finalCumulativeAmount: '$2,211.22',
  },
  {
    category: '홍성홍서 RC',
    name: 'GG, 폴리오',
    donationAmount: '이상연 $48, 이소연 $48, 정진혁 $23, 한재문 $1,098, 김기철 $25, 김성식 $25, 김순옥 $25, 김용덕 $25, 김원태 $25, 김윤경 $25, 민경수 $25, 박경미 $25, 박경화 $25, 박완철 $25, 박필수 $25, 손봉환 $100, 송신범 $25, 유국동 $25, 육태국 $25, 이완희 $25',
    finalCumulativeAmount: '$2,211.22',
  },
  {
    category: '홍성홍주 RC',
    name: '$100이상',
    donationAmount: '박병수, 박순호, 임창순, 최홍철, 한정훈, 김성일, 김재권, 안재영, 이기연, 이준범 $45, 김연태, 안상길, 김바다, 장주명, 최홍철, 강준규, 김영환, 김형건, 박백수, 박세종, 오창영, 임창순, 최재원, 박영길, 박종덕, 신교철, 배수진 $200, 안형식, 이찬주, 한문택, 홍경표, 우태호, 이원용, 곽정욱, 김상국, 신영근, 오석환, 이상훈, 이선우, 이수행, 이홍엽, 최우영, 한이희, 황채성',
    finalCumulativeAmount: '$15,020.11',
  },
  {
    category: '홍성홍주 RC',
    name: '$1,000이상',
    donationAmount: '이준범 $2,200, 서용욱, 김수현 $1,200, 이순준 $1,100, 김유환 $1,100, 전용철 $1,100',
    finalCumulativeAmount: '$15,020.11',
  },
  {
    category: '홍성홍주 RC',
    name: 'BNF',
    donationAmount: '이준범 $1,000',
    finalCumulativeAmount: '$15,020.11',
  },
  {
    category: '홍성홍주 RC',
    name: 'GG, 폴리오',
    donationAmount: '이준범 $156, 김형건 $23, 서용욱 $23, 안상길 $23, 이순준 $232, 전용철 $23, 백영기 $1,145, 이선우 $23, 김바다 $37, 김연태 $37, 박백수 $37, 한운택 $37, 강동원 $23, 이원용 $23, 주현노 $25',
    finalCumulativeAmount: '$15,020.11',
  },
  {
    category: '청양칠갑산 RC',
    name: '$100이상',
    donationAmount: '이화영, 최면빈, 김진국, 김진환, 나인찬, 박상진, 윤혁수 $200, 이장섭, 이화영, 임창길, 조봉현, 최용실',
    finalCumulativeAmount: '$4,857.24',
  },
  {
    category: '청양칠갑산 RC',
    name: '$1,000이상',
    donationAmount: '김재덕 $1,100, 김원하',
    finalCumulativeAmount: '$4,857.24',
  },
  {
    category: '청양칠갑산 RC',
    name: 'GG, 폴리오',
    donationAmount: '윤혁수 $1,073, 이장섭 $23, 강용석 $30, 김진현 $30, 김평수 $30, 송인문 $30, 이영채 $30, 이정대 $30, 이종윤 $30, 이종재 $30, 전태성 $30, 정한수 $30, 최면빈 $30, 최영진 $30',
    finalCumulativeAmount: '$4,857.24',
  },
  {
    category: '홍성조양 RC',
    name: '$100이상',
    donationAmount: '문경희 $300, 이경수 $200, 이경희 $500, 신임숙 $700, 정주희 $300, 김보희 $200, 주희숙, 황연정, 김  숙 $400, 박승희 $200, 오미정 $300, 이나윤 $200, 임선희, 조윤주, 김진향 $200, 김창선 $300, 박승란 $200, 복혜옥 $700, 이화순 $400, 인경애 $200, 임선희 $200, 장정희 $700, 전영희 $200, 조성령 $200, 조윤주 $200, 허명숙 $200, 김영란, 김희라',
    finalCumulativeAmount: '$31,613.04',
  },
  {
    category: '홍성조양 RC',
    name: '$1,000이상',
    donationAmount: '강현주 $2,000, 김명자 $2,000, 유  쾌 $2,000, 이선자 $1,500, 이성자 $2,000, 이용선, 조연화 $1,300, 김미나, 박춘화 $2,000, 이현주 $1,500, 최숙묵 $2,000, 김미경, 송요선 $1,200, 양미자 $1,200',
    finalCumulativeAmount: '$31,613.04',
  },
  {
    category: '홍성조양 RC',
    name: 'GG, 폴리오',
    donationAmount: '김미경 $1,150, 박승희 $23, 박춘화 $61, 이나윤 $23, 조연화 $23, 신임숙 $30, 강현주 $93, 김미나 $23, 김숙 $38, 김영란 $23, 김창선 $23, 김희라 $46,  유쾌 $77, 이경수 $38, 이경희 $23, 이선자 $23, 이성자 $23, 이용선 $23, 이현주 $46, 이화순 $23, 임선희 $38, 전영희 $23, 조윤주 $23, 주희숙 $46, 홍성조양 RC $116, 황연정 $22',
    finalCumulativeAmount: '$31,613.04',
  },
  {
    category: '내포 RC',
    name: '$100이상',
    donationAmount: '임  솔, 장지운, 정상수, 정상수, 한정자 $540, 김경진 $200, 김숙 , 김연숙 $200, 김종옥, 민희숙, 윤정남 $200, 이종희 $300, 이지운, 이진영',
    finalCumulativeAmount: '$8,457.77',
  },
  {
    category: '내포 RC',
    name: '$1,000이상',
    donationAmount: '조미란 $1,900, 김조희 $1,100, 이경숙',
    finalCumulativeAmount: '$8,457.77',
  },
  {
    category: '내포 RC',
    name: 'GG, 폴리오',
    donationAmount: '한정자 $53, 김경진 $22, 김경진 $15, 이진영 $29, 임  솔$15, 조미란 $1,072, 한정자 $15, 김 숙 $37, 박사회 $37, 장혜림 $37, 최미영 $37, 이정연 $23, 전인영 $23, 홍지은 $23, 김연숙 $7, 김조희 $26, 김종옥 $14, 민희숙 $29, 백유현 $, 유선화 $77, 윤정남 $7, 이경숙 $7, 이정연 $37, 이종희 $37, 이지운 $14, 장지운 $7, 정상수 $7, 조인숙 $7, 바혜진 $22, 정예린 $22',
    finalCumulativeAmount: '$8,457.77',
  },
  {
    category: '광천오서산 RC',
    name: '$100이상',
    donationAmount: '강영숙 $200, 고미선 $200, 김경랑, 김나현 $200, 김명옥, 김미경 $300, 김복순, 김순이, 김양순, 김종선, 김현애 $200, 송영숙 $200,윤주원 $200, 이명은 $200, 이영숙, 이정희, 이지혜 $200,  임남정, 장정수, 정은미 $200, 정은수, 조금희 $100, 조현분 $100,최지희 $200, 한명희, 최재숙, 손혜량',
    finalCumulativeAmount: '$27,639.96',
  },
  {
    category: '광천오서산 RC',
    name: '$1,000 이상',
    donationAmount: '조현숙 $11,500, 강미 $1,500, 박현미 $1,200, 정경숙 $1,200, 김미숙 $1,100, 김상열 $1,100, 김현자 $1,100, 오경자 $1,100, 이수진 $1,200',
    finalCumulativeAmount: '$27,639.96',
  },
  {
    category: '광천오서산 RC',
    name: 'GG, 폴리오',
    donationAmount: '조현숙 $1,131, 강 미 $75, 박현미 $37, 정경숙 $37, 김미숙 $60, 김상열 $44, 김현자 $37, 장정수 $23, 오경자 $44, 김경랑 $37, 김나현 $59, 김명옥 $37, 김미경 $37, 김복순 $29, 김순이 $37, 조현분 $37, 김양순 $29, 김종선 $37,  송영숙 $45, 윤주원 $82, 이명은 $37, 이수진 $59, 이영숙 $37, 최지희 $37, 이정희 $37, 이지혜 $44, 임남정 $37, 장정수 $29, 정은미 $37, 정은수 $29, 조금희 $37, 손혜량 $30, 고미선 $37, 강영숙 $15, 김현애 $15, 최재숙 $15, 한명희 $7',
    finalCumulativeAmount: '$27,639.96',
  },
  {
    category: '홍성용봉 RC',
    name: '$100 이상',
    donationAmount: '김기천, 양승용, 최현준, 곽태영, 김동산, 김석연, 김성률, 김성무, 김용신, 노보성, 박  현, 백옥현, 복영길, 엄익상, 오재우, 이권호, 이병수, 이영기, 이환종, 임세훈, 장민상, 정희수, 조진철, 조창호, 조창희, 최건수, 최영찬',
    finalCumulativeAmount: '$8,910.88',
  },
  {
    category: '홍성용봉 RC',
    name: '$1,000 이상',
    donationAmount: '조병국, 지준석, 김용상, 주인섭, 최충식',
    finalCumulativeAmount: '$8,910.88',
  },
  {
    category: '홍성용봉 RC',
    name: 'GG, 폴리오',
    donationAmount: '김용상 $23, 정희수 $23, 김문환 $1,050, 엄익상 $37, 이권호 $37, 장민상 $37',
    finalCumulativeAmount: '$8,910.88',
  },
  {
    category: '홍성운주 RC',
    name: '$100 이상',
    donationAmount: '박선희, 유정화 $300, 이미숙 $300, 유정화, 이현정빈, 배문선, 정미정 $300, 조복화',
    finalCumulativeAmount: '$9,616.87',
  },
  {
    category: '홍성운주 RC',
    name: '$1,000 이상',
    donationAmount: '심미은 $1,100, 김미숙, 김효남, 박선희, 심미은, 이미숙',
    finalCumulativeAmount: '$9,616.87',
  },
  {
    category: '홍성운주 RC',
    name: 'GG, 폴리오',
    donationAmount: '박혜숙 $48, 심미은 $48, 조복화 $48, 한태화 $48, 이현정빈 $1,075, 김경아 $25, 김미숙 $25, 김연화 $25, 김용주 $25, 김운숙 $25, 김은희 425, 김혜경 $25, 김효남 $25, 문평미 $25, 박은지 $25, 박혜득 $25, 배문선 $25, 백혜정 $25, 서연희 $25, 서희원 $25, 송미자 $25, 안순선 $25, 오은숙 $25, 한미순 $50, 원정희 $22, 유정화 $25, 이란숙 $25, 이미숙 $25, 이선경 $25, 이인진 $25, 이재금 $25, 이정숙 $25, 이혜정 $25, 장병숙 $25, 정미정 $25, 정현진 $25, 조상록 $25, 조현정 $25',
    finalCumulativeAmount: '$9,616.87',
  },
];

const data10 = [
  {
    category: '대천 RC',
    name: '$100이상',
    donationAmount: '조락행 $210, 김종근, 임강빈, 박화용 $103, 이병수 $105, 이성수 $105, 임동식 $200, 고명권, 김길수, 남기덕, 조만희, 이한규 $105, 임우혁 $105, 김남균, 박규진  $200, 강광영 $200, 김장권, 김정태, 이종호, 임강빈 $310, 임인식, 최병상 $200, 경규준 $200, 이동철 $149, 이영철, 편호범 $200, 김기준, 김기형, 김태기, 이영성',
    finalCumulativeAmount: '$12,719.67',
  },
  {
    category: '대천 RC',
    name: '$1,000이상',
    donationAmount: '임관혁 $1,100, 허광회 $1,015, 구본춘, 김종세, 이성만 $1,100, 박근진 $1,205, 박철용 $1,100',
    finalCumulativeAmount: '$12,719.67',
  },
  {
    category: '대천 RC',
    name: 'GG, 폴리오',
    donationAmount: '남기덕 $23, 박철용 $23, 임동식 $23, 김길수 $7, 이성만 $7, 김영선 $10, 김요환 $10, 김용환 $10, 박춘기 $10, 양재원 $10, 유용성 $10, 이현국 $10, 조명제 $10, 최광종 $10, 최웅재 $10, 허영회 $10',
    finalCumulativeAmount: '$12,719.67',
  },
  {
    category: '대천중앙 RC',
    name: '$100이상',
    donationAmount: '강정길 $200, 강주영 $200, 강현우 $200, 고정윤 $200, 공병일 $200, 국중남 $200, 권순선 $200, 김대현 $200, 김문식 $200, 김민수 $200, 김보겸 $200, 김봉권 $200, 김상근 $200, 김상석 $200, 김성진 $200, 김승환 $200, 김시연 $200, 김영민 $200, 김영춘 $200, 김준배 $200, 김지호 $400, 김진혁 $200, 김학동 $200, 김학철 $200, 김환형 $200, 나용근 $200, 명재권 $200, 문명훈 $200, 박상철 $200, 박종민 $200, 박종신 $200, 박창환 $200, 백광진 $200, 백창호 $200, 변인태 $200, 서영석 $200, 서용석 $200, 설창균 $200, 송관섭 $200, 신동길 $200, 신상준 $200, 염관영 $200, 우상현 $200, 유병호 $200, 유봉수 $200, 이광호 $200, 이병행 $200, 이보현 $200, 이영국 $200, 이종저 $200, 이철희 $200, 이형화 $200, 임교순 $200, 임기혁 $200, 임상완 $200, 장이기 $200, 정원희 $200, 조남설 $200, 최순태 $200, 최윤규 $200, 한면택 $200, 한재영 $200, 한진성 $200, 홍지호 $200, 황찬우 $200',
    finalCumulativeAmount: '$75,888.08',
  },
  {
    category: '대천중앙 RC',
    name: '$1,000이상',
    donationAmount: '박홍전 $3,400, 김면수 $3,000, 이수연 $3,100, 강희구, 김요진, 김유년, 김찬용, 김형길, 류승현, 박종복, 손준호, 신상준, 심용기, 안정배, 유선일, 이기복, 이병열, 이보근 $1,100, 이순신, 이진범, 이찬호, 김완섭 $1,200, 이춘호, 이형규, 장동엽, 정준모, 조대희, 최진복, 하동우, 황인만, 김기운, 김윤기 $1,200, 류해권 $1,100, 오정교, 조승한, 황영택',
    finalCumulativeAmount: '$75,888.08',
  },
  {
    category: '대천중앙 RC',
    name: 'BNF',
    donationAmount: '박홍전 $15,000',
    finalCumulativeAmount: '$75,888.08',
  },
  {
    category: '대천중앙 RC',
    name: 'GG, 폴리오',
    donationAmount: '박홍전 $2,025, 김면수 $64, 김승환 $48, 김윤영 $48, 김형길 $48, 류해권 $48, 박종복 $48, 박창환 $48, 변인태 $48, 안정배 $48, 염관영 $48, 유봉수 $48, 이기복 $23, 이수연 $48, 이춘호 $23, 장이기 $23, 조대희 $48, 조승한 $48, 강정길 $25, 강주영 $25, 강현우 $25, 강희구 $25, 고정윤 $25, 공병일 $25, 국중남 $25, 권순선 $25, 김대현 $25, 김문식 $25, 김민수 $25, 김보겸 $25, 김봉권 $25, 김상근 $25 , 김상석 $25, 김성진 $51, 김시연 $25, 김영민 $25, 김영춘 $25, 김완섭 $25, 김요진 $25, 김유년 $25, 김윤기 $25, 김준배 $25, 김지호 $51 김진혁 $25, 김찬용 $25, 김학동 $25, 김학철 $25, 김환형 $25, 나용근 $25, 류승현 $25, 명재권 $25, 문명훈 $25, 박상철 $25, 박정욱 $25, 박종민 $25, 박종신 $25, 백광진 $25, 백창호 $25, 서영석 $25, 서용석 $25, 설창균 $25, 손준호 $25, 송관섭 $25, 신동길 $25, 신상준 $25, 심용기 $25, 엄광진 $25, 오정교 $25, 우상현 $25, 유병효 $25, 유선일 $25, 이광호 $25, 이기복 $25, 이병열 $25, 이병행 $25, 이보근 $25, 이보현 $25, 이순신 $25, 이영국 $25, 이종저 $25, 이진범 $25, 이찬호 $25, 이철희 $25, 이춘호 $25, 이형규 $25, 이형화 $25, 임교순 $25, 임기혁 $25, 임상완 $25, 장이기 $25, 정원희 $25, 정준모 $25, 조남설 $25, 최순태 $25, 최윤규 $25, 최진복 $25, 하동우 $25, 한면택 $25, 한재영 $25, 한진성 $25, 홍지호 $25, 황영택 $25, 황인만 $25, 황찬우 $25',
    finalCumulativeAmount: '$75,888.08',
  },
  {
    category: '대천서부 RC',
    name: '$100 이상',
    donationAmount: '나성윤',
    finalCumulativeAmount: '$100.00',
  },
  {
    category: '뉴대천 RC',
    name: '$100 이상',
    donationAmount: '유근상, 홍성표, 박종현, 이경환',
    finalCumulativeAmount: '$68,940.02',
  },
  {
    category: '뉴대천 RC',
    name: '$1,000 이상',
    donationAmount: '강한구 $12,300, 고종은 $1,100, 김석암 $3,100, 김은태 $3,300, 이계석 $1,100, 이명구 $1,100, 이진경 $1,600, 박희식 $1,300, 황규붕 $1,100, 강규헌 $1,100, 구자걸 $1,100, 강문필 $1,100, 고광일 $1,100, 권구남 $1,100, 김성룡 , 김완종$1,100, 김원배 $1,100, 김일환 $1,100, 김태익 $1,100, 박병운 $1,100, , 박준희 $1,100, 성병관 $1,600, 이상수 $1,100, 이상현 $1,100, 이지호 $1,100, 조규창 $1,100, 김선근, 유영재 $1,100, 최종찬 $1,100, 박태진 $1,100, 정승호 $1,100, 최병태 $1,100, 최영근 $1,100, 최형휴 $1,100, 강덕재, 강진우, 고동균, 김태연, 박대길, 박병혁, 정해창, 조철연',
    finalCumulativeAmount: '$68,940.02',
  },
  {
    category: '뉴대천 RC',
    name: 'GG, 폴리오',
    donationAmount: '강한구 $210, 고동균 $48, 김태연 $23, 박병혁 $23, 김석암 $1,032, 강규현$25, 강덕재$25, 강문필 $25,, 강진우$25, 고광일$25, 고종은 $25, 구자걸$25, 권구남 $25, 김선근 $25 , 김완종 $25, 김은태 $1,025, 김일환 $25, 김태연 $25, 김태익 $25, 뉴대천 RC $250, 박대길 $25, 박병운 $25, 박병혁 $25, 박종현 $1,025, 박준희 $25, 박태진 $25, 박희식 $25, 성병관 $25, 유근상 $25, 유영재 $25, 이경환 $25, 이계석 $25, 이명구 $25, 이상수 $25, 이상현 $25, 이지호 $25, 이진경 $25, 정승호 $25, 정해창 $25, 조규창 $25, 조철연 $25, 최병태 $25, 최영근 $25, 최종찬 $25, 최형휴 $25, 홍성표 $1,025, 황규붕 $25',
    finalCumulativeAmount: '$68,940.02',
  },
  {
    category: '대천더함 RC',
    name: '$100이상',
    donationAmount: '구미정, 김명월, 김옥정 $400, 김현옥, 장애경, 정현아, 조미래 $200, 최은이, 한남희, 황영선',
    finalCumulativeAmount: '$3,663.36',
  },
  {
    category: '대천더함 RC',
    name: '$1,000이상',
    donationAmount: '이기순',
    finalCumulativeAmount: '$3,663.36',
  },
  {
    category: '대천더함 RC',
    name: 'GG, 폴리오',
    donationAmount: '고영미 $53, 구미정 $26, 김명애 $53, 김명월 $26, 김선희 $53, 김옥정 $50, 김정란 $53, 김현옥 $26, 라선희 $53, 류남신 $53, 박진여 $53, 신명순 $53, 신혜옥 $53, 이기순 $50, 이순임 $53, 이정화 $53, 이혜윤 $53, 장애경 $26, 장영자 $53, 정현아 $26, 조경화 $53, 조미래 $26, 최은이 $50, 한규리 $53, 한남희 $26, 한선임 $53, 홍경옥 $53, 황영선 $26',
    finalCumulativeAmount: '$3,663.36',
  },
  {
    category: '로타리를 사랑한 모임',
    name: '$100 이상',
    donationAmount: '김성범 $102, 이성우 $102, 이종대 $102, 이충열 $102, 최길종 $102, 강목단 $102, 김길숙 $102, 김선화 $102, 유옥자 $102',
    finalCumulativeAmount: '$3,272.70',
  },
  {
    category: '로타리를 사랑한 모임',
    name: '$1,000 이상',
    donationAmount: '김한범 $1,022, 차경숙 $1,022',
    finalCumulativeAmount: '$3,272.70',
  },
];

const data11 = [
  {
    category: '서산 RC',
    name: '$100이상',
    donationAmount: '김기갑 $200, 김상곤 $200, 김용진 $200, 박낙균 $200, 백광욱 $200, 변진현 $200, 강찬일 $209, 서현석 $200, 송원태 $200, 오병옥 $200, 이남수 $200, 이상덕 $200, 김재관 $209, 임정수 $200, 임성환 $200, 임채윤 $200, 조한민 $200, 최창용 $200, 한범희 $200, 황상준 $200, 김정연 $209, 신수동 $209, 이수민 $209, 정재관 $209, 조희진 $209, 천경태 $209, 최준호 $209, 한준석 $200, 곡효인 $200, 김종규 $200, 박상목 $200, 박종윤 $200, 오장영 $200, 윤정구 $200, 정용민 $200, 김우석 $200, 윤성일 $200, 윤정호 $200, 인현무 $200, 금동준 $200, 김기엽 $200, 김선헌 $200, 이석하 $200, 조풍현 $200, 김병길 $205, 박태신 $205, 이정구 $205, 전종원 $205, 최낙훈 $205, 박상모 $205, 신동재 $205, 안상환 $205, 임붕순 $205, 최상윤 $205, 한기남 $205, 이귀형 $200, 이수섭 $200, 조정상 $200, 진시완 $200, 김경호 $200, 김대열 $200, 김영철 $200, 김종욱 $200, 송기수 $200, 송요신 $200, 아규현 $200, 차은용 $200, 최요섭 $200, 최재영 $200, 김정호 $212, 송영하 $212, 윤형노 $212, 조홍식 $212, 박근영 $207, 안선환 $207, 윤형식 $207',
    finalCumulativeAmount: '$53,970.45',
  },
  {
    category: '서산 RC',
    name: '$1,000이상',
    donationAmount: '가복현 $1,200, 김경희, 김상곤, 김현주 $1,200, 장광순 $3,409, 최동호 $1,209, 문안수 $1,205, 이덕순, 이택정 $1,200, 정동권 $1,209',
    finalCumulativeAmount: '$53,970.45',
  },
  {
    category: '서산 RC',
    name: 'BNF',
    donationAmount: '최준호 $11,000, 장광순 $1,000',
    finalCumulativeAmount: '$53,970.45',
  },
  {
    category: '서산 RC',
    name: 'GG, 폴리오',
    donationAmount: '김기갑 $26, 김상곤 $26, 김용진 $1,026, 박낙균 $26, 백광욱 $26, 변진현 $26, 서현석 $26, 강찬일 $1,026, 송원태 $26, 오병옥 $26, 이남수 $26, 이상덕 $1,026, 이택정 $26, 임성환 $26, 임채윤 $26, 김기엽 $45, 조한민 $526, 최창용 $26, 한범희 $26, 황상준 $26, 김병길 $49, 김재관 $26, 김정연 $26, 김종규 $49, 김현주 $26, 변진현 $23, 송용찬 $23, 신수동 $26, 오장영 $49, 이수민 $26, 임채윤 $23, 장광순 $2,071, 정동권 $26, 정용민 $49, 정재관 $2,026, 조한민 $23, 조희진 $526, 천경태 $1,026, 최동호 $26, 최요섭 $23, 최준호 $26, 최창용 $23, 한범희 $23, 곡효인 $26, 박상목 $26, 박종윤 $26, 윤정구 $26, 김우석 $21, 윤성일 $21, 윤정호 $21, 인현무 $21, 가복현 $22, 금동준 $22, 김선헌 $22, 이석하 $522, 조풍현 $1,022, 문안수 $26, 박태신 $26, 이정구 $26, 전종원 $26, 최낙훈 $26, 박상모 $26, 신동재 $26, 안상환 $26, 임붕순 $26, 최상윤 $26, 한기남 $26, 이귀형 $25, 이수섭 $25, 조정상 $25, 진시완 $25, 김경호 $23, 김광호 $10, 김대열 $23, 김영철 $23, 김종욱 $23, 박근영 $10, 박윤모 $10, 송기수 $23, 송요신 $23, 안선환 $10, 유장곤 $10, 윤형로 $10, 윤형식 $10, 이규현 $23, 이기영 $10, 임정수 $23, 장광재 $10, 장동욱 410, 전병기 $10, 전준배 $10, 조흥식 $10, 차은용 $23, 최요섭 $23, 최재영 $23, 한노수 $10, 한준석 $23, 홍기훈 $10, 김정호 $10, 송영하 $10',
    finalCumulativeAmount: '$53,970.45',
  },
  {
    category: '서령 RC',
    name: '$100이상',
    donationAmount: '백광복, 백광흠, 이정만, 이희복, 임경순, 전용섭, 박경식, 이동수, 유원동, 이강민',
    finalCumulativeAmount: '$5,294.90',
  },
  {
    category: '서령 RC',
    name: '$1,000이상',
    donationAmount: '김현기 $1,039, 장금철 $1,039, 한상용 $1,039, 허  만 $1,039',
    finalCumulativeAmount: '$5,294.90',
  },
  {
    category: '서령 RC',
    name: 'GG, 폴리오',
    donationAmount: '노치석 $23, 이동수 $54, 이정만 $23, 전용섭 $23',
    finalCumulativeAmount: '$5,294.90',
  },
  {
    category: '새서산 RC',
    name: '$1,000이상',
    donationAmount: '김성환, 정진구, 조현상',
    finalCumulativeAmount: '$3,889.48',
  },
  {
    category: '새서산 RC',
    name: 'GG, 폴리오',
    donationAmount: '김성환 $23, 성  웅 $49, 정운곤 $49, 정진구 $49, 가원민 $26, 강승구 $26, 권희상 $26, 김기윤 $26, 김남하 $26, 김문호 $26, 김성민 $26, 김성환 $26, 김종묵 $26, 김진식 $26, 김현웅 $26, 김호종 $26, 남형주 $26, 문수기 $26, 박권태 $26, 신광철 $26, 유경모 $26, 윤영식 $26, 윤정상 $26, 이상용 $26, 이재영 $26, 이태호 $26, 임범재 $26, 정주영 $26, 정지열 $26, 조현상 $26, 최민호 $26',
    finalCumulativeAmount: '$3,889.48',
  },
  {
    category: '충서 RC',
    name: '$100이상',
    donationAmount: '강선구, 강종수, 김도우, 김용철, 노승남, 박재영, 박종규, 박준영, 백세기, 송성현, 안기식, 엄철호, 유재민, 이원찬, 이흥노, 장대영, 전지웅, 정한문, 조석환, 지궁청, 최용호, 최준형, 최호웅, 한상기, 김은호, 유병국, 정재영',
    finalCumulativeAmount: '$13,194.48',
  },
  {
    category: '충서 RC',
    name: '$1,000이상',
    donationAmount: '남규탁, 남철현, 성명용, 연주흠, 오범택, 윤영중, 이상구, 이영관, 장대웅, 정일권 $1,400',
    finalCumulativeAmount: '$13,194.48',
  },
  {
    category: '충서 RC',
    name: 'GG, 폴리오',
    donationAmount: '남철현 $23, 송성현 $23, 연주흠 $23, 지궁청 $23',
    finalCumulativeAmount: '$13,194.48',
  },
  {
    category: '서산한서 RC',
    name: '$100이상',
    donationAmount: '가양현, 강문수 $181, 김동주, 김정훈 $200, 김주태, 안성용, 이경노, 최영현, 김병식, 김종국, 신훈재, 이수현, 한성, 김정호 $102, 남덕수 $102, 안성찬 $102, 임창신 $102, 정승욱 $102, 한순웅 $102, 정종환 $159, 가금현 97, 김종구 $444, 안종필 $97',
    finalCumulativeAmount: '$5,075.51',
  },
  {
    category: '서산한서 RC',
    name: '$1,000이상',
    donationAmount: '좌유성 $1,157, 한도현 $1,023',
    finalCumulativeAmount: '$5,075.51',
  },
  {
    category: '서산보라매 RC',
    name: '$100이상',
    donationAmount: '김백규, 김성재, 성기현, 임순학, 장순성, 정민우 $400, 조윤상, 차종배 $400, 한상유, 김무연, 박한종, 임계춘, 김병수, 김세영, 박성순, 송영훈, 이동호, 이재림, 인용희, 전복균, 정봉영, 지윤덕, 박상학, 이용선, 정해일, 홍경수',
    finalCumulativeAmount: '$28,294.48',
  },
  {
    category: '서산보라매 RC',
    name: '$1,000이상',
    donationAmount: '이석호 $3,100, 이기봉 $2,100, 송현주 $1,200, 이용주 $1,100, 이재현 $1,100, 이학철 $2,100, 이희윤 $2,100, 정병희 $1,100, 정점식 $2,100, 최관호 $1,100, 김두진 $2,400, 김종연 $1,100, 김종필 $1,100, 박용조 $1,100, 이경한 $1,100, 문수협 $1,100',
    finalCumulativeAmount: '$28,294.48',
  },
  {
    category: '서산보라매 RC',
    name: 'GG, 폴리오',
    donationAmount: '이기봉 $23, 이석호 $23, 최관호 $23, 최영희 $23',
    finalCumulativeAmount: '$28,294.48',
  },
  {
    category: '서산국화 RC',
    name: '$100이상',
    donationAmount: '가은정, 강계실 $500, 강민경, 고명옥, 고수진, 구능현, 김미경 $200, 김민주, 김선경, 김선아, 김선희 $200, 김혜경, 김혜희, 문병숙 $300, 박소연 $400, 박영민, 박현미, 변현욱 $400, 양형아, 엄미경 $300, 오은종 $300, 우수선, 유정희 $400, 유혜선, 이관순, 이성지, 이은숙, 이은심 $300, 임고운, 한옥화 $400, 한은량 $300, 한일희, 황영화',
    finalCumulativeAmount: '$20,102.10',
  },
  {
    category: '서산국화 RC',
    name: '$1,000이상',
    donationAmount: '김은숙 $1,100, 김은진 $1,300, 이화란 $1,100, 조영자 $1,100, 한옥희 $1,300, 장명애 $1,100',
    finalCumulativeAmount: '$20,102.10',
  },
  {
    category: '서산국화 RC',
    name: 'GG, 폴리오',
    donationAmount: '가은정 $23, 강계실 $1,164, 강민경 $23, 고명옥 $23, 고수진 $47, 구능현 $23, 김미경 $23, 김민주 $23, 김선경 $23, 김선아 $23, 김선희 $47, 김은숙 $70, 김은진 $1,142, 김혜경 $23, 김혜희 $23, 문병숙 $23, 박소연 $47, 박영민 $23, 박현미 $23, 변현욱 $23, 양형아 $23, 엄미경 $94, 오은종 $47, 우수선 $23, 유정희 $23, 유혜선 $23, 이관순 $1,120, 이성지 $1,120, 이은숙 $23, 이은심 $45, 이화란 $23, 임고운 $23, 장명애 $93, 조영자 $23, 한옥화 $47, 한옥희 $23, 한은량 $47,한일희 $23, 황지영 $1,095',
    finalCumulativeAmount: '$20,102.10',
  },
  {
    category: '서산가온 RC',
    name: '$100이상',
    donationAmount: '고미희, 김정애, 박수경 $300, 박지연, 송현미, 신유하, 윤희정, 이명임 $300, 이해진, 정선옥',
    finalCumulativeAmount: '$2,600.78',
  },
  {
    category: '서산가온 RC',
    name: '$1,000이상',
    donationAmount: '김은자 $1,100',
    finalCumulativeAmount: '$2,600.78',
  },
  {
    category: '서산가온 RC',
    name: 'GG, 폴리오',
    donationAmount: '김경이 $7, 김민숙 $7, 김시현 $7, 이명임 $23, 이미영 $23, 장도검 $23, 장동민 $7',
    finalCumulativeAmount: '$2,600.78',
  },
];

const data12 = [
  {
    category: '태안 RC',
    name: '$100 이상',
    donationAmount: '가병현, 김관중, 김대환, 김도인, 김종서, 김창주, 김철성, 김한수, 내조선, 박경배, 박광수, 방문권, 송재화, 신병철, 이상일, 이흥묵, 정영모, 조항영, 최영배, 최진서',
    finalCumulativeAmount: '$9,611.61',
  },
  {
    category: '태안 RC',
    name: '$1,000 이상',
    donationAmount: '장필종 $1,100,이부희 $1,100, 이윤희 $5,333',
    finalCumulativeAmount: '$9,611.61',
  },
  {
    category: '태안 RC',
    name: 'GG, 폴리오',
    donationAmount: '김한수 $23, 이상일 $23, 최진서 $23, 박경배 $7',
    finalCumulativeAmount: '$9,611.61',
  },
  {
    category: '안면도 RC',
    name: '$100이상',
    donationAmount: '김덕수, 김종석, 김흥돈, 이상호, 이영식, 이종태, 임지식, 황의덕, 최기문, 최정인, 강명진, 김대중, 김덕수, 김종석, 김흥돈, 남궁옥, 문대모, 박병근, 박세진, 박태기, 송현석, 이관종, 이상호, 이영식, 이종태, 임지식, 지경재, 황의덕',
    finalCumulativeAmount: '$17,102.23',
  },
  {
    category: '안면도 RC',
    name: '$1,000이상',
    donationAmount: '최병권 $2,000, 최종석 $2,000, 고봉화 $1,100, 권용식, 김동민, 김은광, 류현식, 박은근 $1,100, 전덕림, 정재봉, 편무용, 서동철',
    finalCumulativeAmount: '$17,102.23',
  },
  {
    category: '안면도 RC',
    name: 'GG, 폴리오',
    donationAmount: '고봉화 $23, 김대중 $23, 김덕수 $23, 정재봉 $23, 권용식 $7',
    finalCumulativeAmount: '$17,102.23',
  },
  {
    category: '태안소성 RC',
    name: '$100이상',
    donationAmount: '김휴석, 최성호, 한석용, 김남호, 김내곤, 김덕규, 김종즉, 박순용, 이현준, 한경희',
    finalCumulativeAmount: '$18,094.48',
  },
  {
    category: '태안소성 RC',
    name: '$1,000이상',
    donationAmount: '이종욱 $2,000, 강종원, 길헌근, 김종언, 강종원, 김광수, 문원근, 박인규 $2,000, 송창학, 이인행, 하대성',
    finalCumulativeAmount: '$18,094.48',
  },
  {
    category: '태안소성 RC',
    name: 'BNF',
    donationAmount: '김종언 $3,700',
    finalCumulativeAmount: '$18,094.48',
  },
  {
    category: '태안소성 RC',
    name: 'GG, 폴리오',
    donationAmount: '김국진 $23, 박인규 $23, 이경일 $23, 조성호 $23, 김종언 $300',
    finalCumulativeAmount: '$18,094.48',
  },
  {
    category: '태안백화산 RC',
    name: '$100이상',
    donationAmount: '도은종, 심태환, 윤창석, 이은형',
    finalCumulativeAmount: '$7,694.48',
  },
  {
    category: '태안백화산 RC',
    name: '$1,000이상',
    donationAmount: '김대섭 $2,000, 가재업, 박희규, 이기용, 전용환 $1,100, 조은상',
    finalCumulativeAmount: '$7,694.48',
  },
  {
    category: '태안백화산 RC',
    name: 'GG, 폴리오',
    donationAmount: '가재업 $23, 김대섭 $23, 박희규 $23, 조은상 $23',
    finalCumulativeAmount: '$7,694.48',
  },
  {
    category: '태안해당화 RC',
    name: '$100이상',
    donationAmount: '내효신 $300, 김미숙, 신미경, 신혜숙, 최미경',
    finalCumulativeAmount: '$1,183.52',
  },
  {
    category: '태안해당화 RC',
    name: 'GG, 폴리오',
    donationAmount: '양경옥 $52, 김헤자 $29, 강미하 $22, 강순숙 $22, 김민숙 $22, 김민희 $22, 김수진 $22, 류영월 $22, 박다교 $22, 박라라 $22, 백성미 $22, 이난순 $22, 이미숙 $22, 이정임 $22, 정영순 $22, 정윤자 $22, 정은주 $22, 조영순 $22, 최미경 $22, 최연봉 $22',
    finalCumulativeAmount: '$1,183.52',
  },
  {
    category: '안면도송림 RC',
    name: '$100이상',
    donationAmount: '강경희, 김 설, 김순애, 김애란, 나선영, 나은영, 서난희, 정희정, 조식례, 최순애, 홍승란, 홍진숙, 황세정',
    finalCumulativeAmount: '$4,699.98',
  },
  {
    category: '안면도송림 RC',
    name: '$1,000이상',
    donationAmount: '박소영 $1,100, 조경미 $2,100',
    finalCumulativeAmount: '$4,699.98',
  },
  {
    category: '안면도송림 RC',
    name: 'GG, 폴리오',
    donationAmount: '김 설 $22, 김애란 $22, 나선영 $22, 나은영 $22, 박소영 $22, 정희정 $22, 조경미 $22, 홍진숙 $22, 황세정 $22',
    finalCumulativeAmount: '$4,699.98',
  },
  {
    category: '태안한결 RC',
    name: '$100이상',
    donationAmount: '강종필 $620, 김민경, 문소연, 안화숙, 이창신 $200, 이희숙, 이상훈, 이창신 $200, 오지후, 김연화, 안화숙',
    finalCumulativeAmount: '$15,580.35',
  },
  {
    category: '태안한결 RC',
    name: '$1,000이상',
    donationAmount: '정미란 $2,078, 김복진 $1,339, 김정순 $1,139, 민인애 $1,039, 안해은 $2,200, 이수지 $1,039, 정미홍 $1,039, 손인성 $1,100, 이재하 $1,100',
    finalCumulativeAmount: '$15,580.35',
  },
  {
    category: '태안한결 RC',
    name: 'GG, 폴리오',
    donationAmount: '안해은 $310, 강종필 $37, 김명희 $15, 김민경 $103, 김복진 $127, 김애경 $31, 김정순 $33, 문소연 $25, 민인애 $77, 박순화 $19, 신경숙 $39, 안화숙 $40, 이수지 $39, 이창신 $104, 이희숙 $48, 임화영 $25, 정미란 $62, 정미홍 $62, 최  영 $23, 최은지 $11, 손인성 $26, 이재하 $26, 이상훈 $26, 오지후 $26, 강석준 $24, 김연화 $51, 김지숙 $24, 심경자 $24, 이혜지 $24, 조성화 $24, 주은희 $24, 한선규 $24, 황수우 $24',
    finalCumulativeAmount: '$15,580.35',
  },
];

const DonationE = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>[ 천안1지역 ]</strong>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data1.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>천안1지역 기부 합계 : $93,677.17 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 천안2지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data2.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>천안2지역 기부 합계 : $158,154.81 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>[ 천안3지역 ]</strong>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data3.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>천안3지역 기부 합계 : $215,106.39</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 천안4지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data4.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>천안4지역 기부 합계 : $122.184.00</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 아산1지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data5.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>아산1지역 기부 합계 : $101,679.92</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 아산2지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data6.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>아산2지역 기부 합계 : $137,823.38</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 예산지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data7.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>예산지역 기부 합계 : $28,675.74</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 당진지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data8.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>당진지역 기부 합계 : $107,301.71 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 홍성∙청양지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data9.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>홍성∙청양지역 기부 합계 : $156,952.61 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 보령지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data10.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>보령지역 기부 합계 : $164,583.83 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 서산지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data11.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px'}}>
          <strong>서산지역 기부 합계 : $132,422.18 </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '20px'}}>
              <strong>[ 태안지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>구분</TableCell>
              <TableCell className={classes.cell}>개인별 재단기부현황</TableCell>
              <TableCell className={classes.cell}>클럽합계액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data12.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Typography variant="h6" style={{textAlign: 'center', marginTop: '20px', marginBottom: '50px'}}>
          <strong>태안지역 기부 합계 : $73,966.65 </strong>
        </Typography>
      </Grid>
    </div>
  );
};

export default DonationE;