import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 천안4지역 ]",
    title2: "회장∙총무 상견례",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcTauw9%2FbtsHS9NMzZf%2FckFoCJO91X1lKtlidwWYyK%2Fimg.png',
    description: '&nbsp;5월 13일(월) 19시, 이담원에서 천안4지역 협의회 회장∙총무 상견례를 실시했다. 천안 4지역 현∙차기회장, 총무 협의회는 임기의 끝이 아닌 새로운 회기의 맨토로서 로타리안의 정신을 굳건하게 세워가자고 했다.',
  },
  {
    title1: "[ 천안중앙 로타리클럽 ]",
    title2: "정기모임",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc7sVzC%2FbtsHSXGJEku%2FIA7qkYpnF6qkSOzx0JW0kK%2Fimg.jpg',
    description: '&nbsp;5월 9일 오후 7시, 클럽사무실에서 정기모임을 실시했다.',
  },
  {
    title1: "[ 천안중앙 로타리클럽 ]",
    title2: "무료 도시락 봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FMZJLd%2FbtsHSzGdg7O%2FVqGYbIIwe7FyT8OpDJwUs0%2Fimg.png',
    description: '&nbsp;5월 28일 오전 9시, 도광사에서 지구보조금 사업으로 무료 도시락 봉사를 실시했다.',
  },
  {
    title1: "[ 천안목주 로타리클럽 ]",
    title2: "좋은씨앗 공동체 급식 봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbV8GIj%2FbtsHSyAu5KZ%2F4kPgP5SWWe4KtVwnbsC9t1%2Fimg.jpg',
    description: '&nbsp;5월 7일(화) 8시, 좋은씨앗 공동체 무료급식소에서 매월 정기 급식 봉사활동을 진행했다. "초아의 봉사"로 지역사회 소외계층 및 불우한 이웃들에게 따뜻한 온정의 손길, 선한 영향력이 전해질 수 있도록 더욱더 봉사활동에 최선을 다할 것을 다짐한다.',
  },
  {
    title1: "[ 천안목주 로타리클럽 ]",
    title2: "상명대학교 로타랙트클럽<br/>가입증서 전수 및 창립식",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FczuYXE%2FbtsHSvxajj6%2FHkQs3emTWsKfnmCqVvDqT0%2Fimg.jpg',
    description: '&nbsp;5월 17일(금) 16시, 상명대학교 한누리관에서 2023-24년도 상명대학교 로타랙트클럽 가입증서 전수 및 창립식을 진행했다.',
  },
  {
    title1: "[ 천안일봉 로타리클럽 ]",
    title2: "천성중학교 인터랙트 합동봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FtnDSf%2FbtsHRHL0q8Z%2FZIIIHkHVAgZjtCltLu0QnK%2Fimg.png',
    description: '&nbsp;5월 11일(토) 10시 천안 천성중학교 원성천 일대를 정화작업하는 봉사를 실시했다. 주말임에도 많은 학생들이 참여하여 함께하는 봉사를 실천하였다. 고생한 학생들에게 맛있는 점심을 대접한 초다 장순백 회장은 앞으로도 끊임없이 봉사에 참여해 달라는 당부와 함께 지속적인 지원을 약속하였다.',
  },
  {
    title1: "[ 천안남산 로타리클럽 ]",
    title2: "버스정류장 환경정화 봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fzjpdv%2FbtsHR9OLeLa%2FqyzGUhgafGCBOHMbnFLn7K%2Fimg.jpg',
    description: '&nbsp;5월 12일 9시, 천안시 백석동 일대 버스정류장 환경정화를 실시했다. 작년 8월부터 시작한 버스정류장 환경정화 봉사는 어느덧 2023-24년도 임기 마지막봉사로 마무리했다. 그동안 관리가 되지 않던 천안시 버스정류장에 변화를 주었다. 천안남산 로타리클럽 뿐만 아니라 타 봉사단체에서도 환경정화를 하고 또한 청소업체에서도 무료 재능기부도 하는걸로 들었다.',
  },
  {
    title1: "[ 천안아우내 로타리클럽 ]",
    title2: "독거어르신 밑반찬 배달봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F6vikQ%2FbtsHSWnA0jC%2FPmqmPS2ksJ4KdAYGJPU5U0%2Fimg.jpg',
    description: '&nbsp;5월 2일(목), 독거어르신 아홉분을 대상으로 밑반찬 배달봉사를 실시했다.',
  },
  {
    title1: "[ 천안아우내 로타리클럽 ]",
    title2: "무료 자장면 나눔 봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FJaEKL%2FbtsHSwCSb0r%2FlUMDQClaoAtWwYekeNl1H1%2Fimg.png',
    description: '&nbsp;5월 13일 아우내은빛복지관 식당에서 한그릇의 사랑 자장면 나눔 봉사를 실시했다.',
  },
  {
    title1: "[ 천안무궁화 로타리클럽 ]",
    title2: "청각장애인 미용봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FmhA5C%2FbtsHTiYgraQ%2F7x1b2AaqCWX296pwnusLok%2Fimg.png',
    description: '&nbsp;5월 20일 오전 10시, 청각장애인 미용봉사를 실시했다.',
  },
];

const ClubCaD = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" className={classes.title} component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubCaD;
