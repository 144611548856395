import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 온양 로타리클럽 ]",
    title2: "문화모임 개최",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FrJHMG%2FbtsHTe2xnM0%2Fkboi7oo1r67Fn3yG3Qbmx0%2Fimg.jpg',
    description: '&nbsp;5월 29일, 아산 CGV에서 클럽 회원과 가족들을 위한 문화모임을 개최했다.',
  },
  {
    title1: "[ 온양목련 로타리클럽 ]",
    title2: "5월 직장정기모임",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbxYO8t%2FbtsHTUJh54V%2FAKKkx6TfIkkpGb5HK01VCK%2Fimg.png',
    description: '&nbsp;5월 6일(월) 18시, 이상미 회원이 운영하는 아지트비어에서 직장정기모임을 실시했다.',
  },
];

const ClubAsA = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubAsA;
