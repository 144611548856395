import { makeStyles } from '@mui/styles';

const backgroundImageUrl = 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FctbrII%2FbtstxiTmtYu%2FiYvOpZnlSdUWF9LKv9fPk0%2Fimg.png';
const useStyles = makeStyles((theme) => ({
  // Your existing styles
  logoMain: {
    marginTop: '5px',
    textAlign: 'center',
  },
  logo: {
    width: '90%',
    height: 'auto',
    marginTop: '15px',
  },
  centerText: {
    textAlign: 'center',
  },
  cardFourLevel: {
    backgroundImage: `url(${backgroundImageUrl})`, // Add this line to set the background image
    backgroundSize: 'cover', // Optional: adjust background size to cover the entire card
    backgroundPosition: 'center', // Optional: adjust background position
    // padding: theme.spacing(2),
    padding: 0,
    maxWidth: 'calc(100% - 16px)', // 50% width with spacing in between
    margin: theme.spacing(1), // Add spacing between cards
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '57%', // 16:9 aspect ratio (9 / 16 * 100)
    },
    boxShadow: theme.shadows[3],
    borderTop: '8px solid #FFD700',
  },
  cardA: {
    maxWidth: 'calc(100% - 16px)', // 50% width with spacing in between
    margin: theme.spacing(1), // Add spacing between cards
    padding: 0,
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 * 100)
    },
    boxShadow: theme.shadows[3],
    borderTop: '8px solid #941B4C',
  },
  cardB: {
    maxWidth: 'calc(100% - 16px)', // 50% width with spacing in between
    margin: theme.spacing(1), // Add spacing between cards
    padding: 0,
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 * 100)
    },
    boxShadow: theme.shadows[3],
    borderTop: '8px solid #194C86',
  },
  imageContainer: {
    position: 'absolute',
    top: 5,
    left: 17,
    width: '120px', // Half of the card's width
    height: '150px',
    display: 'flex', // Add flex display
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
  },
  image: {
    width: '110px', // Adjusted to 80% of the image container
    height: '130px', // Maintain aspect ratio
    display: 'block',
    margin: '0', // Center horizontally within the container
    borderRadius: '5px'
  },
  textContainer: {
    position: 'absolute',
    top: 0,
    right: -15,
    width: '60%', // Half of the card's width
    height: '100%',
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  info: {
    marginBottom: theme.spacing(1),
  },
  tabs: {
    backgroundColor: '#fff',
  },
  tableContainer: {
    maxWidth: '100%', // Set a maximum width to prevent the table from expanding too much
    overflowX: 'auto',
  },
  cell: {
    borderBottom: 'none', // Remove bottom border for the first cell in each row
    borderRight: '1px solid #e0e0e0', // Add a right border for all cells
    padding: '8px', // Adjust padding as needed
    whiteSpace: 'nowrap', // Prevent text from breaking into multiple lines
  },
  mergedCell: {
    borderRight: 'none', // Remove right border for merged cells
    backgroundColor: '#f0f0f0', // Add background color for merged cells
    textAlign: 'center', // Center the content in merged cells
  },
  // mergedCell: {
  //   borderRight: '1px solid #e0e0e0', // Adjust border styles as needed
  // },
  preserveNewline: {
    whiteSpace: 'pre-line',
  },
}));

export default useStyles;
