import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import useStyles from '../../styles/contents/NewMemberStyle';

const data = [
  // {
  //   title: '천안천룡 RC',
  //   info1: '오현',
  //   info2: '2023. 7. 1',
  //   info3: '세무사',
  //   info4: '윤용훈',
  //   imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbei7Wi%2FbtsGwNtkZeg%2F2ljd5h16k2RdZtWSrHt48K%2Fimg.jpg',
  // },
];

const NewMember = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.centerText}>
            <br/><strong>2023-24년도<br/> &lt;5월&gt; 신입회원</strong>
          </Typography>
        </Grid>
      </Grid>

      {data.map((item, index) => (
        <Paper elevation={3} className={classes.cart} key={index}>
          <div className={classes.imageContainer}>
                <img src={item.imageUrl} alt="img" className={classes.image} />
              </div>
          <div className={classes.textContainer}>
            <Typography variant="h6">{item.title}</Typography>
            <Typography variant="body1">{item.info1}</Typography>
            <Typography variant="body1">{item.info2}</Typography>
            <Typography variant="body1">{item.info3}</Typography>
            <Typography variant="body1">추천인: {item.info4}</Typography>
          </div>
        </Paper>
      ))}
    </div>
  );
};

export default NewMember;
