import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data = [
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FWDAoS%2FbtsIs6jUTRW%2FpuUVlgPbpolXAaFV1tM0s1%2Fimg.jpg',
    name: '류해권',
    affiliation: '대천중앙 RC',
    position: '사찰위원장',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+7',
    joinDate: '2012. 2. 17',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbm7Eop%2FbtsIs5ywiZs%2FQ0DdQ6ALq18zRQiK0HXl2K%2Fimg.jpg',
    name: '조승한',
    affiliation: '대천중앙 RC',
    position: '회원',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+5',
    joinDate: '2016. 6. 23',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fv2l8k%2FbtsItoR7JwM%2F1fMkOhjPQk54y136kQJOUk%2Fimg.jpg',
    name: '김완섭',
    affiliation: '대천중앙 RC',
    position: '회원',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+4',
    joinDate: '2009. 4. 9',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbNySQX%2FbtsItWHzYMA%2FqnEdFIssRIK8kI2HkowQG0%2Fimg.jpg',
    name: '이종혁',
    affiliation: '천안쌍봉 RC',
    position: '회장',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+3',
    joinDate: '2013. 7. 1',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbUC4KH%2FbtsItPoeQSb%2FKwyr0UEK21Dn8Jpq0ElU01%2Fimg.jpg',
    name: '이춘식',
    affiliation: '천안쌍봉 RC',
    position: '회원',
    donationAmount: '$2,000.00',
    donationAchievement: 'PHF+3',
    joinDate: '2011. 11. 1',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FSKyCF%2FbtsItQ8uRkt%2FK8ZKHdklUPMbkXk2Q3zm4k%2Fimg.jpg',
    name: '김성규',
    affiliation: '천안쌍봉 RC',
    position: '회원',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+2',
    joinDate: '2013. 12. 1',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbHd5qv%2FbtsItUbTNQT%2FMR9saLJpI6KeUoe1Z392m0%2Fimg.jpg',
    name: '이시헌',
    affiliation: '천안버들 RC',
    position: '차기회장',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+1',
    joinDate: '2012. 6. 28',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FwsMhk%2FbtsIs4fisRA%2Fjt4Os0FePNMU1wjL6T2s0K%2Fimg.jpg',
    name: '오정교',
    affiliation: '대천중앙 RC',
    position: '수석의전',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+1',
    joinDate: '2018. 7. 12',
  },
];

const Donation = () => {
  const classes = useStyles(); // Initialize useStyles hook here

  return (
    <div>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <Paper elevation={3} className={classes.cardB}>
              <div className={classes.imageContainer}>
                <img src={item.imageUrl} alt="img" className={classes.image} />
              </div>
              <div className={classes.textContainer}>
                <Typography variant="h6" className={classes.title}>
                  {item.name}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.affiliation}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.position}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부금액: </strong> {item.donationAmount}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부실적:</strong> {item.donationAchievement}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>입회일:</strong> {item.joinDate}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Donation;
