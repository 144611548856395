import React from 'react';

const YoutubeCard = ({ title, description, videoUrl }) => {
  const videoId = videoUrl.split('v=')[1].split('&')[0];
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
        <h2 style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginRight: '10px',
          display: 'inline-block',
          position: 'relative',
        }}>
          {title}
          <span style={{
            content: '',
            position: 'absolute',
            bottom: '-3px',
            left: 0,
            right: 0,
            height: '2px',
            backgroundColor: title.length % 2 === 0 ? '#194C86' : '#EDA123',
          }}></span>
        </h2>
        <p style={{ fontSize: '15px', fontWeight: 'bold', display: 'inline-block', marginTop: '30px' }}>{description}</p>
      </div>
      <iframe
        style={{ width: '100%', height: '315px' }}
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      ></iframe>
    </div>
  );
};

export default YoutubeCard;
