import React, { useState } from 'react';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth'; // 수정: 함수 import
import { TextField, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email); // 수정: 함수 호출 방식 변경
      alert('입력한 이메일에서 비밀번호 변경을 진행해주세요!');
      navigate('/login'); // 성공 후 로그인 페이지로 이동
    } catch (error) {
      console.error('Password Reset Error:', error.message);
      alert('비밀번호 재설정 메일을 보내는데 실패했습니다.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <h2>비밀번호 재설정</h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <TextField
            label="이메일"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: '#194C86' }}>
            비밀번호 재설정 링크 보내기
          </Button>
        </form>

        <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link to="/signup" style={{ textDecoration: 'none', color: 'gray' }}>회원가입</Link>
          <span style={{ margin: '0 10px' }}>|</span>
          <Link to="/login" style={{ textDecoration: 'none', color: '#194C86' }}>로그인</Link>
          <span style={{ margin: '0 10px' }}>|</span>
          <Link to="/find-id" style={{ textDecoration: 'none', color: 'gray' }}>아이디 찾기</Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;

