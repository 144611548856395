import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiF = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          국제로타리 3620지구{'\n'} 2023-24년도{'\n'}{'\n'}
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>상명대학교 로타랙트클럽 탄생</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb3aCnV%2FbtsHRY08oAz%2FXNPJEZtZYJnerfDuk7nB0k%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;국제로타리 3620지구(해암 박홍전총재) 천안목주 로타리클럽은 2024년 5월 17일(금요일) 스폰서 클럽으로 상명대학교 한누리관에서 상명대학교 로타랙트클럽의 가입증서 전달과 창립 및 회장 취임식을 박홍전 총재와 지구 임원 등이 참석한 가운데 진행했다.
          {'\n'}{'\n'}
          &nbsp;서상희 초대회장은 취임사에서 “천안목주 로타리클럽의 지원 아래 많은 로타랙트 회원 분들께서 학업 혹은 개인의 일정으로 봉사에 접근하기 어려운 상황을 극복하고 봉사라는 행위에 조금 더 다가갈 수 있었으면 좋겠고 더불어 지역사회와 타인의 삶에 도움을 보태는 활동을 이어나가 다양한 사람들과 교류하며 유기적인 관계를 형성할 수 있기를 노력하겠다.”라고 밝혔다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbVSUCJ%2FbtsHRIxuH4x%2FFQSNixBwHFxkmSpYyBXbI1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;해암 박홍전 총재는(2023-24년도) “오늘 태어난 상명대학교 로타랙트클럽은 앞으로 스폰서 클럽인 천안목주 로타리클럽과 함께 지역사회 다양한 봉사 활동을 펼치게 될 것이며 미래 지도자로서 인성과 역량을 키우기 위한 리더십 교육을 받고, 국제로타랙트 클럽의 일원으로서 여러 국제행사에 참여, 로타랙트 활동을 통해 학생들이 봉사하는 리더로 거듭 성장할 수 있도록 기대한다."라며 축하했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdohQ6x%2FbtsHTgMWHx5%2FK6kCJ8m3GHyOklwCRUCSxK%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;스폰서 클럽인 천안목주 로타클럽 정도 김 구 회장은“국제로타리 탄생 119년이자 2024년 청룡의 기운이 가득한 78명의 학생과 함께 상명대학교 로타랙트클럽이 창립된 만큼 3620지구 최고의 로타랙트클럽으로 성장하고 최고의 동아리활동이 될 것이라 확신하며 젊고 희망찬 봉사의 열정으로 국제봉사 및 지역사회봉사 활동을 통해 멋진 로타리안으로 거듭나기를 기대하고 소망한다."라며 격려했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbrIAFA%2FbtsHTFMk8AS%2FnPKoUSWDFnnH2jvMSyzFJk%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FRCVl1%2FbtsHS6p5dPe%2FLRPjZmYutMyysKhaabgFV0%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbvIoxN%2FbtsHR8bntL7%2F2bHjCWO27wvgZt8xBZ9zI1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;한편 행사에는 천안목주 로타리클럽 회원들과 지구 임원 등 100여 명이 참석하여 창립 및 회장 취임을 축하했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbz7FEg%2FbtsHR8vJUOS%2FsCkobach6Q8PT0iJv4rDP0%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpZCVG%2FbtsHSp4XwiI%2FKnsjZYRyeYAumhiFIZekvK%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 3620지구기자{'\n'}&nbsp;보경 문수협{'\n'}&nbsp;(서산보라매 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiF;