import React from 'react';
import { Typography, Grid } from '@mui/material';
import useStyles from '../../styles/contents/PurposeStyle';

const Purpose = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcwVP3p%2Fbtsn7qWiaWo%2FNOGa9IMwOA5O3Pjm53l530%2Fimg.png"
            alt="logo"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12} className={classes.sectionTitle}>
          <img
            src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcs6de7%2Fbtsorlz6GZo%2Fkv3Sq7Kvjjv5CNtkQoHr9K%2Fimg.png"
            alt="logo"
            className={classes.smallLogo}
          />
          <Typography variant="h6" className={classes.profileName}>
            <strong>로타리의 목적</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionContent}>
          <Typography variant="body1">
            <strong>로타리의 목적</strong>은 봉사의 이상을 모든 가치있는 활동의 기초로 발전시키고 증진하는 것으로, 특히 다음을 북돋는 데 있다. <br /><br />
            <strong>첫째,</strong> 사람간의 교류를 봉사의 기회로서 넓히고 함양한다. <br /><br />
            <strong>둘째,</strong> 사업과 직업에서의 도덕적 수준을 높이고, 모든 유익한 직업의 가치를 인식하며, 로타리안 개개인의 직업을 사회에 봉사할 소중한 기회로 삼는다. <br /><br />
            <strong>셋째,</strong> 로타리안 각자의 개인 생활이나 사업 및 사회 생활에서 봉사의 이상을 실천한다. <br /><br />
            <strong>넷째,</strong> 봉사의 이상으로 맺어진 사업 및 직업인들의 범세계적인 우정을 바탕으로 국제적인 이해와 친선 그리고 평화를 증진한다. <br /><br />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionTitle}>
          <img
            src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcs6de7%2Fbtsorlz6GZo%2Fkv3Sq7Kvjjv5CNtkQoHr9K%2Fimg.png"
            alt="logo"
            className={classes.smallLogo}
          />
          <Typography variant="h6" className={classes.profileName}>
            <strong>네 가지 표준</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionContent}>
          <Typography variant="body1">
            <strong>우리가 생각하고 말하고 행동하는데 있어서</strong> <br /><br />
            진실한가?<br />
            <span style={{ fontSize: '14px' }}>Is it the truth?</span><br /><br />
            모두에게 공평한가?<br />
            <span style={{ fontSize: '14px' }}>Is it fair to all concerned?</span><br /><br />
            선의와 우정을 더하게 하는가?<br />
            <span style={{ fontSize: '14px' }}>Does it increase goodwill and friendship?</span><br /><br />
            모두에게 유익한가?<br />
            <span style={{ fontSize: '14px' }}>Is it beneficial to everyone?</span><br /><br />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionTitle}>
          <img
            src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcs6de7%2Fbtsorlz6GZo%2Fkv3Sq7Kvjjv5CNtkQoHr9K%2Fimg.png"
            alt="logo"
            className={classes.smallLogo}
          />
          <Typography variant="h6" className={classes.profileName}>
            <strong>로타리안 행동 강령</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionContent}>
          <Typography variant="body1">
            <strong>나는 로타리안으로서</strong> <br /><br />
            <strong>1. </strong> 개인 생활과 직업활동에 있어 윤리적으로 정직(Integrity)하게 행동한다. <br /><br />
            <strong>2. </strong> 모든 사람을 공정하게 대하며 그들과 그들의 직업을 존중한다. <br /><br />
            <strong>3. </strong> 내가 속한 지역사회와 전 세계에서 젊은이들을 선도하고 장애인들을 도우며 사람들의 삶의 질을 개선하기 위하여 나의 직업적 재능을 로타리에 제공한다. <br /><br />
            <strong>4. </strong> 로타리 및 동료 로타리안들에게 불이익을 초래하는 일은 하지 않는다. <br /><br />
            <strong>5. </strong> 로타리 모임, 행사 및 활동에 괴롭힘이 없는 환경을 조성하고, 괴롭힘이 의심되는 경우에 이를 보고하고 개인에 대한 불이익을 방지하기 위해 노력한다. <br /><br />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Purpose;
