import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const GiguNews = () => {
  const newsData = [
    {
      id: 1,
      title: '제29년차 지구대회',
      description: '',
      date: '5월 18~19일',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FMQMYn%2FbtsH1EnGQdl%2FMgNuJjLX9EiYkf2cJ3L1M1%2Fimg.png',
    },
    {
      id: 2,
      title: '싱가포르 세계대회',
      description: '의전실장 은효 강미(광천오서산 로타리클럽)',
      date: '5월 23~28일',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb7n557%2FbtsHTlOiPXt%2FaaoysnK7qFGdgZQKpU5KFk%2Fimg.png',
    },
    {
      id: 3,
      title: '다문화 가족 경주 역사탐방',
      description: '',
      date: '5월 31일 ~ 6월 1일',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbaF1Oa%2FbtsH2nFI8xO%2FSZn6T6DJfyN9fTr9w4RbyK%2Fimg.png',
    },
    {
      id: 4,
      title: '지구보조금 프리젠테이션',
      description: '',
      date: '5월 4일',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fezqbxn%2FbtsH9qwsvdn%2FGKWMfwSF7FuSkd4yBK9By0%2Fimg.png',
    },
    {
      id: 5,
      title: '상명대학교 로타랙트 창립식',
      description: '재학 중인 학생 78명으로 탄생, 가입 증서 전수 및 창립 행사 성료',
      date: '5월 17일',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbexJ6u%2FbtsHRK289Zs%2F6pTKsFKE0sGC4eH1EKX35K%2Fimg.jpg',
    },
    {
      id: 6,
      title: '필리핀 3830지구 지구대회 참관기',
      description: '“비행 희망을 구하기”를 참석하며',
      date: '5월 9~12일',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FescyCa%2FbtsIsvxr814%2FmFOYsLuRcqLCLTsvMhdiik%2Fimg.jpg',
    },
    {
      id: 7,
      title: '',
      description: '',
      date: '',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FIuzl4%2FbtsH882xYps%2F0eWepzcaB4kGChPtdftJrK%2Fimg.png',
    },
    {
      id: 8,
      title: '',
      description: '',
      date: '',
      imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdEFmGj%2FbtsH84eTV5x%2FdCNAh5uU7iIoVUDCMOsXA1%2Fimg.png',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontSize: '2rem', color: '#194C86', textAlign: 'center', borderBottom: '4px solid #194C86', padding: '1rem', cursor: 'pointer' }}>
          <strong>지구소식</strong>
        </Typography>
      </Grid>
      {newsData.map((news) => (
        <Grid item xs={12} sm={6} md={4} key={news.id}>
          <Link to={`/news/${news.id}`} style={{ textDecoration: 'none', color: 'black' }}> {/* Add a Link wrapper */}
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={news.imageUrl}
                alt={news.title}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {news.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {news.date}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default GiguNews;
