import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data1 = [
  { category: '천안 RC', name: '45', donationAmount: '10', finalCumulativeAmount: '55' },
  { category: '천안삼거리 RC', name: '102', donationAmount: '11', finalCumulativeAmount: '113' },
  { category: '천안파로스 RC', name: '34', donationAmount: '8', finalCumulativeAmount: '42' },
  { category: '천안드림 RC', name: '8', donationAmount: '-1', finalCumulativeAmount: '7' },
  { category: '천안충남 RC', name: '34', donationAmount: '-5', finalCumulativeAmount: '29' },
];

const data2 = [
  { category: '성환 RC', name: '23', donationAmount: '-2', finalCumulativeAmount: '21' },
  { category: '서천안 RC', name: '110', donationAmount: '-6', finalCumulativeAmount: '104' },
  { category: '남천안 RC', name: '34', donationAmount: '-4', finalCumulativeAmount: '30' },
  { category: '천안하늘 RC', name: '44', donationAmount: '-9', finalCumulativeAmount: '35' },
  { category: '천안천룡 RC', name: '32', donationAmount: '1', finalCumulativeAmount: '33' },
  { category: '천안백두 RC', name: '19', donationAmount: '-2', finalCumulativeAmount: '17' },
  { category: '천안쌍봉 RC', name: '56', donationAmount: '0', finalCumulativeAmount: '56' },
  { category: '천안새충무 RC', name: '34', donationAmount: '5', finalCumulativeAmount: '39' },
];

const data3 = [
  { category: '새천안 RC', name: '44', donationAmount: '-3', finalCumulativeAmount: '41' },
  { category: '천안능수 RC', name: '29', donationAmount: '11', finalCumulativeAmount: '40' },
  { category: '천안도솔 RC', name: '81', donationAmount: '1', finalCumulativeAmount: '82' },
  { category: '천안동남 RC', name: '98', donationAmount: '0', finalCumulativeAmount: '98' },
  { category: '천안상록 RC', name: '36', donationAmount: '10', finalCumulativeAmount: '46' },
  { category: '천안천지 RC', name: '30', donationAmount: '-2', finalCumulativeAmount: '28' },
  { category: '천안희망 RC', name: '27', donationAmount: '8', finalCumulativeAmount: '35' },
  { category: '천안리더스 RC', name: '8', donationAmount: '4', finalCumulativeAmount: '12' },
  { category: '천안동호 RC', name: '57', donationAmount: '-2', finalCumulativeAmount: '55' },
  { category: '천안버들 RC', name: '14', donationAmount: '2', finalCumulativeAmount: '16' },
];

const data4 = [
  
  { category: '천안중앙 RC', name: '30', donationAmount: '-6', finalCumulativeAmount: '24' },
  { category: '천안목주 RC', name: '44', donationAmount: '26', finalCumulativeAmount: '70' },
  { category: '천안일봉 RC', name: '29', donationAmount: '2', finalCumulativeAmount: '31' },
  { category: '천안남산 RC', name: '25', donationAmount: '3', finalCumulativeAmount: '28' },
  { category: '천안서북 RC', name: '25', donationAmount: '-13', finalCumulativeAmount: '12' },
  { category: '천안아우내 RC', name: '23', donationAmount: '-2', finalCumulativeAmount: '21' },
  { category: '천안무궁화 RC', name: '12', donationAmount: '3', finalCumulativeAmount: '15' },
  { category: '천안다온 RC', name: '24', donationAmount: '-11', finalCumulativeAmount: '13' },
];

const data5 = [
  { category: '온양 RC', name: '74', donationAmount: '9', finalCumulativeAmount: '83' },
  { category: '온양목련 RC', name: '39', donationAmount: '1', finalCumulativeAmount: '40' },
  { category: '아산둔포 RC', name: '15', donationAmount: '-2', finalCumulativeAmount: '13' },
  { category: '아산배방 RC', name: '21', donationAmount: '1', finalCumulativeAmount: '22' },
  { category: '아산온궁 RC', name: '50', donationAmount: '11', finalCumulativeAmount: '61' },
  { category: '아산성웅 RC', name: '37', donationAmount: '4', finalCumulativeAmount: '41' },
  { category: '온양온천 RC', name: '30', donationAmount: '1', finalCumulativeAmount: '31' },
];

const data6 = [
  { category: '새온양 RC', name: '61', donationAmount: '-1', finalCumulativeAmount: '60' },
  { category: '온아 RC', name: '122', donationAmount: '-2', finalCumulativeAmount: '120' },
  { category: '아산 RC', name: '57', donationAmount: '-4', finalCumulativeAmount: '53' },
  { category: '온양중앙 RC', name: '64', donationAmount: '0', finalCumulativeAmount: '64' },
  { category: '아산온정 RC', name: '29', donationAmount: '-1', finalCumulativeAmount: '28' },
  { category: '아산현충 RC', name: '13', donationAmount: '-1', finalCumulativeAmount: '12' },
  { category: '아산단미 RC', name: '12', donationAmount: '6', finalCumulativeAmount: '18' },
];

const data7 = [
  { category: '아산온수 RC', name: '26', donationAmount: '-3', finalCumulativeAmount: '23' },
  { category: '예산 RC', name: '44', donationAmount: '6', finalCumulativeAmount: '50' },
  { category: '신례원 RC', name: '13', donationAmount: '8', finalCumulativeAmount: '21' },
  { category: '고덕 RC', name: '42', donationAmount: '-4', finalCumulativeAmount: '38' },
  { category: '예산어울림 RC', name: '', donationAmount: '29', finalCumulativeAmount: '29' },
];

const data8 = [
  { category: '당진 RC', name: '67', donationAmount: '1', finalCumulativeAmount: '68' },
  { category: '석문 RC', name: '46', donationAmount: '-5', finalCumulativeAmount: '41' },
  { category: '합덕 RC', name: '24', donationAmount: '', finalCumulativeAmount: '24' },
  { category: '당진당나루 RC', name: '52', donationAmount: '1', finalCumulativeAmount: '53' },
  { category: '당진송악 RC', name: '45', donationAmount: '5', finalCumulativeAmount: '50' },
  { category: '당진신평 RC', name: '28', donationAmount: '1', finalCumulativeAmount: '29' },
  { category: '당진고대 RC', name: '40', donationAmount: '4', finalCumulativeAmount: '44' },
  { category: '당진해나루 RC', name: '37', donationAmount: '-1', finalCumulativeAmount: '36' },
  { category: '당진아미 RC', name: '25', donationAmount: '-4', finalCumulativeAmount: '21' },
  { category: '당진골드 RC', name: '12', donationAmount: '', finalCumulativeAmount: '12' },
];

const data9 = [
  { category: '광천 RC', name: '40', donationAmount: '7', finalCumulativeAmount: '47' },
  { category: '홍성 RC', name: '66', donationAmount: '5', finalCumulativeAmount: '71' },
  { category: '청양 RC', name: '11', donationAmount: '16', finalCumulativeAmount: '27' },
  { category: '홍성홍서 RC', name: '21', donationAmount: '-5', finalCumulativeAmount: '16' },
  { category: '홍성홍주 RC', name: '44', donationAmount: '6', finalCumulativeAmount: '50' },
  { category: '청양칠갑산 RC', name: '22', donationAmount: '2', finalCumulativeAmount: '24' },
  { category: '홍성조양 RC', name: '29', donationAmount: '9', finalCumulativeAmount: '38' },
  { category: '내포 RC', name: '18', donationAmount: '10', finalCumulativeAmount: '28' },
  { category: '광천오서산 RC', name: '26', donationAmount: '9', finalCumulativeAmount: '35' },
  { category: '홍성용봉 RC', name: '25', donationAmount: '4', finalCumulativeAmount: '29' },
  { category: '홍성운주 RC', name: '38', donationAmount: '-3', finalCumulativeAmount: '35' },
];

const data10 = [
  { category: '대천 RC', name: '43', donationAmount: '4', finalCumulativeAmount: '47' },
  { category: '대천중앙 RC', name: '98', donationAmount: '5', finalCumulativeAmount: '103' },
  { category: '대천서부 RC', name: '15', donationAmount: '-1', finalCumulativeAmount: '14' },
  { category: '뉴대천 RC', name: '45', donationAmount: '-3', finalCumulativeAmount: '42' },
  { category: '대천더함 RC', name: '34', donationAmount: '-11', finalCumulativeAmount: '23' },
];

const data11 = [
  { category: '서산 RC', name: '95', donationAmount: '-3', finalCumulativeAmount: '92' },
  { category: '서령 RC', name: '26', donationAmount: '6', finalCumulativeAmount: '32' },
  { category: '새서산 RC', name: '34', donationAmount: '-4', finalCumulativeAmount: '30' },
  { category: '충서 RC', name: '37', donationAmount: '2', finalCumulativeAmount: '39' },
  { category: '서산한서 RC', name: '23', donationAmount: '2', finalCumulativeAmount: '25' },
  { category: '서산보라매 RC', name: '58', donationAmount: '-15', finalCumulativeAmount: '43' },
  { category: '서산국화 RC', name: '32', donationAmount: '-1', finalCumulativeAmount: '31' },
  { category: '서산가온 RC', name: '17', donationAmount: '0', finalCumulativeAmount: '17' },
];

const data12 = [
  { category: '태안 RC', name: '20', donationAmount: '3', finalCumulativeAmount: '23' },
  { category: '안면도 RC', name: '30', donationAmount: '0', finalCumulativeAmount: '30' },
  { category: '태안소성 RC', name: '56', donationAmount: '-1', finalCumulativeAmount: '55' },
  { category: '태안백화산 RC', name: '31', donationAmount: '-1', finalCumulativeAmount: '30' },
  { category: '태안해당화 RC', name: '29', donationAmount: '-9', finalCumulativeAmount: '20' },
  { category: '안면도송림 RC', name: '14', donationAmount: '1', finalCumulativeAmount: '15' },
  { category: '태안한결 RC', name: '19', donationAmount: '5', finalCumulativeAmount: '24' },
];

const data13 = [
  {
    category: '총합',
    name: '3,432',
    donationAmount: '136',
    finalCumulativeAmount: '3,568',
  },
];

const ClubTotalA = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
          <strong>[ 천안1지역 ]</strong>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data1.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 천안2지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data2.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
          <strong>[ 천안3지역 ]</strong>
        </Typography>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data3.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 천안4지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data4.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 아산1지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data5.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 아산2지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data6.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 예산지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data7.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 당진지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data8.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 홍성∙청양지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data9.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 보령지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data10.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 서산지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data11.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 태안지역 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>클럽명</TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data12.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12}>
            <Typography variant="h5" style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px'}}>
              <strong>[ 3620지구 회원증강 현황 ]</strong>
            </Typography>
          </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}></TableCell>
              <TableCell className={classes.cell}>2023.7.1</TableCell>
              <TableCell className={classes.cell}>증감</TableCell>
              <TableCell className={classes.cell} align="center">6.30<br/>(현재)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data13.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{row.category}</TableCell>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{row.donationAmount}</TableCell>
                <TableCell className={classes.cell}>{row.finalCumulativeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br/><br/>
    </div>
  );
};

export default ClubTotalA;