import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiD = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          국제로타리 3620지구{'\n'} 2023-24년도{'\n'}{'\n'}
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>다문화위원회</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbaVNpZ%2FbtsH03uCchJ%2FZkbHbN0QchjixS6cxXfSk1%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;국제로타리 3620지구(총재 해암 박홍전)의 다문화위원회(위원장 김건기)에서 주관하여 진행된 1박2일 간의 ‘다문화가정과 함께하는 경주 역사탐방’이 상황리에 마무리 되었다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FD3814%2FbtsH2PuXyY5%2FE356ZyJJwYWcYEc3rcgVEK%2Fimg.jpg'
            title="main1"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FOLJEv%2FbtsH2oq3QPx%2F00chR5RRhbMmtHS7sp2fL1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;충남도 내 62명의 다문화가족과 박홍전 총재를 비롯한 지구임원 등 로타리안 16명 총78명이 5월31일~6월1일 양일 간 경주에 방문하여 천마총, 경주국립박물관, 석굴암, 불국사 등의 문화재를 통해 신라시대의 역사를 보고 배우는 의미 있는 시간을 가졌다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FTJzEv%2FbtsH1OKrZsu%2FFudk61o12Olto7uPW4Pb60%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;아산지역에서 참석한 마링여오님은 “중국에서 시집와 두 딸을 키우고 있는 엄마입니다. 이번 경주 역사탐방의 기회를 주신 3620지구 총재님과 모든 여러분께 감사의 말을 전한다. 평소에 비용부담과 시간 여유가 없어 아이들과 여행할 기회가 많지 않았는데, 이번 2일 간의 경주 역사탐방은 완벽한 시간이었다. 나는 물론이고 아이들도 한국 역사에 대해 좀 더 이해하고 배울 수 있어서 너무 좋았다”며 감사의 인사를 전했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fq4W1o%2FbtsH1ikM3QC%2F9CXGqWsncL6gGlIORvMY30%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdpeKh3%2FbtsH2Phop6z%2FYLVpl6gWKDJKEuvkcUekh1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;또한 역사탐방 외에 루지체험과 레크레이션, 황리단길 투어도 참석한 가족들에게 큰 기쁨을 주었다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FzfNZv%2FbtsH01culCY%2F6kW93EomUywhDoPKvrOP00%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbaF1Oa%2FbtsH2nFI8xO%2FSZn6T6DJfyN9fTr9w4RbyK%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 로타리코리아 지구기자{'\n'}&nbsp;금암 전용우{'\n'}&nbsp;(천안남산 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiD;