import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/header/LogoStyle.css';

const Logo = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Link to="/" className={`logo ${isScrolled ? 'scrolled' : ''}`}>
      <img
        src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbq9bvJ%2FbtsHRYtifUf%2FJv1EKivh5oQv6BosXEIMT0%2Fimg.png"
        alt="Logo"
        className="logo-img"
      />
    </Link>

  );
};

export default Logo;
