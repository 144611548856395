import React from 'react';
import { List, ListItem, ListItemText, Divider, Typography } from '@material-ui/core';

const PlanList = () => {
    const plans = [
        {
            date: new Date('2024-05-11'),
            title: '지구대회 1일차'
        },
        {
            date: new Date('2024-05-12'),
            title: '지구대회 2일차'
        },
        {
            date: new Date('2024-06-15'),
            title: '2024-25 로타리협의회, 재단세미나, 공공이미지세미나'
        },
    ];

    return (
        <div style={{ maxWidth: '600px' }}>
            <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>
                2024년 &lt;5-6월&gt;<br/> 3620지구 총재동정
            </Typography>
            <Divider />
            <List>
                {plans.map((plan, index) => (
                    <React.Fragment key={index}>
                        <ListItem>
                            <ListItemText
                                primary={plan.title}
                                secondary={plan.end
                                        ? `${new Intl.DateTimeFormat('ko-KR').format(plan.date)} ~ ${new Intl.DateTimeFormat('ko-KR').format(plan.end)}`
                                        : new Intl.DateTimeFormat('ko-KR').format(plan.date)}
                            />
                        </ListItem>
                        {index !== plans.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
};

export default PlanList;
