import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column', // Change to column layout
    marginBottom: theme.spacing(2),
    alignItems: 'center', // Center horizontally
    padding: theme.spacing(2), // Add some padding for spacing
    borderRadius: 8, // Add border radius for a slight round border
  },
  media: {
    width: '100%', // Set the image width to 100%
    height: '220px',
    borderRadius: '8px 8px 0 0', // Adjust the border radius as needed
    marginTop: '20px',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'left', // Left-align description
    padding: theme.spacing(1), // Add some padding for spacing
  },
  title: {
    fontWeight: 'bold',
    fontSize: '15px', // Adjust title font size
    textAlign: 'center',
  },
  description: {
    fontSize: '10px', // Adjust description font size
    fontWeight: 'normal',
  },

  '@media (min-width: 600px)': {
    media: {
      width: '80%',
      height: '400px',
    },
  },
}));

export default useStyles;
