import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiC = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          <span style={{ fontWeight: 'bold' }}>2024 싱가폴 로타리 세계대회</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb7n557%2FbtsHTlOiPXt%2FaaoysnK7qFGdgZQKpU5KFk%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;국제로타리 3620지구 광천오서산로타리클럽에 입회한지 10년만에 그동안 한번도 관심 가져보지 않았던 세계대회에 참가하게 되었다. 올 회기에 의전실장으로 지구행사를 참여하면서 로타리에 대한 관심과 애정이 어느해 보다 큰 한 해였다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;사실 세계대회 등록은 일찍 했었지만 참석 할 마음은 반반이었다. 해암 박홍전 총재와 함께한 28명의 로타리안들은 싱가폴 관광 및 세계대회참가등 4박 6일 간의 일정을 함께 하며 우정을 쌓았다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbk09Jx%2FbtsHSB5kndZ%2FkOyF7Qn5KK8tXCsaSseI71%2Fimg.png'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FUDidn%2FbtsHSv5jLxj%2FjfXkcBLKDFncFbVTe0UAk1%2Fimg.png'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fdt9AVY%2FbtsHR0kw8eu%2F2kfRKoIEayEsIhaiIdkLcK%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;우정의 집을 방문하면서 전세계 로타리안과 인사도 하고 뱃지 교환,사진도 함께 찍는 등 세계대회동안 전세계 로타리안을 만나고 소통하면서 내가 더 특별해 진거 같은 기분도 느껴 본다. 대회 개막식 태극기가 입장 할때의 코끝 찡한 감동이 아직도 생생하다.
          {'\n'}{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;일정을 모두 마치고 돌아와서 서로의 추억을 공유하고 헤어짐이 아쉬워 내년에도 함께 하자는 이야기들을 서로 주고받으며, 세계대회를 계기로 우리지구 로타리안들의 특별한 인연이 끝이 아니고 시작이라는 것을 깨닫는 소중한 시간이 되었다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FeJVHEN%2FbtsHTiqEKx9%2FRDj5YTSFrG5SvHBZtfgyc1%2Fimg.png'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FZiod1%2FbtsHSzNgHuj%2FOMLgYqEIh7dJ3bH1DuIyV0%2Fimg.png'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 의전실장{'\n'}&nbsp;은효 강미{'\n'}&nbsp;(광천오서산 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiC;