import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const data = [
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb4bbfj%2FbtsHYYY6sPw%2FDkerdgKuOjOjre7PZiqymk%2Fimg.jpg',
    name: '이득우',
    affiliation: '천안쌍봉 RC',
    position: '회원',
    donationAmount: '$1,100.00',
    donationAchievement: 'PHF+2',
    joinDate: '2011. 11. 1',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbdYWnP%2FbtsHXOXvOvP%2FHkzpBghQLcPM0O3RGuFShK%2Fimg.jpg',
    name: '김민영',
    affiliation: '당진해나루 RC',
    position: '총무',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+1',
    joinDate: '2021. 2. 1',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FMY54t%2FbtsHYFS6K1X%2F3P6xab4M6nhGOCDoe2oDK1%2Fimg.jpg',
    name: '정유성',
    affiliation: '새온양 RC',
    position: '회원',
    donationAmount: '$1,021.74',
    donationAchievement: 'Polio Plus(PHF+1)',
    joinDate: '2021. 7. 8',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FJq1Hs%2FbtsHW293ZDB%2FbwxeMg6e8ZQ9RD4Q0BOnD0%2Fimg.jpg',
    name: '백병석',
    affiliation: '천안동호 RC',
    position: '회원',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+1',
    joinDate: '2020. 6. 5',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbOV4Gw%2FbtsHXlapsLk%2FQEXfgRMkYWwzrw06bIRKQk%2Fimg.jpg',
    name: '최기수',
    affiliation: '천안동호 RC',
    position: '회원',
    donationAmount: '$1,000.00',
    donationAchievement: 'PHF+1',
    joinDate: '2020. 6. 5',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fdpq503%2FbtsHYqIEcxr%2F916KWkIoUVl0txF5567Wn1%2Fimg.jpg',
    name: '이시헌',
    affiliation: '천안버들 RC',
    position: '차기회장',
    donationAmount: '1,000.00',
    donationAchievement: 'PHF',
    joinDate: '2012. 6. 28',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FR4I1I%2FbtsHX2abZZQ%2FpVpBdxL7IPDk2KA3l7DKnK%2Fimg.png',
    name: '이성우',
    affiliation: '천안버들 RC',
    position: '회장',
    donationAmount: '1,000.00',
    donationAchievement: 'PHF',
    joinDate: '2015. 8. 29',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fclca7u%2FbtsHXluHVlG%2FDzvJ4qaI9f2uxWLWJ2QYr1%2Fimg.jpg',
    name: '정원욱',
    affiliation: '천안버들 RC',
    position: '총무',
    donationAmount: '1,000.00',
    donationAchievement: 'PHF',
    joinDate: '2015. 8. 29',
  },
];

const Donation = () => {
  const classes = useStyles(); // Initialize useStyles hook here

  return (
    <div>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <Paper elevation={3} className={classes.cardB}>
              <div className={classes.imageContainer}>
                <img src={item.imageUrl} alt="img" className={classes.image} />
              </div>
              <div className={classes.textContainer}>
                <Typography variant="h6" className={classes.title}>
                  {item.name}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.affiliation}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.position}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부금액: </strong> {item.donationAmount}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부실적:</strong> {item.donationAchievement}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>입회일:</strong> {item.joinDate}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Donation;
