// 1. 인증 관련 데이터 및 기능에 액세스하기 위해 다른 구성 요소에서 사용할 수 있는 공유 'AuthContext'를 제공합니다.
// 2. 인증 상태를 관리하고 컨텍스트 값을 제공하는 AuthProvider 컴포넌트를 정의합니다.
// 3. 사용자가 로그인하거나 로그아웃할 때 인증 상태를 업데이트하도록 리스너를 설정합니다.
// 4. 사용자가 Firebase 인증에서 로그아웃할 수 있는 '로그아웃' 기능을 제공합니다.

import { createContext, useState, useEffect } from 'react';
import { auth } from '../../firebase';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null); // Add user state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setIsLoggedIn(true);
        setUser(currentUser); // Update user state
      } else {
        setIsLoggedIn(false);
        setUser(null); // Reset user state
      }
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
