import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, FormHelperText, Autocomplete } from '@mui/material';
import CustomButton from '../shared/CustomButton';
import { SignupContainer, Form, FormControl as CustomFormControl } from '../../styles/auth/SignupScreenStyles';
import { auth, db } from '../../firebase';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const isEmailValid = (email) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return emailRegex.test(email);
};

const SignUpPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPasswordRules, setShowPasswordRules] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [name, setName] = useState('');
  const [clubs, setClubs] = useState([]);
  const [clubError, setClubError] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [workplace, setWorkplace] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [joinedClub, setJoinedClub] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError && e.target.value.length >= 8) {
      setPasswordError(false);
    }
  };

  const handlePasswordFocus = () => {
    setShowPasswordRules(true);
  };

  const handlePasswordBlur = () => {
    setShowPasswordRules(false);
    setPasswordError(password.length < 8);
  };

  const navigate = useNavigate();

  const normalizePhoneNumber = (input) => {
    return input.replace(/\D/g, ''); // 숫자가 아닌 모든 문자를 제거
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // 클럽 선택 확인
    if (!selectedClub) {
      setClubError(true);
      // 필요하다면, 클럽 선택 드롭다운으로 포커스 이동
      // 예: document.getElementById('club-select').focus();
      return;
    } else {
      setClubError(false);
    }

    if (!isEmailValid(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    if (password !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // 사용자 정보 저장
      await setDoc(doc(db, "users", user.uid), {
        email: email,
        name: name,
        club: selectedClub,
        dateOfBirth: dateOfBirth,
        workplace: workplace,
        phoneNumber: normalizePhoneNumber(phoneNumber),
      });

      alert('회원가입이 완료되었습니다!');
      navigate('/login');
    } catch (error) {
      console.error('Error signing up:', error.message);
      alert('회원가입 중 오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    const fetchClubs = async () => {
      const querySnapshot = await getDocs(collection(db, 'clubs'));
      let clubOptions = [];
      querySnapshot.forEach((doc) => {
        // 여러 필드에서 클럽 이름들을 가져옵니다
        const data = doc.data();
        clubOptions.push(data.ch1, data.ch2, data.ch3, data.ch4, data.as1, data.as2, data.es, data.dj, data.hsca, data.bl, data.ss, data.ta);
      });
      // 중복 제거
      const uniqueClubs = Array.from(new Set(clubOptions.flat()));
      setClubs(uniqueClubs);
    };

    fetchClubs();
  }, []);

  return (
    <SignupContainer>
      <h2 style={{ textAlign: 'center' }}>3620지구 온라인 플랫폼 <br/>회원가입</h2>
      <Form onSubmit={handleSignUp}>
        <CustomFormControl error={emailError}>
          <TextField
            label="이메일"
            value={email.trim()}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={() => {
              setEmailError(!isEmailValid(email));
            }}
            fullWidth
            required
          />
          {emailError && <FormHelperText style={{ color: 'red' }}>이메일 형식이 올바르지 않습니다.</FormHelperText>}
        </CustomFormControl>

        <CustomFormControl error={passwordError}>
          <TextField
            label="비밀번호"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            fullWidth
            required
          />
          {showPasswordRules && (
            <FormHelperText style={{ color: 'grey' }}>
              비밀번호는 8자 이상, 영문자/숫자 등을 조합해주세요.
            </FormHelperText>
          )}
          {passwordError && (
            <FormHelperText style={{ color: 'red' }}>
              8자 이상 입력하세요.
            </FormHelperText>
          )}
        </CustomFormControl>
        <CustomFormControl error={confirmPasswordError}>
          <TextField
            label="비밀번호 확인"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => {
              setConfirmPasswordError(confirmPassword !== password && confirmPassword !== '');
            }}
            fullWidth
            required
            error={confirmPasswordError}
            helperText={confirmPasswordError ? '비밀번호가 일치하지 않습니다.' : ''}
          />
        </CustomFormControl>

        <CustomFormControl error={clubError}>
          <Autocomplete
            value={selectedClub}
            onChange={(event, newValue) => {
              setSelectedClub(newValue);
            }}
            options={clubs}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="소속 클럽" required />
            )}
            fullWidth
          />
          {clubError && <FormHelperText style={{ color: 'red' }}>클럽을 선택해주세요.</FormHelperText>}
        </CustomFormControl>

        <CustomFormControl>
          <TextField
            label="이름"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required />
        </CustomFormControl>

        <CustomFormControl>
          <TextField
            label="생년월일"
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
          <FormHelperText style={{ color: 'grey' }}>
            예)1989.08.17, 1977.10.01
          </FormHelperText>
        </CustomFormControl>

        <CustomFormControl>
          <TextField
            label="직업(사업체명)"
            value={workplace}
            onChange={(e) => setWorkplace(e.target.value)}
            fullWidth
            required/>
        </CustomFormControl>

        <CustomFormControl>
          <TextField
            label="전화번호"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            required
          />
        </CustomFormControl>

        <CustomFormControl>
          <FormControlLabel
            control={<Checkbox checked={joinedClub} onChange={(e) => setJoinedClub(e.target.checked)} />}
            label="개인정보 수집 및 이용에 동의합니다."
          />
        </CustomFormControl>
        <CustomButton type="submit">회원가입</CustomButton>
      </Form>
    </SignupContainer>
  );
};

export default SignUpPage;