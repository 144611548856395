import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { db } from '../../firebase'; // 올바른 경로로 수정해주세요.
import { collection, getDocs } from 'firebase/firestore';

const AnswersPage = () => {
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);

  const fetchAnswers = async () => {
    const correctSnapshot = await getDocs(collection(db, 'correctAnswers'));
    setCorrectAnswers(correctSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

    // 'incorrectAnswers' 컬렉션에서 문서 가져오기
    const incorrectSnapshot = await getDocs(collection(db, 'incorrectAnswers'));
    setIncorrectAnswers(incorrectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };
  
  useEffect(() => {
    fetchAnswers();
  }, []);

  const cellStyle = {
    whiteSpace: 'nowrap', // 텍스트를 한 줄로 유지
    overflow: 'hidden', // 셀의 너비를 넘어서는 텍스트 숨기기
    textOverflow: 'ellipsis' // 넘어서는 텍스트가 있을 경우 말줄임표(...) 표시
  };

  const renderTableRows = (data) => {
    return data.map((row, index) => (
      <TableRow key={row.id}>
        <TableCell style={cellStyle}>{index + 1}</TableCell>
        <TableCell style={cellStyle}>{row.name}</TableCell>
        <TableCell style={cellStyle}>{row.answer}</TableCell>
        <TableCell style={cellStyle}>{row.club}</TableCell>
        <TableCell style={cellStyle}>{row.phoneNumber}</TableCell>
      </TableRow>
    ));
  };

  return (
    <div style={{ margin: '20px' }}>
      <h2>정답자</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>번호</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>답안 1</TableCell>
              {/* <TableCell>답안 2</TableCell> */}
              <TableCell>클럽</TableCell>
              {/* <TableCell>생년월일</TableCell> */}
              {/* <TableCell>이메일</TableCell> */}
              <TableCell>전화번호</TableCell>
              {/* <TableCell>제출 시간</TableCell> */}
              {/* <TableCell>직장</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows(correctAnswers)}
          </TableBody>
        </Table>
      </TableContainer>

      <h2>오답자</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>번호</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>답안 1</TableCell>
              {/* <TableCell>답안 2</TableCell> */}
              <TableCell>클럽</TableCell>
              {/* <TableCell>생년월일</TableCell> */}
              {/* <TableCell>이메일</TableCell> */}
              <TableCell>전화번호</TableCell>
              {/* <TableCell>제출 시간</TableCell> */}
              {/* <TableCell>직장</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows(incorrectAnswers)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AnswersPage;
