import React, { useState } from 'react';
import { AppBar, Paper, Tab, Tabs, ThemeProvider, createTheme, Box } from '@mui/material';
import ClubTotalAJ from './ClubTotalAJ';
import ClubTotalBJ from './ClubTotalBJ';
import ClubTotalCJ from './ClubTotalCJ';
import ClubTotalDJ from './ClubTotalDJ';
import ClubTotalEJ from './ClubTotalEJ';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const ClubTotalScreen = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div>
        <AppBar position="static">
          <Box sx={{ maxWidth: { xs: 400, sm: 680 }, bgcolor: 'background.paper' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label="회원현황" style={{ color: '#92CDDC' }} />
              <Tab label="연차기금/영구기금/제한기금" style={{ color: '#EDA123', marginLeft: '-15px' }} />
              <Tab label="봉사의인" style={{ color: '#228B22', marginLeft: '-15px' }} />
              <Tab label="지구회비및분담금" style={{ color: '#194C86', marginLeft: '-15px' }} />
              <Tab label="국제회비 등" style={{ color: '#941B4C', marginLeft: '-15px' }} />
            </Tabs>
          </Box>
        </AppBar>
        <Paper>
          {value === 0 && <ClubTotalAJ />}
          {value === 1 && <ClubTotalBJ />}
          {value === 2 && <ClubTotalCJ />}
          {value === 3 && <ClubTotalDJ />}
          {value === 4 && <ClubTotalEJ />}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default ClubTotalScreen;
