import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc', // Adding a border
    padding: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  // Define your custom styles here
  menuTransition: {
    transform: 'translateX(0px)',
    transition: 'transform 0.3s ease-in-out',
  },
  menuOpen: {
    transform: 'translateX(0px)', // Adjust the value for the desired slide distance
  },
}));

export default useStyles;