import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiB = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          필리핀 3830지구 지구대회{'\n'}
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>“비행 희망을 구하기”</span>를 참석하며{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FescyCa%2FbtsIsvxr814%2FmFOYsLuRcqLCLTsvMhdiik%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          <span style={{ fontWeight: 'bold', fontSize: '1.2em', display: 'block', marginBottom: '8px' }}>&nbsp;RI회장대리 환영만찬 </span>
          &nbsp;마닐라에 도착후 밀드레드(mildred) RI회장대리 에이드(2022-23총재) 및 환영단의 극진한 환대를 받으며 대형요트에서 만찬과 환영식을 가졌다. 축하공연과 로타리안 소개, 3620지구에 대한 각종 글로벌보조금사업 과 빈얀집짓기 사업 등에 대한 감사와 현안에 대한 담소가 이어졌으며 3830지구 지구임원 모두가 승선 한 듯 하였다. 오후 5시에 승선하여 저녁노을과 마닐라 야경까지 감상할 수 있었다. 5월 12일 지산 문은수 RI 회장대리는 spiral 조찬장에서 3830지구 총재단을 초대하여 조찬을 베풀며 담소를 나누고 선물교환을 하였다. 
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
           <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb7LGWf%2FbtsItTRkI75%2FmQr6QEHklaICFxgbfquFz1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          <span style={{ fontWeight: 'bold', fontSize: '1.2em', display: 'block', marginBottom: '8px' }}>&nbsp;빈얀(Binan) 현장방문 </span>
          &nbsp;5월 10-11일 양일간 개최된 3830지구 지구대회 첫 일정으로 아침 8시에 빈얀(Binan)에 도착하여 지산 문은수 RI 회장대리는 인사말에서 국제로타리 3620지구 해암 박홍전 총재를 치하 하며 빈얀 집짓기 사업은 4개년 연속사업으로 진행하게 될 것이라고 말하며 모인 입주민들에게 큰 박수를 받았다. 이어서 입주민들에게 각각 key 전달식과 지산 문은순 RI회장대리가 준비한 칫솔 세트 200개 전달하였다. 
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FzHU7V%2FbtsIsJhXR3n%2FHLEuqJydrwRk1Fr5BKtrmK%2Fimg.jpg'
            title="main3"
          />{'\n'}
           <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbdcjNa%2FbtsItnZImeP%2F9HHVYCSaPGVoWpYI9zwf60%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          <span style={{ fontWeight: 'bold', fontSize: '1.2em', display: 'block', marginBottom: '8px' }}>&nbsp;색다른 3830지구 지구대회 이모저모{'\n'}&nbsp;(컨셉 “비행 희망을 구하기”) </span>
          &nbsp;지구대회 드레스 코드를 미리 발표하여 의상을 편하게 하였고 이번드레스 코드는 공군 파일럿 탑건 의상 이었으며 모두가 군복을 입고 지구대회에 참석하였다. 행사장 어디에도 현수막은 존재하지 않았으며 모니터로 대신하였다. 입장식은 현 회장과 차기회장이 같이 입장하였으며 입장 후 바로 현 회장은 무대에 차기회장은 계단과 아랫쪽에 모여 함께 합창을 하였는데 회기 연속성 측면에서 인상적 이었다. 지구대회 별도로 참가하는 로타리안 대상으로 한화 약 160,000원정도 등록비를 받아 운영하고 있었다. 
          {'\n'}{'\n'}
          &nbsp;소피텔 대회장은 원탁으로 세팅되어 있었으며 자유롭게 입구에 마련된 커피나 다과 및 음식을 먹으며 지구대회 행사를 진행하였다. 지구대회에서 별도의 클럽 시상식은 없었으며 성과있는 클럽회장들을 단상에 올려 토크쇼를 진행하였다. 세계대회에서 그러하듯 3830지구 제이총재의 가족들을 무대에서 한명 한명 소개하였다. 총재를 역임하기 위해 가족들의 희생이나 헌신이 뒷바침 되었기에 그러한듯 하였다. 지구대회 1일차는 세계대회 오전 본회의와 비슷하게 진행되었으며 각 분야에 대한 현안이나 성과 등에 대한 세미나 형식으로 진행되는 듯 하였다. 2일차는 3620지구와 재매지구 25주년 협약식과 서명이 있었고, 지역대표 소개와 협의회, 노래와 춤 각종 공연등으로 즐거운 파티를 프로그램에 많이 반영하였다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
           <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FxOgbL%2FbtsItUbDA91%2FWAtDrjceHI5WKylqVEC6Bk%2Fimg.jpg'
            title="main3"
          />{'\n'}
           <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcHVzVI%2FbtsIu4dopnU%2FkCHAPIw6c301cYK2S0Au8k%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          <span style={{ fontWeight: 'bold', fontSize: '1.2em', display: 'block', marginBottom: '8px' }}>&nbsp;지산 문은수 RI회장대리{'\n'}&nbsp;기조연설과 본회의 축사 </span>
          &nbsp;지산 문은수 RI회장대리 축사에서 안토니오 제이 3830지구 총재를 포함한 로타리리더와 3,600명의 로타리안들께 지구대회를 축하하고 R. 고든 R. 메키넬리 RI회장 대신하여 RI회장대리로 참석하게 됨을 영광으로 생각한다고 말했다. 120만 로타리안들을 대표하여 3830지구의 위대한 발전을 이끌어 주신 안토니오 제이 3830지구 총재와 3830지구 로타리안 여러분들에게 감사를 드린다고 말하였다. 특히 3620지구와 자매지구이기에 더욱 특별하다고 말했다. 
          {'\n'}{'\n'}
          &nbsp;3830지구와 3620지구는 2000년부터 청소년교류 뿐만아니라 글로벌그랜트사업, 지구보조금사업, 등 다양한 프로그램을 통해 양지구가 자매지구로 노력하며 끈끈한 친목과 우의를 다져왔고 로타리에서 봉사와 함께 교류와 우정이 핵심가치인 만큼 두 지구가 세계에서 가장 이상적인 자매지구라고 말했다.
          {'\n'}{'\n'}
          &nbsp;안토니오 제이 3830지구 총재는 인사말에서, "지난 1년동안 이루어낸 놀라운 프로젝트와 활동을 선보이기 위해 지구대회 소피텔 대회장에 모였다고 말하며 이 컨퍼런스에서 공유될 영향, 혁신, 영감의 이야기에 몰입하시기 바란다. 앞으로 2일동안 서로 교제하면서 친교를 나누고 여러사람들에게 알리고 영감을 주고 흥분시키기를 바랍니다. 앉아서 쉬면서 술도 마시고 재미있게 놀기를 기대합니다." 라고 말했다.
          {'\n'}{'\n'}
        </Typography>
        
        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 차기기획실장{'\n'}&nbsp;금산 장광석{'\n'}&nbsp;(온아 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiB;