import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 천안 로타리클럽 ]",
    title2: "글로벌 그랜트 필리핀 현장방문",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FJLp5V%2FbtsHRMfoDVt%2FD7Cu7K3yWzF4tvkTKguWjK%2Fimg.png',
    description: '&nbsp;5월 15~16일, 필리핀 소외지역 수술지원 국제봉사를 실시했다. 국제로타리 7대 초점분야중 하나인 "질병예방 및 치료"로 필리핀 외곽지역에 외과 수술 지원으로 50여 명의 의사와 간호사, 천안 로타리클럽 회원, 마카티 중앙 로타리클럽 회원과 로타리 재단에 관계자가 참석하여 150 여명의 환자에게 외과 수술을 지원하고 있다.',
  },
  {
    title1: "[ 천안삼거리 로타리클럽 ]",
    title2: "1050차 정기모임",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FUYBtI%2FbtsHSBjXVSq%2FrL5u5ikhP3UX8ePunOJffK%2Fimg.jpg',
    description: '&nbsp;5월 2일(목) 오후 7시, 클럽사무실에서 정기모임을 실시했다. 클럽 장학생에게 장학증서를 홍춘기 장학이사가 전달했으며, 장학생들은 소감에서 봉사활동을 통해 보답하겠다는 의지를 보여주었다.',
  },
  {
    title1: "[ 천안파로스 로타리클럽 ]",
    title2: "좋은씨앗 공동체 무료급식봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbvrsLg%2FbtsHR74BoMW%2FlNNxHe9Gt3OaRk3w7vbbK1%2Fimg.png',
    description: '&nbsp;5월 12일(일) 오전 9시 30분, 좋은씨앗 공동체 무료급식소에서 요리 식재료 정리 및 도시락 싸기 봉사를 실시했다. 이번 봉사는 멤버십 위원회가 주축이 되었으며, 천안파로스 로타리클럽 회원 10명과 나사렛대 로타렉트 학생 1명까지 총 11명이 참여했다.',
  },
];

const ClubCaA = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card} >
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubCaA;
