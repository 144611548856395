// src/styles/SharedStyles.js

import styled from '@emotion/styled';

export const CustomButtonStyled = styled.button`
  background-color: #1e90ff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;

  &:hover {
    background-color: #007bbd;
    color: white;
  }
`;

