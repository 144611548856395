import React from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import useStyles from '../../styles/contents/MainContentsStyle';
import ComplexBtn from '../main/ComplexBtn';

const GiE = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.preserveNewline}>
          국제로타리 3620지구{'\n'} 2024-25년도{'\n'}{'\n'}
          <span style={{ color: '#194C86', fontWeight: 'bold' }}>보조금관리 세미나 및 보조금 프리젠테이션</span>{'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FnTPee%2FbtsH98u3tS5%2FJO79UTSydqBEpbSnNezcfK%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body1" color="h1" component="p" className={`${classes.preserveNewline} ${classes.leftAlign}`}>
          &nbsp;2024.5.4(토)10시 온아 로타리클럽 사무실에서 도유 한주희 총재, 각 지역대표, 무이 최준호 RI로타리 재단위원장, 가포 이석호 모금소위원회 위원장, 대동 김정호 보조금의원회 위원장, 지구임원, 각 클럽회장 임원이 모여 24-25년 보조금관리 세미나 및 보조금 프리젠테이션을 진행했다.
          {'\n'}{'\n'}
          &nbsp;보조금 프리젠테이션을 신청한 각 클럽이 모여 클럽의 봉사 계획과 보조금 신청을 발표하고 위원장들과 개선점과 발전 방향을 이야기했다.
          {'\n'}{'\n'}
        </Typography>

        <Typography className={classes.preserveNewline}>
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FweRL4%2FbtsIah6ECEk%2F8cmo4l9kmYeAUhq8vKvzAk%2Fimg.jpg'
            title="main1"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcZPMQ4%2FbtsIbk2CrVU%2FlQDMO5A3HcIsLnDAh6hPJ1%2Fimg.jpg'
            title="main3"
          />{'\n'}
          <CardMedia
            className={classes.media3}
            image='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FsN1Sk%2FbtsIbKmvsWI%2FyHTG3qtfTs8Z3W7g0nKXQ1%2Fimg.jpg'
            title="main3"
          />{'\n'}
        </Typography>

        <Typography variant="body2" className={`${classes.preserveNewline} ${classes.leftAlign} ${classes.smallText}`}>
          <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpawxB%2Fbtss3YmPsbs%2F1gNuJax515blC88jKlL461%2Fimg.png" alt="Reporter" className={classes.reporterImage} />
          &nbsp;글. 로타리코리아 지구기자{'\n'}&nbsp;금암 전용우{'\n'}&nbsp;(천안남산 로타리클럽){'\n'}
        </Typography>
      </CardContent>
      <ComplexBtn />
    </Card>
  );
}

export default GiE;