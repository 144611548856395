import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import useStyles from '../../styles/contents/DonationStyle';

const dataUp = [
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FRd488%2FbtsHYqIEcxQ%2FWARo1E86thgpUsslYXPQ2K%2Fimg.jpg',
    name: 'AKS2 문은수',
    affiliation: '천안도솔 RC',
    position: '前 국제로타리 이사',
    donationAmount: '$10,000.00',
    donationAchievement: '$642,780',
    joinDate: '2001. 4. 7',
  },
];

const data = [
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fy4wJI%2FbtsHX2OOWQi%2FYS77hkj5Lpti74mFjwdPPK%2Fimg.jpg',
    name: 'LEVEL2 김순고',
    affiliation: '아산 RC',
    position: '지구 아산2지역 국제봉사단부위원장',
    donationAmount: '$1,000.00',
    donationAchievement: '$29,029',
    joinDate: '2008. 7. 1',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F7mnL8%2FbtsHWX8UUD7%2FQenJCr4GoKUXeP7tme0hLk%2Fimg.png',
    name: 'LEVEL1 임승환',
    affiliation: '천안동호 RC',
    position: '전회장',
    donationAmount: '$1,000.00',
    donationAchievement: '$14,805',
    joinDate: '2012. 11. 2',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbhyyRA%2FbtsHXKt4nsC%2FdwWidhbBlLJzpLRVp2QYX1%2Fimg.jpg',
    name: 'LEVEL1 이승배',
    affiliation: '천안동호 RC',
    position: '회원',
    donationAmount: '$1,000.00',
    donationAchievement: '$12,515',
    joinDate: '2014. 8. 14',
  },
  {
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb67wS7%2FbtsHYqomBGT%2FHR8aWQKSbkg2CkunffV1r1%2Fimg.jpg',
    name: 'LEVEL1 조용운',
    affiliation: '천안동호 RC',
    position: '회장',
    donationAmount: '$1,000.00',
    donationAchievement: '$11,101',
    joinDate: '2017. 8. 1',
  },
];


const Donation = () => {
  const classes = useStyles(); // Initialize useStyles hook here

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{textAlign: 'center', marginTop: '10px'}}>
            <strong>2023-24년도 &lt;5월&gt;<br/>기부자 현황 </strong>
          </Typography>
        </Grid>
          {dataUp.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <Paper elevation={3} className={classes.cardFourLevel}>
              <div className={classes.imageContainer}>
                <img src={item.imageUrl} alt="Ima" className={classes.image} />
              </div>
              <div className={classes.textContainer}>
                <Typography variant="h6" className={classes.title}>
                  {item.name}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.affiliation}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.position}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부금액: </strong> {item.donationAmount}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부실적:</strong> {item.donationAchievement}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>입회일:</strong> {item.joinDate}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}

        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
            <Paper elevation={3} className={classes.cardA}>
              <div className={classes.imageContainer}>
                <img src={item.imageUrl} alt="img" className={classes.image} />
              </div>
              <div className={classes.textContainer}>
                <Typography variant="h6" className={classes.title}>
                  {item.name}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.affiliation}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong></strong> {item.position}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부금액: </strong> {item.donationAmount}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>기부실적:</strong> {item.donationAchievement}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                  <strong>입회일:</strong> {item.joinDate}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Donation;
