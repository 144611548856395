import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 태안 로타리클럽 ]",
    title2: "2024-25년 차기 이사회",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FrNz1z%2FbtsHTAROQ5N%2FHz8rCjWGuhsxTv70SvxRzK%2Fimg.png',
    description: '&nbsp;5월 12일 클럽사무실에서 2024-25년 차기 이사회를 실시했다. 2024-25년 사업계획서 확정 및 월별 계획봉사 등 다양한 안건에 대해서 논의하였다.',
  },
];

const ClubTa = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubTa;
