import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import useStyles from '../../styles/contents/ClubStyle';

const data = [
  {
    title1: "[ 천안능수 로타리클럽 ]",
    title2: "제과∙제빵 나눔봉사",
    imageUrl: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FoOeYv%2FbtsHTe9iuhR%2Fxw9KvJvb54HCqINuTDdik1%2Fimg.png',
    description: '&nbsp;5월 4일(토), 천안자원봉사센터 기능실에서 5월 가정의 달을 맞이하여 지역아동센터 학생들과 함께 제과∙제빵 나눔봉사를 실시했다. 맛있게 구워진 쿠키와 차기회장이 지원한 샌드위치까지 지역아동센터에 전달하였다.',
  },
];

const ClubCaC = () => {
  const classes = useStyles();

  return (
    <div>
      {data.map((item, index) => (
        <Card key={index} className={classes.card}>
          <Typography variant="h5" className={classes.title} component="h3" style={{ color: '#17458F', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: item.title1 }} />
          <Typography variant="h6" className={classes.title} dangerouslySetInnerHTML={{ __html: item.title2 }} />
          <CardMedia className={classes.media} image={item.imageUrl} title={item.title} />
          <CardContent className={classes.content}>
            <Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ClubCaC;
