import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    padding: 0, // Add this line to remove padding
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%', // For smaller screens, make the card full width
    },
  },
  media1: {
    width: '100%',
    height: '400px',
    position: 'relative',
    paddingBottom: '90%', // 16:9 aspect ratio (9 / 16 * 100%)
    [theme.breakpoints.down('sm')]: {
      height: '400px', // Adjust height for smaller screens
    },
  },
  media2: {
    width: '100%',
    height: '500px',
    position: 'relative',
    paddingBottom: '90%', // 16:9 aspect ratio (9 / 16 * 100%)
    [theme.breakpoints.down('sm')]: {
      height: '300px', // Adjust height for smaller screens
    },
  },
  media3: {
    width: '100%',
    height: '180px',
    position: 'relative',
    paddingBottom: '90%', // 16:9 aspect ratio (9 / 16 * 100%)
    [theme.breakpoints.down('sm')]: {
      height: '180px', // Adjust height for smaller screens
    },
  },
  media4: {
    width: '70%',
    height: '250px',
    position: 'relative',
    paddingBottom: '90%', // 16:9 aspect ratio (9 / 16 * 100%)
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  media5: {
    width: '100%',
    height: '130px',
    position: 'relative',
    paddingBottom: '40%', // 16:9 aspect ratio (9 / 16 * 100%)
    [theme.breakpoints.down('sm')]: {
      height: '180px', // Adjust height for smaller screens
    },
  },
  media6: {
    width: '100%',
    height: '130px',
    position: 'relative',
    paddingBottom: '70%', // 16:9 aspect ratio (9 / 16 * 100%)
    [theme.breakpoints.down('sm')]: {
      height: '180px', // Adjust height for smaller screens
    },
  },
  mediaCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'center',
      fontSize: '30px',
  },
  preserveNewline: {
    whiteSpace: 'pre-line',
  },
  leftAlign: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  reporterImage: {
    width: '20%',
  },
}));

export default useStyles;
